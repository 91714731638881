import React, { useEffect } from 'react';
import * as actions_work_orders from 'actions/work-orders-actions';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import _ from 'lodash';
import { CloseX } from 'enspire-manager-framework';
import { useDispatch } from 'react-redux';

const ProjectAnalysis = () => {

    /* Hooks ----------------------------------------------------------------------------------------------------------------------------------------------------------------*/

    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    /* Effects ----------------------------------------------------------------------------------------------------------------------------------------------------------------*/

    useEffect(() => {
        dispatch(actions_work_orders.subWorkOrderItemsByRequestId(params.handle, params.project_id));
    }, []);

    /* Render ----------------------------------------------------------------------------------------------------------------------------------------------------------------*/

    return (
        <>
            <CloseX onClick={() => { history.goBack(); }} style={{ right: '22px', marginTop: '22px' }} />
            <h1 className="heading-work-order">Project Analysis</h1>
            <div className='mt-4'>
            </div>
        </>
    );
};

export default ProjectAnalysis;