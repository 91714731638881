import * as actions_quickbooks from 'actions/quickbooks-actions';
import React, { useEffect, useState } from 'react';
import { Spinner } from 'enspire-manager-framework';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

const QuickbooksDisconnect = (props) => {

    // Passing Realm ID into QuickBooks Disconnect URL
    // https://help.developer.intuit.com/s/question/0D54R00009B4VzUSAV/what-is-the-disconnect-url-for-other-than-redirecting-a-user-to-that-page-when-they-disconnect-on-the-qb-portal-from-our-app-what-parameters-will-be-sent-to-that-disconnect-page

    const params = useParams();
    const dispatch = useDispatch();
    const [disconnecting, setDisconnecting] = useState(false);

    useEffect(() => {
        setDisconnecting(true);
        dispatch(actions_quickbooks.disconnectFromQuickbooks(null, {}, params.realm_id, () => {
            setDisconnecting(false);
        }));
    }, []);

    return (

        <div id="wrapper" className="gray-bg" style={{ paddingBottom: '300px' }}>

            <div className="middle-box text-center loginscreen animated fadeInDown">
                <div>
                    <div style={{ margin: '20px 0' }}>
                        <img src={'images/logo.png'} width="100%" alt="Mobile Track Logo" />
                    </div>

                    {disconnecting
                        ? <Spinner />
                        : <div>
                            <h2 className="mt-5" style={{ fontSize: '24px' }}>QuickBooks Successfully Disconnected!</h2>
                            <h4 className="mt-4">The connection between MobileTrack and your Quickbooks account has been terminated.</h4>
                            <p className="mt-3">You may reconnect by establishing a connection on the QuickBooks Settings page in the MobileTrack application.</p>
                        </div>
                    }

                </div>
            </div>

        </div>
    );
};

export default QuickbooksDisconnect;

