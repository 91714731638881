import React from 'react';
import _ from 'lodash';
import { Button, Dropdown, DropdownButton } from 'react-bootstrap';
import { CHECKIN } from 'components/common/constants';

export default function StatusSelect(props) {

    var statuses = props.statusTypes.map((status, index) => {

        let disabled = (props.statusTable == 'WORKORDERS' && status.id == '1') ? (props.selectedStatusId == '0') : status.disabled;

        return (
            <Dropdown.Item key={'Status:' + status.id} 
                disabled={ disabled || status.id == props.selectedStatusId}
                style={{ backgroundColor: (status.id == props.selectedStatusId)
                    ? 'lightSteelBlue'
                    : (disabled) ? '#eeeeee' : 'white' }} 
                onClick={ (typeof props.onClick === 'function') && props.onClick.bind(this, props.statusTable, status.id, props.id)}
            >
                <span style={{ color: status?.color }}>
                    <i className="fa fa-circle"></i>
                </span> &nbsp;
                {status?.name}
            </Dropdown.Item>
        );
    });

    const selectedStatus = _.find(props.statusTypes, { id: props.selectedStatusId });
    const isCheckin = (props.statusTable == 'CHECKIN');
    const isCheckedIn = (props.statusTable == 'CHECKIN' && (
        props.selectedStatusId == CHECKIN.ENROUTE.id || 
        props.selectedStatusId == CHECKIN.ONSITE.id ||
        props.selectedStatusId == CHECKIN.INPROGRESS.id
    ))
    let statusName = selectedStatus?.name.toUpperCase();
    if (!selectedStatus) statusName = "N/A";

    return (
        <>
            {props.static
                ?   <Button style={{ cursor: 'default' }} className={props.className} variant="default" size="sm" onClick={(typeof props.onClick === 'function') ? props.onClick.bind(this, props.statusTable, selectedStatus?.id) : undefined}>
                        <><span style={{ color: selectedStatus?.color }}><i className="fa fa-circle"></i></span> &nbsp; {statusName} </>
                    </Button>
                :   <DropdownButton 
                        className="d-inline" 
                        size="sm"
                        title={
                            (isCheckin)
                                ?   <>{ isCheckedIn ? <><span className="ml-1" style={{ color: selectedStatus?.color }}><i className="fa fa-circle"></i></span> &nbsp; {statusName} </> : <span className="ml-1">Check In</span> }</>
                                :   <>
                                        <span style={{ color: selectedStatus?.color }}>
                                            {selectedStatus &&
                                                <i className="fa fa-circle"></i>
                                            }
                                        </span> &nbsp; {statusName}
                                    </>
                        }
                        variant={ (isCheckin) ? ((isCheckedIn) ? 'default' : 'info') : 'default' }
                        onClick={(event) => {
                            if (event?.stopPropagation) event?.stopPropagation();
                        }}
                        drop={(isCheckin) ? 'left' : 'down'}
                    >
                        {(!props.static) ? statuses : null}
                    </DropdownButton>
            }
        </>
    );
};
