import React, { Fragment } from 'react';
import _ from 'lodash';
import { Button, ButtonGroup } from 'react-bootstrap';
import { ROLES } from 'components/common/constants';
import { sortByField, toProperCase } from 'components/common/toolbox';

const moment = require('moment');
const isDev = (process.env.REACT_APP_FIREBASE_ENV == 'development');

export function service_request_fields(default_duration) {
	return (
		{
			callTime: '',
			profileId: '',
			addressId: '',
			startDate: moment().toDate(),
			endDate: moment().add(default_duration, 'hours').toDate(),
			department: '',
			summary: '',
			description: '',
			technicianId: '',
			isServiceCall: true,
		}
	);
}

export function service_request_form_layout(handle, request_id, service_request, firebase, handleTerms, handlePromote, customer, ADDRESSES, employees, show_customer_form, settings, isCall, isComplete) {

	if (customer?.addresses) {
		var customer_option = <option value={customer.id}>{customer.displayName}</option>;
		var address_options = customer.addresses.map((address, index) => {
			let addressType = _.find(Object.values(ADDRESSES), { id: address.addressTypeId });
			return (
				<option key={'address' + address.addressId} value={address.addressId}>{address.addressLine1 + ', ' + address.addressLine2 + ' ' + ((addressType) ? '(' + addressType?.name + ') ' : '')}</option>
			);
		});
	}

	var technicians = [];
	for (let employee of Object.values(employees)) {
		if (employee.roles?.includes(ROLES.TECHNICIAN.id)) technicians.push({ ...employee });
	}

	const technician_options = _.orderBy(technicians, ['lastName'], ['asc']).map((tech, index) => {
		return (
			<option key={index} value={tech.id}>{tech.firstName + ' ' + tech.lastName}</option>
		);
	});

	const department_options = sortByField(settings?.departments, 'position')?.map((department, index) => <option key={department.id} value={department.id}>{toProperCase(department.value)}</option>);

	var altImages = [];
	for (let index = 0; index < 6; index++) {
		if (service_request['imageUrlAlt' + index]) {
			altImages.push({
				grid: 'col-sm-6 col-md-12 col-xl-6 mt-3',
				label: '',
				field: 'imageUrlAlt' + index,
				type: 'dropzone',
				disabled: (!service_request.imageUrl),
				dropzone: {
					camera: true,
					storage: 'firebase',
					storageRef: firebase.storage().ref(),
					bin: handle,
					directory: 'service-requests',
					className: 'mb-3',
					height: "180px",
					multiple: false,
					resultsArray: true,
					onPromote: handlePromote,
				}
			});
		}
	}

	return (
		[{
			column_class: 'col-md-7',
			body: [{
				section:
					<Fragment>
						<h3 className="m-t-none m-b">Customer Information</h3>
					</Fragment>,
				layout: [
					{
						grid: 'col-12',
						label: 'Call Time',
						field: 'callTime',
						type: (isCall) ? 'date' : 'skip',
						readOnly: true,
						dateFormat: 'MM-dd-yyyy h:mm a',
						showTimeSelect: true,
						valid: ['required'],
						selectsStart: true,
					}, {
						grid: 'col-12',
						label: 'Customer',
						field: 'profileId',
						allow_new: true,
						placeholder: 'Search for Customer',
						table: handle + (isDev ? '-dev' : '') + '-profiles',
						filter: 'customer',
						fields: ['displayName', 'addresses', 'contacts'],
						sort: [{ "modified": "desc" }, { "displayName": "asc" }],
						target: 'displayName',
						id: 'id',
						type: (show_customer_form || customer_option) ? 'skip' : 'typeahead',
						valid: ['required'],
					}, {
						grid: 'col-12',
						label: 'Customer',
						field: '_customerName',
						type: (!customer_option) ? 'skip' : 'text',
						clearButton: (isCall && !isComplete) && true,
						valid: ['required'],
						disabled: true,
					}, {
						grid: 'col-12',
						label: 'Service Address',
						field: 'addressId',
						type: (show_customer_form) ? 'skip' : 'select',
						valid: ['required'],
						disabled: (isComplete) ? true : false,
						options:
							<Fragment>
								<option key={'none'} value={null}>- Select -</option>
								{address_options}
							</Fragment>,
					}],
			}, {
				section:
					<Fragment>
						<h3 className="m-t-none m-b">Service Request Details</h3>
					</Fragment>,
				layout: [
					{
						grid: 'col-6',
						label: 'Request Date',
						field: 'requestDate',
						type: 'date',
						dateFormat: 'MM-dd-yyyy h:mm a',
						showTimeSelect: true,
						valid: ['required'],
						selectsStart: true,
						readOnly: (isComplete) ? true : false,
					}, {
						style: { marginTop: '33px', paddingLeft: '20px' },
						grid: 'col-4',
						label: 'Call Enroute',
						field: 'callEnroute',
						type: 'checkbox',
					}, {
						grid: 'col-6',
						label: 'Department',
						field: 'departmentId',
						type: 'select',
						// valid: ['required'],
						options:
							<Fragment>
								<option key={'none'} value={null}>- Select -</option>
								{department_options}
							</Fragment>,
					}, {
						className: 'mb-4',
						grid: 'col-6',
						type: 'jsx',
						component: <>
							<label> &nbsp; </label>
							<ButtonGroup size="sm" className="mt-2 d-flex justify-content-center align-items-end">
								<Button variant={(service_request?.isServiceCall) ? 'primary' : "default"} onClick={() => handleTerms(true)} > Service Call </Button>
								<Button variant={(!service_request?.isServiceCall) ? 'primary' : "default"} onClick={() => handleTerms(false)} > Estimate </Button>
							</ButtonGroup>
						</>
					}, {
						grid: 'col-12',
						label: 'Summary',
						field: 'summary',
						type: 'text',
						valid: ['required'],
						disabled: (isComplete) ? true : false,
					}, {
						grid: 'col-12',
						label: 'Description',
						field: 'description',
						type: 'textarea',
						rows: 8,
						valid: ['required'],
						disabled: (isComplete) ? true : false,
					}, {
						grid: 'col-12',
						label: 'Technician',
						field: 'technicianId',
						type: (parseInt(request_id)) ? 'skip' : 'select',
						disabled: (isComplete) ? true : false,
						options:
							<Fragment>
								<option key={'none'} value={null}>- Select -</option>
								{technician_options}
							</Fragment>,
					}],
			}]
		}, {
			column_class: 'col-md-5',
			body: [{
				section:
					<Fragment>
						<h3 className="m-t-none m-b">Main Service Request Image</h3>
					</Fragment>,
				layout: [{
					grid: 'col-12',
					label: 'Service Request Image',
					field: 'imageUrl',
					type: 'dropzone',
					dropzone: {
						camera: true,
						storage: 'firebase',
						storageRef: firebase.storage().ref(),
						bin: handle,
						directory: 'service-requests',
						className: 'mb-3',
						height: "280px",
						multiple: true
					}
				}],
			}, {
				section:
					<Fragment>
						<h3 className="m-t-none mb-0">Additional Images</h3>
					</Fragment>,
				layout: [ ...(altImages) ],
			}],
		}]
	);
}