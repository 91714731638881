import * as toolbox from 'components/common/toolbox';
import * as types from './action-types';
import firebase from 'firebase/compat/app';
import { getFirestore, addDoc, onSnapshot, collection, collectionGroup } from 'firebase/firestore';
import { getCountFromServer, query, limit, where, orderBy } from 'firebase/firestore';

const firestore = firebase.firestore();
const db = getFirestore();

/*-----------------------------------------------*/
/*  MOVE DATA
/*-----------------------------------------------*/

export function moveData() {

    return dispatch => {

        // firestore.collection('hukillsftw/users/users/joelfunkhouser@gmail.com/locations').get().then(async(querySnapshot) => {
        //     var collection = [];
        //     querySnapshot.forEach((doc) => {
        //         collection.push({ ...doc.data(), id: doc.id });
        //     });

        //     for (let doc of collection) {
        //         var date = moment(doc.timestamp).toDate();
        //         doc.timestamp = date;
        //         await firestore.collection('hukillsftw/users/users/joelfunkhouser@gmail.com/locations').doc(doc.id).update(doc);
        //     }
        // });
    };
}

/*-----------------------------------------------*/
/*  Activity Log
/*-----------------------------------------------*/

export function saveActivity(handle, log, action, id, description, record, { tab } = {}) {

    return async dispatch => {

        try {
            var activity = toolbox.sanitize({ log, action, id, description, record: toolbox.sanitize(record), date: new Date() });
            activity.handle = handle;
            
            const userId = firebase.auth().currentUser?.email;

            dispatch({ type: types.ACTIVITY_LOG + '_SAVE_PENDING' });
            const doc = await addDoc(collection(db, `${handle}/users/users/${userId}/activity-log`), activity);
            dispatch({ type: types.ACTIVITY_LOG + '_SAVE_FULFILLED' });
        
        } catch (error) {
            toolbox.process_error(error, 'Activity NOT Logged!');
        }
    };
}
export function subActivityLog(handle, logTypes, id, limit_) {

    return async dispatch => {

		dispatch({ type: types.ACTIVITY_LOG + '_CLEAR' });
        dispatch({ type: types.ACTIVITY_LOG + '_PENDING' });

        logTypes = logTypes.split('|');

        var query_ = query(collectionGroup(db, 'activity-log'), where('handle', '==', handle), where('log', 'in', logTypes), orderBy('date', 'desc'));
        if (id) query_ = query(query_, where('id', '==', id));
        var query_limit_ = query(query_, limit(limit_));

        const unsubscribe = onSnapshot(query_, async (querySnapshot) => {

            const snapshot = await getCountFromServer(query_limit_);
            const count = snapshot.data().count;
            
            var activity_log = [];
            querySnapshot.forEach((doc) => {
                activity_log.push({ ...doc.data(), userId: doc.ref.parent.parent.id });
            });

            dispatch({ type: types.ACTIVITY_LOG + '_FULFILLED', data: { data: activity_log, totalCount: count }, unsubscribe });
        });
    };
}
export function subUserActivityLog(handle, userId, limit_) {

	return async dispatch => {

		dispatch({ type: types.ACTIVITY_LOG + '_CLEAR' });
		dispatch({ type: types.ACTIVITY_LOG + '_PENDING' });

        if (userId) {
            var query_ = query(collection(db, `${handle}/users/users/${userId}/activity-log`), orderBy('date', 'desc'), limit(limit_));
            const unsubscribe = onSnapshot(query_, async (querySnapshot) => {

                var activity_log = [];
                querySnapshot.forEach((doc) => {
                    activity_log.push({ ...doc.data() });
                });

                var query_ = query(collection(db, `${handle}/users/users/${userId}/activity-log`), orderBy('date', 'desc'));
                const snapshot = await getCountFromServer(query_);
                const count = snapshot.data().count;

                dispatch({ type: types.ACTIVITY_LOG + '_FULFILLED', data: { data: activity_log, totalCount: count }, unsubscribe });
            });
        }
	};
}

/*-----------------------------------------------*/
/*  Error Log
/*-----------------------------------------------*/

export function logError(handle, error) {

    return async dispatch => {

        dispatch({ type: types.ERROR_LOG + '_SAVE_PENDING' });

        var existingRef = await firestore.collection(handle + '/error-log/error-log/').where('message', '==', error.message).where('pathname', '==', error.pathname).where('stack', '==', error.stack).where('resolved', '==', false).get();
        var existing = { ...existingRef.docs[0]?.data(), id: existingRef.docs[0]?.id };

        if (!existing?.id) {
            firestore.collection(handle + '/error-log/error-log/').add(error).then((doc) => {
                dispatch({ type: types.ERROR_LOG + '_SAVE_FULFILLED' });
            }).catch((error) => {
                toolbox.process_error(error, 'Error NOT Logged!');
            });
        } else {
            dispatch({ type: types.ERROR_LOG + '_SAVE_FULFILLED' });
        }
    };
}

/*-----------------------------------------------*/
/*  Lightbox
/*-----------------------------------------------*/

export function lightboxShow(value) {

    return async dispatch => {
        dispatch({ type: types.LIGHTBOX_SHOW, data: value });
    };
}
export function lightboxSources(sources) {

    return async dispatch => {
        dispatch({ type: types.LIGHTBOX_SOURCES, data: sources });
    };
}

/*-----------------------------------------------*/
/*  Flutter Virtual Keyboard
/*-----------------------------------------------*/

export function virtualKeyboard(visible) {

    return async dispatch => {
        dispatch({ type: types.FLUTTER_KEYBOARD, data: visible });
    };
}

/*-----------------------------------------------*/
/*  Help Panel
/*-----------------------------------------------*/

export function showHelp(show, url='') {

    return async dispatch => {

        dispatch({ type: types.HELP + '_URL', data: 'https://assetmanager.mobiletrack.systems/help/docs' + url });
        dispatch({ type: types.HELP + '_SHOW', data: show });

    };
}
