import React, { Fragment } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

var _ = require('lodash');

export default function StatusKey(props) {

    var content;

    if (props.statusParents) {

        content = props.statusParents.map((type) => {

            var filterCustom = _.filter(props.statusTypes, { parent: type.id });
            var custom = filterCustom.map((custom) => {
                if (custom.deleted) return null;

                return(
                    <div key={ type.id + custom.id } className="my-2 text-nowrap">
                        <span style={{ color: '#' + custom.color }}>
                            <i className="fa fa-circle"></i>
                        </span> &nbsp;
                        { custom.name }
                    </div>
                );
            });

            return (
                <Fragment key={ type.id }>
                    <div className="my-2 text-nowrap" style={{ backgroundColor: '#eeeeee' }}>
                        <span style={{ color: '#' + type.color }}>
                            <i className="fa fa-circle"></i>
                        </span> &nbsp;
                        <strong>{ type.name }</strong>
                    </div>
                    { custom }
                </Fragment>
            );

        });

    } else {

        content = props.statusTypes?.map((custom) => {
            return (
                <div key={ custom.id }>
                    <span style={{ color: custom.color }}>
                        <i className="fa fa-circle"></i>
                    </span> &nbsp;
                    <span>{ custom.name }</span>
                </div>
            );
        });
    }

    return (

        <div style={ props.style }>
            <OverlayTrigger placement={ 'bottom' } trigger="click" rootClose={ true } overlay={
                <Popover id={ `popover-positioned` }>
                    <Popover.Title as="h3">{ 'Status Key' }</Popover.Title>
                    <Popover.Content style={ { width: '276px', maxHeight: '600px', overflowY: 'scroll', overflowX: 'hidden' } }>
                        { content }
                    </Popover.Content>
                </Popover>
            }>
                <button className="btn btn-default btn-sm" style={{ borderLeft: '5px solid #8FBC8B' }}>
                    <span className="">Status Key</span>
                </button>
            </OverlayTrigger>
        </div>

    );
};
