import React, { useEffect } from 'react';
import { Route } from "react-router-dom";
import { Spinner } from 'enspire-manager-framework';
import { Link, useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import Projects from 'components/dashboard/projects/projects';
import Overview from 'components/dashboard/overview/overview';
import FieldServiceMap from 'components/dashboard/field-service-map/field-service-map';
import CatchErrors from 'components/common/catch-errors';
import moment from 'moment';
import { useSelector } from 'react-redux';

const Dashboard = (props) => {

    const location = useLocation();
    const history = useHistory();

    const user_permissions = useSelector(store => store.users.user_permissions);

    const segments = 3;

    useEffect(() => {
        if (!location.pathname.split('/')[segments]) history.replace(location.pathname + '/overview');
    }, []);

    const checkActive = (segment, parameter) => {
        if (location.pathname.split('/')[segment] == parameter) return '-active';
        else return '';
    };

    return (
        <>
            <div className="col-12">
                <div className="row">
                    <div className="custom-menu" style={{ height: '80vh' }}>
                        <h2>Dashboard </h2>
                        <hr />
                        {user_permissions?.DASHBOARD_OVERVIEW &&
                            <Link
                                to={props.match.url + '/overview'}>
                                <div className={`custom-menuitem${checkActive(segments, 'overview')}`}>
                                    Financial Overview
                                </div>
                            </Link>
                        }
                        {user_permissions?.DASHBOARD_PROJECTS &&
                            <Link
                                to={props.match.url + '/projects'}>
                                <div className={`custom-menuitem${checkActive(segments, 'projects')}`}>
                                    Projects
                                </div>
                            </Link>
                        }
                        {user_permissions?.DASHBOARD_SERVICE_MAP &&
                            <Link
                                to={props.match.url + '/field_service_map/all/' + moment().format('YYYY-MM-DD')}>
                                <div className={`custom-menuitem${checkActive(segments, 'field_service_map')}`}>
                                    Field Service Map
                                </div>
                            </Link>
                        }
                    </div>

                    {/* --------------------------------------------------------------------------
                                    |----first col----|--------center col--------|--third col-|
                        COLUMN 1 - /:handle/customers/:customer_id/:form/:form_id/:tab/:tab_id
                    --------------------------------------------------------------------------- */}
                    <div className='col ml-2'>
                        {user_permissions?.DASHBOARD_OVERVIEW &&
                            <Route path={props.match.path + '/overview'} render={(route_props) =>
                                <div className="row">
                                    <div className='col-6'>
                                        <React.Suspense fallback={<Spinner style={{ marginTop: '20%' }} />}>
                                            <CatchErrors className={'m-t-lg'}>
                                                <Overview {...route_props} />
                                            </CatchErrors>
                                        </React.Suspense>
                                    </div>
                                </div>
                            } />
                        }

                        {user_permissions?.DASHBOARD_PROJECTS &&
                            <Route path={props.match.path + '/projects'} render={(route_props) =>
                                <div className="row">
                                    <div className='col-12'>
                                        <React.Suspense fallback={<Spinner style={{ marginTop: '20%' }} />}>
                                            <CatchErrors className={'m-t-lg'}>
                                                <Projects {...route_props} />
                                            </CatchErrors>
                                        </React.Suspense>
                                    </div>
                                </div>
                            } />
                        }

                        {user_permissions?.DASHBOARD_SERVICE_MAP &&
                            <Route path={props.match.path + '/field_service_map'} render={(route_props) =>
                                <div className="row">
                                    <div className='col-12'>
                                        <React.Suspense fallback={<Spinner style={{ marginTop: '20%' }} />}>
                                            <CatchErrors className={'m-t-lg'}>
                                                <FieldServiceMap {...route_props} />
                                            </CatchErrors>
                                        </React.Suspense>
                                    </div>
                                </div>
                            } />
                        }
                    </div>

                </div>
            </div>
        </>
    );
};
export default Dashboard

