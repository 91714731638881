import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/storage";

const firebaseConfigDevelopment = {
  apiKey: "AIzaSyByaWLfJQm3GVyfJLHSRI62t2Q4VDvWyYE",
  authDomain: "mobiletrack-dispatch-develop.firebaseapp.com",
  projectId: "mobiletrack-dispatch-develop",
  storageBucket: "mobiletrack-dispatch-develop.appspot.com",
  messagingSenderId: "153228357480",
  appId: "1:153228357480:web:902ae05ca743386a3a30dd",
};

const firebaseConfigProduction = {
  apiKey: "AIzaSyASMweZtkNuaSSJd0xSnyXFS7h2afvskI8",
  authDomain: "mobiletrack-dispatch.firebaseapp.com",
  projectId: "mobiletrack-dispatch",
  storageBucket: "mobiletrack-dispatch.appspot.com",
  messagingSenderId: "841622756935",
  appId: "1:841622756935:web:a4b852c769a0c34f4adb62",
  measurementId: "G-PXQ03P3PLE",
};

firebase.initializeApp(process.env.REACT_APP_FIREBASE_ENV === 'development' 
  ? firebaseConfigDevelopment 
  : firebaseConfigProduction
);

export default firebase;
