import ErrorFallback from 'components/common/error-fallback';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { ModalForm } from 'enspire-manager-framework';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const CatchErrors = (props) => {
    const isDev = (process.env.REACT_APP_FIREBASE_ENV == 'development');
    const history = useHistory();

    return (
        <>
            {isDev
                ? (
                    <>
                        {/* <div style={{ border: '3px solid red' }}> */}
                            {props.children}
                        {/* </div> */}
                    </>
                )
                : (props?.modal)
                    ? (
                        <ErrorBoundary FallbackComponent={({ error }) => (
                            <ModalForm confirm_button_disabled={true} history={history}>
                                <ErrorFallback error={error} className={props?.className} />
                            </ModalForm>
                        )}>
                            {props.children}
                        </ErrorBoundary>
                    ) : (
                        <ErrorBoundary
                            FallbackComponent={({ error }) => (
                                <ErrorFallback error={error} className={props?.className} />
                            )}
                        >
                            {props.children}
                        </ErrorBoundary>
                    )}
        </>
    );
};

export default CatchErrors;
