import * as types from 'actions/action-types';

var initialState = {
	quote: null,
	quote_pending: false,
	quote_save_pending: false,
	quote_unsubscribe: false,

	quotes: [],
	quotes_pending: false,
	quotes_save_pending: false,
	quotes_unsubscribe: false,

	quote_approved: null,
	quote_approved_pending: false,
	quote_approved_save_pending: false,
	quote_approved_unsubscribe: false,

	options_selected: [],
	options_selected_pending: false,
	options_selected_save_pending: false,
	options_selected_unsubscribe: false,

	quote_items: [],
	quote_items_pending: false,
	quote_items_unsubscribe: false,
};

const quotesReducer = (state = initialState, action) => {

	switch (action.type) {
		case types.QUOTE + '_PENDING': pending('quote'); break;
		case types.QUOTE + '_SAVE_PENDING': save_pending('quote'); break;
		case types.QUOTE + '_FULFILLED': fulfilled('quote'); break;

		case types.QUOTES + '_PENDING': pending('quotes'); break;
		case types.QUOTES + '_SAVE_PENDING': save_pending('quotes'); break;
		case types.QUOTES + '_FULFILLED': fulfilled('quotes'); break;
		case types.QUOTES + '_CLEAR': state = { ...state, quotes: [] }; break;

		case types.OPTIONS_SELECTED + '_PENDING': pending('options_selected'); break;
		case types.OPTIONS_SELECTED + '_SAVE_PENDING': save_pending('options_selected'); break;
		case types.OPTIONS_SELECTED + '_FULFILLED': fulfilled('options_selected'); break;

		case types.QUOTE_APPROVED + '_PENDING': pending('quote_approved'); break;
		case types.QUOTE_APPROVED + '_SAVE_PENDING': save_pending('quote_approved'); break;
		case types.QUOTE_APPROVED + '_FULFILLED': fulfilled('quote_approved'); break;

		case types.QUOTE_ITEMS + '_PENDING': pending('quote_items'); break;
		case types.QUOTE_ITEMS + '_FULFILLED': fulfilled('quote_items'); break;
		case types.QUOTE_ITEMS + '_CLEAR': state = { ...state, quote_items: [] }; break;

		default: break;
	}

	return state;

	function pending(table) {
		state = {
			...state,
			[table + '_pending']: true,
		};
	}
	function fulfilled(table) {
		state = {
			...state,
			[table]: action.data,
			[table + '_pending']: false,
			[table + '_save_pending']: false,
			[table + '_unsubscribe']: (action.unsubscribe) ? action.unsubscribe : state[table + '_unsubscribe'],
		};
	}
	function save_pending(table) {
		state = {
			...state,
			[table + '_save_pending']: true,
		};
	}
};
export default quotesReducer;


