import * as actions_admin from 'actions/admin-actions';
import * as actions_call_log from 'actions/call-log-actions';
import * as actions_profiles from 'actions/profiles-actions';
import * as actions_schedule from 'actions/schedule-actions';
import * as actions_service_requests from 'actions/service-request-actions';
import ActiveCalls from 'components/call-center/active-calls';
import Geocode from "react-geocode";
import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import { Button, ButtonGroup } from 'react-bootstrap';
import { CUSTOMERS, ADDRESSES, CONTACTS, ACTIVITY_LOG, CALL_LOG } from 'components/common/constants';
import { FormBuilder, CloseX, ValidateForm, ModalForm } from 'enspire-manager-framework';
import { GeoPoint } from 'firebase/firestore';
import { customer_fields, customer_form_layout } from 'components/customers/customer/customer-form/customer-form-layout';
import { service_request_fields, service_request_form_layout } from './service-request-form-layout';
import { toProperCase } from 'components/common/toolbox';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';

const moment = require('moment');
var _ = require('lodash');

const ServiceRequestForm = (props) => {

	/* Hooks --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

	const params = useParams();
	const history = useHistory();
	const dispatch = useDispatch();

	const user_permissions = useSelector((store) => store.users?.user_permissions);
	const customers = useSelector((store) => store.customers);
	const services = useSelector((store) => store.services);
	const settings = useSelector((store) => store.settings);
	const employees = useSelector((store) => store.employees);
	const users = useSelector((store) => store.users);
	const callLog = useSelector((store) => store.callLog);

	const editedBy = users.user.id;
	const isCall = props?.isCall;

	const [state, setState] = useState({
		populated: false,
		isAddressIdSet: false,
		form_error: null,
		show_customer_form: false,
		customer: customer_fields(),
		service_request: service_request_fields(settings?.settings?.work_orders?.default_duration ?? 2),
		expand: false,
		isLoading: false,
		search: '',
		options: [],
		editedAddress: false,
		editedDisplay: false,
	});

	/* Effects --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/


	// If existing call, populate form
	useEffect(() => {
		populateForm();
		return () => { dispatch(actions_profiles.clearCustomerFromSearch()); };
	}, [services.service_request]);

	// If customer selected, format customer
	useEffect(() => {
		if (!parseInt(params.request_id)) {
			setState(prev => ({ ...prev,
				service_request: {
					...prev?.service_request,
					profileId: customers.customer_search?.id ?? '',
					_customerName: customers.customer_search?.displayName ?? ''
				}
			}));
		}
	}, [customers.customer_search]);

	/* Handlers --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

	const handleTerms = (isServiceCall) => {
		setState(prev => ({ ...prev, service_request: { ...prev.service_request, isServiceCall } }));
	};
	const handleDate = (field, date) => {
		var form_error = _.filter(state.form_error, (o) => { return o.field !== field; });
		setState(prev => ({ ...prev, form_error, service_request: { ...prev.service_request, [field]: date } }), () => {
			if (field == 'endDate') setState(prev => ({ ...prev, isEndDateSet: true }));
			if (!state.isEndDateSet && field != 'endDate') setState(prev => ({ ...prev, service_request: { ...prev.service_request, endDate: moment(state.service_request.startDate).add(2, 'hours').toDate() } }));
		});
	};
	const handleClear = (field) => {
		dispatch(actions_profiles.clearCustomerFromSearch());
		setState(prev => ({ ...prev, service_request: { ...prev?.service_request, [field]: null } }));
	};
	const handleChange = (table, event) => {
		var treatAddressAsCustomer = settings.settings?.customer?.treatAddressAsCustomer;
		let form_error = _.filter(state.form_error, (o) => { return o.field !== event.target.name; });

		const copySummary = (event?.target.name == 'summary' && state.service_request.summary == state.service_request.description);

		if (!treatAddressAsCustomer && (event.target.name == 'firstName') && !state.editedDisplay) {
			setState(prev => ({ ...prev, customer: { ...state.customer, [event.target.name]: event.target.value, displayName: event.target.value + ' ' + state.customer.lastName } }));
		} else if (!treatAddressAsCustomer && (event.target.name == 'lastName') && !state.editedDisplay) {
			setState(prev => ({ ...prev, customer: { ...state.customer, [event.target.name]: event.target.value, displayName: state.customer.firstName + ' ' + event.target.value } }));
		} else if (treatAddressAsCustomer && event.target.name == 'displayName' && !state.editedAddress) {
			setState(prev => ({ ...prev, customer: { ...state.customer, [event.target.name]: event.target.value, addressLine1: event.target.value } }));
		} else {
			setState(prev => ({ ...prev, form_error, customer: { ...state.customer, [event.target.name]: event.target.value } }));
		}

		if (event.target.name == 'addressLine1') setState(prev => ({ ...prev, editedAddress: true }));
		if (event.target.name == 'displayName') setState(prev => ({ ...prev, editedDisplay: true }));

		setState(prev => ({ ...prev, form_error, [table]: { ...prev[table], [event.target.name]: event.target.value } }));
		if (copySummary) setState(prev => ({ ...prev, service_request: { ...prev.service_request, description: event?.target.value } }));
	};
	const handleCheckbox = (field, table) => {
		setState(prev => ({ ...prev, [table]: { ...prev?.[table], [field]: !prev?.[table][field] } }));
	};
	const handleTypeahead = (table, field, result) => {
		var form_error = _.filter(state.form_error, (o) => { return o.field !== field; });
		if (result?.[0]?.customOption) setState(prev => ({ ...prev, show_customer_form: true, customer: { ...prev.customer, displayName: result?.[0]?.target }}));
		else setState(prev => ({ ...prev, form_error, [table]: { ...prev?.[table], [field]: result?.[0]?.id }}));

		if (parseInt(result?.[0]?.id)) dispatch(actions_profiles.getCustomerFromSearch(params.handle, result?.[0]?.id));
		else dispatch(actions_profiles.clearCustomerFromSearch());
	};
	const handleAutocomplete = (place) => {
		const address = place.address_components.find((component) => component.types.includes('street_number'))?.long_name + ' ' + place.address_components.find((component) => component.types.includes('route'))?.long_name;
		const city = place.address_components.find((component) => component.types.includes('locality'))?.long_name;
		const stateLocation = place.address_components.find((component) => component.types.includes('administrative_area_level_1'))?.short_name;
		const zip = place.address_components.find((component) => component.types.includes('postal_code'))?.long_name;

		setState(prev => ({ ...prev, customer: { ...prev.customer, addressLine1: address, city, state: stateLocation, zip }}));
	};
	const handlePromote = (field) => {
		var newRequest = { ...state.service_request };
		let currentFav = newRequest.imageUrl;
		let elementIndex = parseInt(field.split('imageUrlAlt')[1]);
		
		newRequest.imageUrl = newRequest['imageUrlAlt' + elementIndex];
		newRequest['imageUrlAlt' + elementIndex] = currentFav;

		setState(prev => ({ ...prev, service_request: newRequest }));
	}
	const handleUpload = (results) => {
		var newRequest = { ...state.service_request };

		if (Array.isArray(results)) { // Upload new image
			for (var result of results) {
				var [field, filename] = result;

				if (!newRequest.imageUrl) {
					newRequest.imageUrl = filename;
				} else {
					for (let index = 0; index < 6; index++) {
						if (!newRequest['imageUrlAlt' + index]) {
							newRequest['imageUrlAlt' + index] = filename;
							break;
						}
					}
				}
			}
		} else if (results == 'imageUrl') { // delete main image
			if (newRequest['imageUrlAlt0']) {
				newRequest.imageUrl = newRequest['imageUrlAlt0'];
				for (let index = 0; index < 6; index++) {
					newRequest['imageUrlAlt' + index] = newRequest['imageUrlAlt' + (index + 1)] ?? null;
				}
			} else {
				newRequest.imageUrl = null;
			}

		} else { // Delete alt image
			let elementIndex = parseInt(results.split('imageUrlAlt')[1]);
			for (let index = elementIndex; index < 6; index++) {
				newRequest['imageUrlAlt' + index] = newRequest['imageUrlAlt' + (index + 1)] ?? null;
			}
		}
		setState(prev => ({ ...prev, service_request: newRequest }));
	};

	/* Actions --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

	const archiveServiceRequest = () => {
		dispatch(actions_service_requests.deleteServiceRequest(params.handle, { id: params.request_id }, () => {
			let location = '/' + params.handle + '/customers/' + params.customer_id + '/form/0/0/service_requests/';
			history.push({ pathname: location });
		}));
	};
	const populateForm = () => {
		if (parseInt(params.request_id) || parseInt(params.customer_id)) {
			if (parseInt(params.request_id)) {

				let myServiceRequest = { ...services.service_request, requestDate: services.service_request?.requestDate.toDate() }
				const images = services.service_request?.imagesAlt;
				images?.forEach((image, index) => {
					myServiceRequest['imageUrlAlt' + index] = image;
				});
				myServiceRequest.id = params.request_id;

				setState(prev => ({ ...prev, service_request: myServiceRequest }));
			}
			if (parseInt(params.customer_id)) {
				dispatch(actions_profiles.getCustomerFromSearch(params.handle, params.customer_id));
			}
		} else {
			if (params.startDate) {
				setState(prev => ({ ...prev,
					service_request: {
						...prev.service_request,
						id: params.request_id,
						startDate: moment(params.startDate, 'X').toDate(),
						endDate: moment(params.endDate, 'X').toDate(),
						technicianId: params.technicianId,
					}
				}));
			}
		}
	};
	const cancelButtonCallback = () => {
		(isCall) ? props.closeModal() : history.goBack();
	};
	const saveIncomplete = () => {
		var call = { ...state?.service_request, userId: users.user.id, statusId: CALL_LOG.INCOMPLETE.id, type: 'service_request' };
		call.id = call.callId;
		delete call.callId;
		if (state.show_customer_form) {
			call.customer = { ...state.customer };
			call.profileId = '';
		}
		dispatch(actions_call_log.saveCallLog(params.handle, call, (callId) => {
			setState(prev => ({ ...prev, edited: false, service_request: { ...prev?.service_request, callId } }));
			// dispatch(actions_admin.saveActivity(params.handle, `Saved Incomplete '${toProperCase('service_request'.replace('_', ' '))}' Call Log`, callId, ACTIVITY_LOG.CALL_LOG_SAVED_INCOMPLETE?.id, editedBy));
		}));
	};
	const submitForm = (form_layout, cust_form_layout) => {

		let newServiceRequest = { ...state?.service_request };
		let imagesAlt = [];
		for (let index = 0; index < 5; index++) {
			if (state?.service_request['imageUrlAlt' + index]) imagesAlt.push(state?.service_request['imageUrlAlt' + index]);
			delete newServiceRequest['imageUrlAlt' + index]
		}
		newServiceRequest.imagesAlt = imagesAlt;

		// If new customer created, save customer first
		if (state.show_customer_form) {
			var form_error = ValidateForm(state.customer, cust_form_layout);
			setState(prev => ({ ...prev, form_error }));

			// Validate forms
			if (!form_error.length) {
				var form_error = ValidateForm(state?.service_request, form_layout);
				setState(prev => ({ ...prev, form_error }));
				if (!form_error.length) {
					if (params.workOrderId) dispatch(actions_schedule.deleteReserveTimeline(params.handle, params.workOrderId));

					// Save new customer
					dispatch(actions_profiles.saveProfile(params.handle, state.customer, 'CUSTOMER', editedBy, async (profileId, addressId, contactId) => {
						const geocode = await Geocode.fromAddress(
							`${state.customer?.addressLine1},${(state.customer?.addressLine2) ? ' ' + state.customer?.addressLine2 + ',' : ''} ${state.customer?.city}, ${state.customer?.state}, ${state.customer?.zip}`,
							`AIzaSyDovWF9_-LUGkC_tNoNp0wSVqGJ8mzxl1Y`, 'en', 'us'
						);

						dispatch(actions_service_requests.saveServiceRequest(
							params.handle,
							{ ...newServiceRequest, profileId, addressId, geopoint: new GeoPoint(geocode.results[0]?.geometry.location.lat, geocode.results[0]?.geometry.location.lng) },
							(newServiceRequestId, newWorkOrderId) => {
								newServiceRequest.id = newServiceRequest.callId;
								delete newServiceRequest.callId;

								dispatch(actions_call_log.saveCallLog(params.handle, { ...newServiceRequest, type: 'service_request', profileId, addressId, userId: users.user.id, statusId: CALL_LOG.COMPLETE.id, endTime: new Date() }, () => {
									let location = '/' + params.handle + '/customers/' + profileId + '/form/0/0/service_requests/' + newServiceRequestId + '/work_orders/' + newWorkOrderId + '/items';
									history.push({ pathname: location });

									if (isCall) {
										props?.closeModal();
										// dispatch(actions_admin.saveActivity(params.handle, `Saved Call Log into Service Request #${newServiceRequestId}`, newServiceRequestId, ACTIVITY_LOG.CALL_LOG_TO_SERVICE_REQUEST?.id, editedBy));
									}
								}));
							}
						));
					}));
				}
			}

		} else {
			var form_error = ValidateForm(state?.service_request, form_layout);
			setState(prev => ({ ...prev, form_error }));

			if (!form_error.length) {
				if (params.workOrderId) dispatch(actions_schedule.deleteReserveTimeline(params.handle, params.workOrderId));

				dispatch(actions_service_requests.saveServiceRequest(
					params.handle,
					{ ...newServiceRequest, geopoint: customers.customer_search.addresses.find((add) => add.addressId == newServiceRequest.addressId)?.geopoint ?? null },
					(newServiceRequestId, newWorkOrderId) => {
						if (isCall) {
							dispatch(actions_call_log.saveCallLog(params.handle, { ...newServiceRequest, type: 'service_request', userId: users.user.id, statusId: CALL_LOG.COMPLETE.id, endTime: new Date() }));
						}

						if (parseInt(params.request_id)) {
							history.goBack();
						} else {
							let location = (newWorkOrderId)
								? '/' + params.handle + '/customers/' + newServiceRequest.profileId + '/form/0/0/service_requests/' + newServiceRequestId + '/work_orders/' + newWorkOrderId + '/items'
								: '/' + params.handle + '/customers/' + newServiceRequest.profileId + '/form/0/0/service_requests/' + newServiceRequestId;
							history.replace({ pathname: location });
							if (isCall) {
								props?.closeModal();
								// dispatch(actions_admin.saveActivity(params.handle, `Saved Call Log into Service Request #${newServiceRequestId}`, newServiceRequestId, ACTIVITY_LOG.CALL_LOG_TO_SERVICE_REQUEST?.id, editedBy));
							}
						}
					}
				));
			}
		}
	};

	/* Constants --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

	const isCallComplete = isCall && (state?.service_request?.statusId == CALL_LOG.COMPLETE.id);
	const service_form_layout = service_request_form_layout(params.handle, params.request_id, state.service_request, firebase, handleTerms, handlePromote, customers.customer_search, ADDRESSES, employees.employees, state.show_customer_form, settings, isCall, isCallComplete);
	const cust_form_layout = customer_form_layout(false, settings, CUSTOMERS, CONTACTS);
	const existing = (parseInt(params.request_id)) ? true : false;
	const buttonDisabled = (existing && !user_permissions?.SERVICE_REQUESTS_EDIT || !existing && !user_permissions?.SERVICE_REQUESTS_CREATE);
	
	/* Render --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

	console.log(state.service_request);

	return (

		<ModalForm {...props}
			style={1}
			size={'xl'}
			expand={isCall && true}
			history={props.history}
			modal_header={(isCall) ? 'Call Center' : 'Service Request'}
			no_padding={true}
			bodyClassName={'mx-3 my-2'}
			cancel_button_title="FINISHED"
			cancel_button_callback={cancelButtonCallback}
			delete_button_title={(isCall && state?.service_request?.statusId != CALL_LOG.COMPLETE.id && 'service_request' != 'transfer') ? "SAVE INCOMPLETE" : (!isCall && existing && user_permissions?.SERVICE_REQUESTS_DELETE) && "Archive"}
			delete_button_callback={(isCall && state?.service_request?.statusId != CALL_LOG.COMPLETE.id && 'service_request' != 'transfer') ? saveIncomplete : (!isCall && existing && user_permissions?.SERVICE_REQUESTS_DELETE) && archiveServiceRequest}
			save_button_title={'Save Service Request'}
			confirm_button_disabled={isCallComplete || buttonDisabled}
			submitFormHandler={() => submitForm(service_form_layout, cust_form_layout)}
			show_spinner={callLog.call_log_save_pending || services.service_request_save_pending || customers.customer_search_pending}
		>
			<div id="container10" className="col-12" style={{ height: '100%', overflow: 'hidden' }}>
				<div className="row">
					{isCall &&
						<div id="width1" className="col-5" style={{ paddingLeft: 0 }}>
							<ActiveCalls setState={setState} state={state} />
						</div>
					}

					<div className={'col-12'}>
						<div className="row mb-3">
							{state.show_customer_form &&
								<CloseX className="mr-2" onClick={() => setState(prev => ({ ...prev, show_customer_form: false }))} />
							}
						</div>

						{state.show_customer_form &&
							<div className="animated fadeInDown">
								<FormBuilder
									callbacks={{
										text: handleChange.bind(this, 'customer'),
										select: handleChange.bind(this, 'customer'),
										autocomplete: handleAutocomplete,
										checkbox: (field) => handleCheckbox(field, 'customer'),
									}}
									form_error={state.form_error}
									form={cust_form_layout}
									record={state.customer}
								/>
							</div>
						}
						{state?.service_request &&
							<FormBuilder
								callbacks={{
									text: handleChange.bind(this, 'service_request'),
									select: handleChange.bind(this, 'service_request'),
									date: handleDate,
									typeahead: (field, result) => handleTypeahead('service_request', field, result),
									clear: handleClear,
									checkbox: (field) => handleCheckbox(field, 'service_request'),
									dropzone: handleUpload,
								}}
								form_error={state.form_error}
								form={service_form_layout}
								record={state?.service_request}
							/>
						}
					</div>
				</div>
			</div>
		</ModalForm>
	);
};

export default ServiceRequestForm;
