import * as types from 'actions/action-types';

var initialState = {
	contact: null,
	contact_pending: false,
	contact_save_pending: false,
	contact_unsubscribe: false,
};

const contactsReducer = (state = initialState, action) => {

	switch(action.type) {
		case types.CONTACT + '_PENDING': pending('contact'); break;
		case types.CONTACT + '_SAVE_PENDING': save_pending('contact'); break;
		case types.CONTACT + '_FULFILLED': fulfilled('contact'); break;
		case types.CONTACT + '_CLEAR': clear('contact', {}); break;

		default : break;
	}

	return state;

	function pending(table) {
		state = { ...state,
			[table + '_pending']: true,
		};
	}
	function save_pending(table) {
		state = { ...state,
			[table + '_save_pending']: true,
		};
	}
	function fulfilled(table) {
		state = { ...state,
			[table]: action.data,
			[table + '_pending']: false,
			[table + '_save_pending']: false,
			[table + '_unsubscribe']: (action.unsubscribe) ? action.unsubscribe : state[table + '_unsubscribe'],
		};
	}
	function clear(table, value) {
		state = {
			...state,
			[table]: value,
		}
	}
}
export default contactsReducer;


