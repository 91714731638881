import * as types from 'actions/action-types';

var initialState = {
	service_request: null,
	service_request_pending: false,
	service_request_save_pending: false,
	service_request_unsubscribe: false,

	service_requests: [],
	service_requests_pending: false,
	service_requests_save_pending: false,
	service_requests_unsubscribe: false,

	service_requests_map_coordinates: null,
	service_requests_map_coordinates_pending: false,
};

const serviceReducer = (state = initialState, action) => {

	switch (action.type) {
		case types.SERVICE_REQUEST + '_PENDING': pending('service_request'); break;
		case types.SERVICE_REQUEST + '_SAVE_PENDING': save_pending('service_request'); break;
		case types.SERVICE_REQUEST + '_SAVE_FULFILLED': save_fulfilled('service_request'); break;
		case types.SERVICE_REQUEST + '_FULFILLED': fulfilled('service_request'); break;
		case types.SERVICE_REQUEST + '_CLEAR': clear('service_request', {}); break;

		case types.SERVICE_REQUESTS + '_PENDING': pending('service_requests'); break;
		case types.SERVICE_REQUESTS + '_SAVE_PENDING': save_pending('service_requests'); break;
		case types.SERVICE_REQUESTS + '_FULFILLED': fulfilled('service_requests'); break;
		case types.PROJECTS + '_PENDING': pending('projects'); break;
		case types.PROJECTS + '_FULFILLED': fulfilled('projects'); break;

		case types.SERVICE_REQUEST_MAPBOX_SEARCH + '_PENDING': pending('service_requests_map_coordinates'); break;
		case types.SERVICE_REQUEST_MAPBOX_SEARCH + '_FULFILLED': fulfilled('service_requests_map_coordinates'); break;

		default: break;
	}

	return state;

	function pending(table) {
		state = {
			...state,
			[table + '_pending']: true,
		};
	}
	function fulfilled(table) {

		state = {
			...state,
			[table]: action.data,
			[table + '_pending']: false,
			[table + '_save_pending']: false,
			[table + '_unsubscribe']: (action.unsubscribe) ? action.unsubscribe : state[table + '_unsubscribe'],
		};
	}
	function save_pending(table) {
		state = {
			...state,
			[table + '_save_pending']: true,
		};
	}
	function save_fulfilled(table) {
		state = {
			...state,
			[table + '_save_pending']: false,
		};
	}
	function clear(table, value) {
		state = {
			...state,
			[table]: value,
		};
	}
};
export default serviceReducer;


