import * as actions_quotes from 'actions/quotes-actions';
import React, { useState } from 'react';
import SignaturePad from 'signature_pad';
import firebase from 'firebase/compat/app';
import { ModalAlert } from 'enspire-manager-framework';
import { UCFirst } from 'components/common/toolbox';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';

const storage = firebase.storage();
const storageRef = storage.ref();

export default function Signature() {

    /* Hooks ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

    const { handle, tableName, quote_id, validateSignature, customer_id } = useParams();
    const dispatch = useDispatch();

    const quickbooksSettings = useSelector(store => store.quickbooks?.quickbooks);
    const company = useSelector(store => store.company.company);

    const [signaturePad, setSignaturePad] = useState(null);
    const [saving, setSaving] = useState(false);
    const [rendering, setRendering] = useState(false);
    const [saved, setSaved] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [declined, setDeclined] = useState(false);
    const [signatureStarted, setSignatureStarted] = useState(false);
    const [downloadUrl, setDownloadUrl] = useState();

    /* Effects ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

    // Update error message if signature link has expired
    React.useEffect(() => {
        if (validateSignature < Date.now()) {
            setErrorMessage('Signature Link Expired');
        } else {
            setErrorMessage(null);
        }
    }, [validateSignature]);

    // Initialize signature pad
    React.useEffect(() => {

        const canvas = window.document.querySelector("canvas");
        const signaturePad = new SignaturePad(canvas);
        setSignaturePad(signaturePad);

        signaturePad.addEventListener("endStroke", () => {
            setSignatureStarted(true);
        });

        window.onresize = resizeCanvas;
        resizeCanvas();

        function resizeCanvas() {
            const ratio = Math.max(window.devicePixelRatio || 1, 1);
            canvas.width = canvas.offsetWidth * ratio;
            canvas.height = canvas.offsetHeight * ratio;
            canvas.getContext("2d").scale(ratio, ratio);
            signaturePad.clear();
        }

        return () => window.onresize = null;
    }, []);

    /* Handlers ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

    const handleAccept = () => {
        var dataUrl = signaturePad.toDataURL();
        var ref = storageRef.child(handle + '/dispatch_attachments/signatures/' + tableName + '/' + quote_id);
        var uploadTask = ref.putString(dataUrl, 'data_url');

        setSaving(true);

        uploadTask.on('state_changed', (snapshot) => {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        }, (error) => {
            console.error(error);
        }, async () => {
            let signatureUrl = await uploadTask.snapshot.ref.getDownloadURL();
            handleQuote('accept', signatureUrl);
        });
    };
    const handleDecline = () => {
        ModalAlert({
            title: 'Are you sure?',
            text: "Declining the will halt all further progress on your service until other arrangements are made",
            icon: 'warning',
            show_cancel: true,
            confirm_color: '#8FBC8B',
            confirm_text: 'Yes, Decline',
            callback_success: async () => {
                handleQuote('decline', null);
                setDeclined(true);
            }
        });
    };

    const handleQuote = async (action, signatureUrl) => {
        dispatch(actions_quotes.customerSignsQuote(handle, quote_id, validateSignature, action, signatureUrl, quickbooksSettings, company, async (result) => {
            if (result.result == 'unauthorized') {
                setErrorMessage('Unauthorized');
            } else if (result.result == 'error') {
                setErrorMessage(result.message);
            } else {

                dispatch(actions_quotes.createQuotePdf({
                    handle: handle,
                    customerId: customer_id,
                    quoteId: quote_id,
                    copy: 'CUSTOMER'
                }, (result) => {
                    setDownloadUrl(result.data.downloadUrl);
                    setRendering(false);
                    setSaved(true);
                }));
            }
        }));
    };

    const handleClear = () => {
        signaturePad.clear();
        setSignatureStarted(false);
    };

    const downloadFile = () => {
        var xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = (event) => {
            var blob = xhr.response;
            const blobUrl = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = `quote${moment(new Date()).format('MMDDYYYY')}.pdf`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        };
        xhr.open('GET', downloadUrl);
        xhr.send();
    };

    /* Render ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

    return (
        <div id="signature-body">

            <div id="signature-pad" className="signature-pad">
                <div className="signature-pad--body">
                    <canvas></canvas>
                </div>
                <div className="signature-pad--footer">
                    <div className="description mb-2">Sign above</div>

                    <div style={{ color: '#666666' }}>
                        {errorMessage
                            ? <h2 style={{ color: 'red' }}>{errorMessage}</h2>
                            : <>
                                {declined
                                    ? <h2 style={{ textAlign: 'center' }}>Thank You!  Please call our office if there's anything<br />else we can do to serve you!</h2>
                                    : <>
                                        {saved
                                            ? <>
                                                <h2 style={{ textAlign: 'center' }}>
                                                    Signature Saved!  Thank You! <br />
                                                    Here is a printable copy of the Approved Quote for your reference.
                                                </h2>
                                                <a href={downloadUrl} target='_blank' disabled={rendering} className='btn btn-info'>{(rendering) ? <>Preparing... <span className="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true"></span></> : 'Approved Quote'}</a>
                                            </>
                                            : <p style={{ fontSize: '16px' }}>I have read <strong>{UCFirst(tableName)} #{quote_id}</strong> and agree to the terms set forth within:</p>
                                        }
                                    </>
                                }
                            </>
                        }
                    </div>

                    {!saved &&
                        <div className="row">
                            <div className="col-3">
                                <button className="btn btn-danger btn-sm mt-3" onClick={handleDecline}>Declined</button>
                            </div>
                            <div className="col-9">
                                <button className={'btn btn-success btn-sm float-right mt-3'} disabled={((!signatureStarted) ? 'disabled' : '')} onClick={handleAccept}>
                                    {saving &&
                                        <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                                    }
                                    Apply Signature & Accept Terms

                                </button>
                                <button className="btn btn-default btn-sm float-right mt-3 mr-3" onClick={handleClear}>Clear Signature Pad</button>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};