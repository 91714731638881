import React from 'react';
import { Widget } from 'enspire-manager-framework';
import { numberWithCommas } from 'components/common/toolbox';

export default function StatWidget(props) {

    var total = 'X';
    const invoiced = props?.invoices?.reduce((a, b) => a + (b['invoiced'] || 0), 0);
    const collected = props?.invoices?.reduce((a, b) => a + (b['collected'] || 0), 0);
    const quoted = props?.quotes?.reduce((a, b) => a + (b['quoted'] || 0), 0);
    const approved = props?.quotes?.reduce((a, b) => a + (b['approved'] || 0), 0);

    switch (props.type) {
        case 'invoiced':
            total = invoiced.toFixed(2);
            break;
        case 'collected':
            total = collected.toFixed(2);
            break;
        case 'outstanding':
            total = (invoiced - collected).toFixed(2);
            break;
        case 'percentageInvoiced':
            if (!invoiced) total = 0;
            else total = ((collected ?? 0) / (invoiced ?? 0) * 100).toFixed(2);
            break;
        case 'quoted':
            total = quoted.toFixed(2);
            break;
        case 'approved':
            total = approved.toFixed(2);
            break;
        case 'unapproved':
            total = (quoted - approved).toFixed(2);
            break;
        case 'percentageQuoted':
            if (!quoted) total = 0;
            else total = ((approved ?? 0) / (quoted ?? 0) * 100).toFixed(2);
            break;
    }

    return (

        <div className="col">
            <Widget
                type={2}
                color_number={props.color_number}
                spinnerColor={'white'}
                fetching={props.fetching}
                icon={`fa ${props.icon} fa-3x`}
                text={props.text}
                title={(props?.prefix ?? '') + numberWithCommas(total) + (props?.suffix ?? '')}
            />
        </div>
    );
};
