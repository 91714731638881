
import React, { Fragment } from 'react';
import { ROLES } from 'components/common/constants';
import _ from 'lodash';

const isDev = (process.env.REACT_APP_FIREBASE_ENV == 'development');

export function transfer_fields() {
    return (
        {
            callTime: '',
            profileId: '',
            technicianId: '',
        }
    );
}

export function transfer_form_layout(handle, request_id, customer, employees, show_customer_form, isComplete) {

    if (customer && Object.keys(customer).length) { var customer_option = <option value={customer.id}>{customer.displayName}</option>; }

    var employeeArray = [];
    for (let employee of Object.values(employees)) {
        if (employee.roles?.includes(ROLES.DISPATCHER.id)) employeeArray.push({ ...employee });
    }

    var technician_options = _.orderBy(employeeArray, ['lastName'], ['asc']).map((tech, index) => {
        return (
            <option key={'tech' + index} value={tech.id}>{tech.firstName + ' ' + tech.lastName}</option>
        );
    });

    return (
        [{
            column_class: 'col-md-12',
            body: [{
                section:
                    <Fragment>
                        <h3 className="m-t-none m-b">Transfer Information</h3>
                    </Fragment>,
                layout: [
                    {
                        grid: 'col-12',
                        label: 'Call Time',
                        field: 'callTime',
                        type: 'date',
                        readOnly: true,
                        dateFormat: 'MM-dd-yyyy h:mm a',
                        showTimeSelect: true,
                        valid: ['required'],
                        selectsStart: true,
                    }, {
                        grid: 'col-12',
                        label: 'Customer',
                        field: 'profileId',
                        allow_new: true,
                        placeholder: 'Search for Customer',
                        table: handle + (isDev ? '-dev' : '') + '-profiles',
                        filter: 'customer',
                        fields: ['displayName', 'addresses', 'contacts'],
                        sort: [{ "modified": "desc" }, { "displayName": "asc" }],
                        target: 'displayName',
                        id: 'id',
                        type: (show_customer_form || customer_option) ? 'skip' : 'typeahead',
                        valid: ['required'],
                    }, {
                        grid: 'col-12',
                        label: 'Customer',
                        field: '_customerName',
                        type: (!customer_option) ? 'skip' : 'text',
                        clearButton: (!isComplete) && true,
                        // valid: ['required'],
                        disabled: true,
                    }, {
                        grid: 'col-12',
                        label: 'Employee',
                        field: 'technicianId',
                        type: (parseInt(request_id)) ? 'skip' : 'select',
                        valid: ['required'],
                        disabled: (isComplete) ? true : false,
                        options:
                            <Fragment>
                                <option key={'none'} value={null}>- Select -</option>
                                {technician_options}
                            </Fragment>,
                    }],
            },]
        }]
    );
}