import * as actions_admin from 'actions/admin-actions';
import * as actions_profiles from 'actions/profiles-actions';
import * as actions_quotes from 'actions/quotes-actions';
import * as actions_service_request from 'actions/service-request-actions';
import * as actions_settings from 'actions/settings-actions';
import * as actions_work_orders from 'actions/work-orders-actions';
import * as toolbox from 'components/common/toolbox';
import React, { useEffect, useState } from 'react';
import StatusSelect from 'components/common/status-select';
import { ACTIVITY_LOG, REQUESTS, SERVICE_REQUEST_TERMS, WORKORDERS } from 'components/common/constants';
import { Avatar, CloseX, DashboardDetail, Mapbox, ModalAlert, Spinner } from 'enspire-manager-framework';
import { Dropdown, SplitButton } from 'react-bootstrap';
import { imageSize, numPanels } from 'components/common/toolbox';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

const _ = require('lodash');
const moment = require('moment');

const ServiceRequestDashboard = (props) => {

    /* Hooks ------------------------------------------------------------------------------------------------------------------------------------*/

    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const routeLocation = useLocation();
    
    const admin = useSelector((store) => store.admin);
    const quotes = useSelector((store) => store.quotes);
    const services = useSelector((store) => store.services);
    const customers = useSelector((store) => store.customers);
    const workOrders = useSelector((store) => store.workOrders);
	const user_permissions = useSelector(store => store.users?.user_permissions);    
    
    const [durationTotals, setDurationTotals] = useState({ enroute: 0, onsite: 0 });
    const [processing, setProcessing] = useState(false);
    const [state, setState] = useState({
        mapAddress: null,
        showGenerateInvoice: false,
    });
    
    const pathname = routeLocation.pathname;
    const segments = pathname.split('/').length;
    const approved_quote = quotes.quote_approved;
    const customer = customers.customer;
    const service_request = services.service_request;
    const serviceAddress = _.find(customer?.addresses, { id: service_request?.addressId });
    const source = props?.source;
    var newRequest = false;

    /* Effects ------------------------------------------------------------------------------------------------------------------------------------*/

    useEffect(() => {
        newRequest = true;
        dispatch(actions_service_request.subServiceRequest(params.handle, params.request_id));
        dispatch(actions_work_orders.subWorkOrdersByRequestId(params.handle, params.request_id));

        return () => {
            var unsubscribe = services.service_request_unsubscribe;
            if (typeof unsubscribe === 'function') unsubscribe();

            var unsubscribe = workOrders.work_orders_unsubscribe;
            if (typeof unsubscribe === 'function') unsubscribe();
        };
    }, [params.request_id]);


    // useEffect(() => {
    //     if (service_request?.durationTotals) {
    //         const today = moment().format('YYYY-MM-DD');

    //         var activeCheckIn = [];
    //         workOrders?.work_orders?.forEach((workOrder) => {

    //             if (workOrder?.['check-in']?.[today] && workOrder?.['check-in']?.[today][workOrder?.['check-in']?.[today].length - 1]?.type != 'checkout') {
    //                 activeCheckIn.push(workOrder?.['check-in']?.[today][workOrder?.['check-in']?.[today].length - 1]);
    //             }
    //         });

    //         if (activeCheckIn.length > 0) {

    //             // Set initially
    //             var runningTime = {
    //                 enroute: 0,
    //                 onsite: 0,
    //             };
    //             for (let checkIn of activeCheckIn) {
    //                 runningTime = { ...runningTime, [checkIn.type]: runningTime[checkIn.type] + moment().diff(moment(checkIn?.time.toDate()), 'minutes', true) };
    //             }
    //             setDurationTotals({ enroute: service_request.durationTotals.enroute + runningTime.enroute, onsite: service_request.durationTotals.onsite + runningTime.onsite });

    //             // Update evry minute
    //             const interval = setInterval(() => {
    //                 var runningTime = {
    //                     enroute: 0,
    //                     onsite: 0,
    //                 };
    //                 for (let checkIn of activeCheckIn) {
    //                     runningTime = { ...runningTime, [checkIn.type]: runningTime[checkIn.type] + moment().diff(moment(checkIn?.time.toDate()), 'minutes', true) };
    //                 }
    //                 setDurationTotals({ enroute: service_request.durationTotals.enroute + runningTime.enroute, onsite: service_request.durationTotals.onsite + runningTime.onsite });
    //             }, 60000);

    //             return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    //         } else {
    //             setDurationTotals(service_request.durationTotals);
    //         }

    //     } else {
    //         setDurationTotals({ enroute: 0, onsite: 0 });
    //     }
    // }, [service_request, workOrders.work_orders]);

    // Log Activity
    useEffect(() => {
        // if (newRequest && service_request) {
        //     // dispatch(actions_admin.saveActivity(props.match.params.handle, service_request.summary, params.request_id, ACTIVITY_LOG.SERVICE_REQUEST_VIEWED?.id, user?.email, true, { statusId: service_request?.statusId, customerId: service_request.profileId }));
        //     newRequest = false;
        // }

        // if (source == 'projects' && service_request) {
        //     dispatch(actions_profiles.subProfile(params.handle, service_request.profileId, 'CUSTOMER'));
        // }

        // return () => {
        //     let unsubscribe = customers.customer_unsubscribe;
        //     if (typeof unsubscribe === 'function') unsubscribe();
        // };
    }, [service_request]);

    // Subscribe by profileId for Customer Dashboard, requestId for Service Request
    useEffect(() => {
        // dispatch(actions_quotes.subQuotesByField(params.handle, 'serviceRequestId', params.request_id));

        // return () => {
        //     dispatch(actions_quotes.clearQuotes());
        //     let unsubscribe = quotes.quotes_unsubscribe;
        //     if (typeof unsubscribe === 'function') unsubscribe();
        // };
    }, [params.request_id, params.customer_id]);

    // Sub to Accepted Quote
    useEffect(() => {
        // dispatch(actions_quotes.subAcceptedQuoteByServiceRequestId(params.handle, params.request_id));

        // return () => {
        //     let unsubscribe = quotes.quote_approved_unsubscribe;
        //     if (typeof unsubscribe === 'function') unsubscribe();
        // };
    }, [params.request_id]);

    // Once customer is loaded, get the address for the map
    useEffect(() => {
        let address = _.find(customers?.customer?.addresses, { addressTypeId: "2" });
        if (!address) address = customers?.customer?.addresses[0];
        let mapAddress = (address) ? `${address.addressLine1} ${address.addressLine2} ${address.city} ${address.state} ${address.zip}` : null;
        if (mapAddress) setState(prev => ({ ...prev, mapAddress }));
    }, [customers?.customer?.id]);

    /* Actions/Handlers ------------------------------------------------------------------------------------------------------------------------------------*/

    const openForm = () => {
        let location = toolbox.modifyPath(props.location.pathname, 4, 'request-form/' + params.request_id);
        props.history.push({ pathname: location });
    };
	const openActivityLog = () => {
		let location = toolbox.modifyPath(pathname, 6, 'activity-log-service'); 
		history.push({ pathname: location });
	}
    const handleGenerateInvoice = () => {
        let newLocation = toolbox.modifyPath(props.location.pathname, 4, 'invoice-form/0');
        props.history.push({ pathname: newLocation });
    };
    const openReceivePayment = () => {
        let newLocation = toolbox.modifyPath(props.location.pathname, 4, 'payment-form/0');
        props.history.push({ pathname: newLocation });
    };
    const handleChangeStatus = (statusId, reason) => {
        switch (statusId) {
            case SERVICE_REQUEST_TERMS.SERVICE_CALL.id: {

                // Prevent switch if Quote has been approved
                if (approved_quote) {
                    window.toastr.error('Cannot change status to Service Call when the Quote has been approved', 'Error Switching Status');
                    break;
                }
                // Prevent switch if more than one Quote option exists
                if (quotes?.quotes?.[0]?.options.length > 1) {
                    window.toastr.error('Estimate with multiple options cannot be converted to Service Call', 'Error Switching Status');
                    break;
                }

                const workOrder = workOrders?.work_orders?.[0];
                const quote = quotes?.quotes?.[0];

                dispatch(actions_service_request.convertToServiceCall(params.handle, service_request, workOrder, quote, () => {
                    dispatch(actions_settings.toggleStatusModal());
                    let newLocation = toolbox.modifyPath(props.location.pathname, 9, 'work_orders/' + workOrder.id + '/items');
                    props.history.push({ pathname: newLocation });
                }));
                break;
            }
            case SERVICE_REQUEST_TERMS.ESTIMATE.id: {

                // Prevent switch if Work Order has been completed or invoiced
                const completed = workOrders?.work_orders?.find((workOrder) => (workOrder.statusId == WORKORDERS.COMPLETE.id || workOrder.statusId == WORKORDERS.INVOICED.id));
                if (completed) {
                    window.toastr.error('Cannot change status to estimate if Work Order has been completed or invoiced', 'Error Switching Status', 'Error Switching Status');
                    break;
                }
                dispatch(actions_service_request.convertToEstimate(params.handle, service_request, () => {
                    dispatch(actions_quotes.createQuoteFromWorkOrders(params.handle, params.request_id, (quoteId) => {
                        dispatch(actions_settings.toggleStatusModal());
                        let newLocation = toolbox.modifyPath(props.location.pathname, 9, 'quotes/' + quoteId + '/items');
                        props.history.push({ pathname: newLocation });
                    }));
                }));
                break;
            }
        }
    };
    const handleStatus = (statusTable, statusId) => {
        dispatch(actions_settings.toggleStatusModal(statusTable, statusId, service_request.id, handleChangeStatus));
    };
    const confirmGenerateQuote = () => {
        ModalAlert({
            title: 'Generate a Quote?',
            text: "This will generate a Quote from the open Work Orders on this Service Request",
            icon: 'question',
            show_cancel: true,
            confirm_color: '#8FBC8B',
            confirm_text: 'Yes, Create Quote',
            callback_success: () => {
                setProcessing(true);
                dispatch(actions_quotes.createQuoteFromWorkOrders(params.handle, params.request_id, (quoteId) => {
                    setProcessing(false);
                    let location = toolbox.modifyPath(props.location.pathname, 4, 'form/0/0/service_requests/' + params.request_id + '/quotes/' + quoteId + '/items');
                    props.history.push({ pathname: location });
                }));
            },
        });
    };
    const confirmGenerateChange = () => {
        ModalAlert({
            title: 'Generate a Change Order?',
            text: "This will generate a new Quote highlighting changes from the currently Accepted Quote",
            icon: 'question',
            show_cancel: true,
            confirm_color: '#8FBC8B',
            confirm_text: 'Yes, Create Change Order',
            callback_success: () => {
                setProcessing(true);
                dispatch(actions_quotes.createChangeOrder(params.handle, params.request_id, (quoteId) => {
                    setProcessing(false);
                    let location = toolbox.modifyPath(props.location.pathname, 4, 'form/0/0/service_requests/' + params.request_id + '/quotes/' + quoteId + '/items');
                    props.history.push({ pathname: location });
                }));
            },
        });
    };
    const confirmTimeMaterials = () => { };
    const openHelpPanel = (url) => {
        // if (window.flutter) {
        //     window.flutter.callHandler('helpPanel', url); 
        // } else {
        //     dispatch(actions_admin.showHelp(true, url));
        // }
	}
	const toggleLightbox = () => {
		let imagesAlt = service_request.imagesAlt?.map((imageUrl, index) => {
			return ({ src: imageSize(imageUrl, 'lg') });
		});
		if (!admin.lightbox_show) dispatch(actions_admin.lightboxSources([
			{ src: imageSize(service_request.imageUrl, 'lg') },
			...(service_request.imagesAlt) ? imagesAlt : []
		]));
		dispatch(actions_admin.lightboxShow(!admin.lightbox_show));
	}

    /* Constants ------------------------------------------------------------------------------------------------------------------------------------*/

    var contactArray = []; // start will all contacts, remove when assigned an address
    if (customer?.contacts) customer.contacts.forEach((contact) => {
        contactArray.push(contact);
    });

    var quoteDisabled = (!user_permissions?.QUOTES_CREATE)
        ? true
        : (!!approved_quote);
    var changeDisabled = (!approved_quote);
    var invoiceDisabled = !workOrders?.work_orders?.find((workOrder) => (workOrder.statusId == WORKORDERS.COMPLETE.id));
    var paymentDisabled = !user_permissions?.PAYMENTS_RECEIVE;

    const moreOptions =
		<div className="text-right">
            <SplitButton variant={'default'}
                title={'Edit Service Request'}
                className={'mr-0' + ((numPanels(1)) ? 'mr-n4':'')}
                onClick={ openForm }
                disabled={!user_permissions.SERVICE_REQUESTS_CREATE && !user_permissions.SERVICE_REQUESTS_EDIT}
            >
                <Dropdown.Item className="py-2" onClick={ openActivityLog } disabled={!user_permissions?.ACTIVITY_LOGS_VIEW}>
                    <i className="fas fa-history pr-1"></i> Activity Log
                </Dropdown.Item>
            </SplitButton>
        </div>

    /* Render ------------------------------------------------------------------------------------------------------------------------------------*/

    return (

		<div className="row mb-2">
            {service_request
                ? <>
                    {segments < 13 && !window.flutter &&
                        <CloseX onClick={() => { props.history.goBack(); }} style={{ right: '22px', marginTop: '22px' }} color={ '#eeeeee' } />
                    }
                    <div className="col-12">
                        <h1 className="heading-service-request">Service Request #{service_request?.customId}</h1>
                    </div>

                    <div id="request-avatar" className={ (numPanels(1)) ? 'col-11':'col-12' }>
						<div className="profile-image" onClick={ (service_request.imageUrl) ? toggleLightbox : null }>
							{ !!service_request.imagesAlt?.length &&
								<Avatar
									style={{ position: 'absolute', left: '70px', top: '55px' }}
									color="white"
									bgColor="black"
									size={35}
									fontSize={ '10px' }
									name={`+${service_request.imagesAlt.length}`}
									initials={ false }
									border="3px solid white"
									role="button"
								/>
							}
							<Avatar className="mx-auto"
								color="white"
								bgColor="silver"
								size={90}
								name={service_request.customId}
                                initials={false}
								image={imageSize(service_request.imageUrl, 'sm')}
								border="4px solid white"
								role={ (service_request.imageUrl) ? "button" : null }
							/>
						</div>
                        <div className="profile-info pt-1">
                            <h1 className="line-clamp-2 mr-5">{service_request.description}</h1>
                        </div>
					</div>

                    <div className="col-12">
                        <div id="details" className="mb-2">
                            { service_request.deleted && <button type="button" className="btn btn-outline btn-danger float-left ml-3 mt-1">ARCHIVED</button> }
                            { moreOptions }
                            <div id="service-request-details" className="mt-3 mb-2">
                                <DashboardDetail field={ 'Request Date' } value={ toolbox.dateOrDash(service_request.requestDate, 'MMM DD, YYYY h:mm A') } />
                                <DashboardDetail field={ 'Summary' } value={ service_request.summary } />
                                <DashboardDetail field={ 'Description' } value={ service_request.description } />
                                <DashboardDetail field={ 'Terms' } value={<>
                                    <StatusSelect
                                        selectedStatusId={service_request.serviceRequestTermsId}
                                        statusTypes={Object.values(SERVICE_REQUEST_TERMS)}
                                        statusTable={'SERVICE_REQUEST_TERMS'}
                                        onClick={handleStatus}
                                    />
                                    <i className="fas fa-info-circle fa-lg text-info ml-2" onClick={openHelpPanel.bind(this, '/servicing-assets.md/service-requests#urgency')} role="button"></i>
                                </>} />
                                <DashboardDetail field={ 'Status' } value={<>
                                    <StatusSelect
                                        selectedStatusId={service_request.statusId}
                                        statusTypes={Object.values(REQUESTS)}
                                        statusTable={'REQUESTS'}
                                        static={true}
                                    /> &nbsp;
                                    <i className="fas fa-info-circle fa-lg text-info ml-2" onClick={openHelpPanel.bind(this, '/servicing-assets.md/service-requests#urgency')} role="button"></i>
                                </>} />
                                <DashboardDetail field={ ' ' } value={<>
                                    <Dropdown className="d-inline mr-2 mb-2">
                                        <Dropdown.Toggle variant="primary" size="sm" style={{ fontSize: '14px' }}>
                                            Service Request Actions &nbsp;
                                            {processing &&
                                                <span className="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true"></span>
                                            }
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item eventKey="1" onClick={confirmGenerateQuote} disabled={quoteDisabled}>Generate Quote</Dropdown.Item>
                                            <Dropdown.Item eventKey="2" onClick={confirmGenerateChange} disabled={changeDisabled}>Change Order</Dropdown.Item>
                                            <Dropdown.Item eventKey="3" onClick={confirmTimeMaterials} disabled>Time & Materials</Dropdown.Item>
                                            <Dropdown.Item eventKey="4" onClick={handleGenerateInvoice} disabled={invoiceDisabled}>Generate Invoice</Dropdown.Item>
                                            <Dropdown.Item eventKey="5" onClick={openReceivePayment} disabled={paymentDisabled}>Receive Payment</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </>} />
                            </div>
                            {/* <div className='col-4 pl-0'>
                                {users.user_permissions?.SERVICE_REQUESTS_EDIT &&
                                    <button className="btn btn-default btn-sm" type="button" onClick={openForm}>Edit Service Request</button>
                                }
                                <h3 className='ml-2 mt-2 text-muted'>
                                    <i class="fa fa-car" aria-hidden="true"></i> &nbsp; {toolbox.minutesToHoursString(durationTotals.enroute)}
                                </h3>
                                <h3 className='ml-2 mt-2 text-muted'>
                                    <i class="fa fa-clock" aria-hidden="true"></i> &nbsp; {toolbox.minutesToHoursString(durationTotals.onsite)}
                                </h3>
                                {approved_quote?.signatureUrl &&
                                    <img src={approved_quote.signatureUrl} className="ml-3 mt-3" height="45" style={{ position: 'absolute', marginTop: '-10px' }} />
                                }
                            </div> */}
                        </div>
                        <div className="row">
                            <h2 className="ml-3 mb-3"><div>{serviceAddress?.addressLine1}</div><div>{serviceAddress?.addressLine2}</div></h2>
                            <div className="mapbox-container mt-2">
                                {state.mapAddress &&
                                    <Mapbox map_address={state.mapAddress} aspect_ratio={3} />
                                }
                            </div>
                        </div>
                    </div>

                </>
                : <Spinner center />
            }
        </div>
    );
};

export default ServiceRequestDashboard;
