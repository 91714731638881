import { combineReducers } from 'redux';
import { reducer as toastrReducer } from 'react-redux-toastr';

// Reducers
import AdminReducer from 'reducers/admin_reducer';
import ArchiveReducer from './archive-reducer';
import AttachmentsReducer from './attachments-reducer';
import CallLogReducer from './call-log-reducer';
import ChatReducer from 'reducers/chat-reducer';
import CompanyReducer from './company-reducer';
import ContactsReducer from './contacts-reducer';
import CustomersReducer from './customers-reducer';
import EmployeesReducer from 'reducers/employees-reducer-port';
import GraphsReducer from 'reducers/graphs-reducer';
import InvoicesReducer from './invoices-reducer';
import QuickbooksReducer from 'reducers/quickbooks-reducer';
import QuotesReducer from './quotes-reducer';
import ServiceItemsReducer from './service-items-reducer';
import ServicesReducer from './services-reducer';
import SettingsReducer from './settings-reducer';
import UsersReducer from './users-reducer';
import WorkOrdersReducer from './work-orders-reducer';

// Combine Reducers
var reducers = combineReducers({
	admin: AdminReducer,
	archive: ArchiveReducer,
	attachments: AttachmentsReducer,
	callLog: CallLogReducer,
	chat: ChatReducer,
	company: CompanyReducer,
	contacts: ContactsReducer,
	customers: CustomersReducer,
	employees: EmployeesReducer,
	graphs: GraphsReducer,
	invoices: InvoicesReducer,
	quickbooks: QuickbooksReducer,
	quotes: QuotesReducer,
	serviceItems: ServiceItemsReducer,
	services: ServicesReducer,
	settings: SettingsReducer,
	toastr: toastrReducer,
	users: UsersReducer,
	workOrders: WorkOrdersReducer,
});

export default reducers;