import React, { useEffect, useState, } from 'react';
import { useSelector } from 'react-redux';
import StatWidget from 'components/common/stat-widgets/stat-widget';

var _ = require('lodash');

export default function StatWidgets(props) {

    /* Hooks --------------------------*/
    const graphs = useSelector((store) => store.graphs);
    const graphInvoices = graphs.graph_invoices;
    const graphQuotes = graphs.graph_quotes;
    const [quotes, setQuotes] = useState([]);
    const [invoices, setInvoices] = useState([]);

    /* Effects --------------------------*/

    useEffect(() => {
        graphQuotes && setQuotes(graphQuotes?.filter((o) => o.date.toDate() >= props.startDate && o.date.toDate() <= props.endDate));
        graphInvoices && setInvoices(graphInvoices?.filter((o) => o.date.toDate() >= props.startDate && o.date.toDate() <= props.endDate));
    }, [props.startDate, props?.endDate, graphs]);

    /* Constants --------------------------*/

    var widgets = (props?.type == 'invoices') ? [
        { position: 1, widget: 'invoiced' },
        { position: 2, widget: 'collected' },
        { position: 3, widget: 'outstanding' },
        { position: 4, widget: 'percentageInvoiced' },
    ] : [
        { position: 1, widget: 'quoted' },
        { position: 2, widget: 'approved' },
        { position: 3, widget: 'unapproved' },
        { position: 4, widget: 'percentageQuoted' },
    ];
    var sorted_widgets = _.orderBy(widgets, (o) => { return o.position; });
    var widgets = sorted_widgets.map((item, index) => {
        // Invoices Widgets
        if (item.widget == 'invoiced') return <StatWidget type={item.widget} key={index} color_number={1} fetching={false} icon={'fa-dollar-sign'} text={'Invoiced'} invoices={invoices} prefix={'$'} />;
        if (item.widget == 'collected') return <StatWidget type={item.widget} key={index} color_number={3} fetching={false} icon={'fa-sign-in-alt'} text={'Collected'} invoices={invoices} prefix={'$'} />;
        if (item.widget == 'outstanding') return <StatWidget type={item.widget} key={index} color_number={4} fetching={false} icon={'fa-sign-in-alt'} text={'Outstanding'} invoices={invoices} prefix={'$'} />;
        if (item.widget == 'percentageInvoiced') return <StatWidget type={item.widget} key={index} color_number={7} fetching={false} icon={'fa-percentage'} text={''} invoices={invoices} suffix={'%'} />;
        //Quotes Widgets
        if (item.widget == 'quoted') return <StatWidget type={item.widget} key={index} color_number={1} fetching={false} icon={'fa-dollar-sign'} text={'Quoted'} quotes={quotes} prefix={'$'} />;
        if (item.widget == 'approved') return <StatWidget type={item.widget} key={index} color_number={3} fetching={false} icon={'fa-sign-in-alt'} text={'Approved'} quotes={quotes} prefix={'$'} />;
        if (item.widget == 'unapproved') return <StatWidget type={item.widget} key={index} color_number={4} fetching={false} icon={'fa-sign-in-alt'} text={'Unapproved'} quotes={quotes} prefix={'$'} />;
        if (item.widget == 'percentageQuoted') return <StatWidget type={item.widget} key={index} color_number={7} fetching={false} icon={'fa-percentage'} text={''} quotes={quotes} suffix={'%'} />;

        return null;
    });

    return (
        <div className="my-3">
            <div className="row">
                {widgets}
            </div>
        </div>
    );
};
