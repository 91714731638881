export const ADDRESSES = {
    BILLING: { id: '1', name: 'Billing', source: ['customers'] },
    SERVICE: { id: '2', name: 'Service', source: ['customers'] },
    DELIVERY: { id: '3', name: 'Delivery', source: ['customers'] },
    PHYSICAL: { id: '4', name: 'Physical', source: ['customers', 'employees'] },
    MAILING: { id: '5', name: 'Mailing', source: ['customers', 'employees'] },
};
export const CONTACTS = {
    BILLING: { id: '1', name: 'Billing' },
    TENANT: { id: '2', name: 'Tenant' },
    MANAGER: { id: '3', name: 'Manager' },
    OWNER: { id: '4', name: 'Owner' },
};
export const EMPLOYEE_ADDRESSES = {
    MAILING: { id: '1', name: 'Mailing' },
    PHYSICAL: { id: '2', name: 'Physical' },
    LOCATION: { id: '3', name: 'Location' },
};
export const ROLES = {
    OWNER: { id: '99', name: 'Owner', order: 6 },
    ADMINISTRATOR: { id: '0', name: 'Administrator', order: 5 },
    DISPATCHER: { id: '1', name: 'Dispatcher', order: 1 },
    TECHNICIAN: { id: '2', name: 'Technician', order: 2 },
    ESTIMATOR: { id: '3', name: 'Estimator', order: 3 },
    ACCOUNTING: { id: '4', name: 'Accounting', order: 4 },
};
export const REQUESTS = {
    UNASSIGNED: { id: '0', name: 'Unassigned', color: '#b3b3b3', description: 'One or more Work Order associated with this Service Request has not yet been assigned to a technician. This can be done by editing the Work Order, or dragging it to Technician\'s Schedule from the Schedule view.' },
    ASSIGNED: { id: '1', name: 'Assigned', color: '#16a5a5', description: 'All the Work Orders associated with this Service Request have been assigned to a technician.' },
    INCOMPLETE: { id: '2', name: 'Incomplete', color: '#e8e475', description: 'One or more Work Order associated with this Service Request has been marked as "Incomplete" for a specified reason, usually requiring followup by the Office or another Technician.' },
    COMPLETE: { id: '3', name: 'Complete', color: '#a4dd00', description: 'All the Work Orders for this Service Request have been marked as "Complete" by the Technician.' },
    INVOICED: { id: '4', name: 'Invoiced', color: '#68bc00', description: 'The Customer has been invoiced for all Work Orders associated with this Service Request.' },
    CLOSED: { id: '5', name: 'Closed', color: '#333333', description: 'This Work Orders associated with this Service Request have been Completed, Invoiced, and Paid, or Incomplete and Closed due to Customer Request.  There are no pending actions.' },
};
export const WORKORDERS = {
    UNASSIGNED: { id: '0', name: 'Unassigned', color: '#b3b3b3', description: 'An "Unassigned" Work Order has been created but not assigned to a technician.  This can be done by editing the Work Order, or dragging it to Technician\'s Schedule from the Schedule view.', change: 'Changing the status to "Unassigned" will remove the current technician from the Work Order and allow another Technician to be assigned.', caution: true, possible: true },
    ASSIGNED: { id: '1', name: 'Assigned', color: '#16a5a5', description: '"Assigned" means that the Work Order has been assigned to a technician.', change: 'You cannot change the status to "Assigned" from here.  Please edit the Work Order and assign a Technician.', caution: false, possible: false },
    ENROUTE: { id: '2', name: 'Enroute', color: '#679cca', description: 'A Work Order marked as "Enroute" indicates that the Technician assigned to this Work Order has "Checked-in" and is currently "Enroute" to the Service Request location.', change: 'Check-in and automatic time tracking is only available on the App.', caution: false, possible: false },
    ONSITE: { id: '3', name: 'Onsite', color: '#937eca', description: 'A Work Order marked as "Onsite" indicates that the Technician assigned to this Work Order has "Checked-in" and is currently "Onsite" at the Service Request location.', change: 'Check-in and automatic time tracking is only available on the App.', caution: false, possible: false },
    INCOMPLETE: { id: '4', name: 'Incomplete', color: '#e8e475', description: 'An "Incomplete" Work Order has been marked as "Incomplete" for a specified reason usually requiring followup by the Office or another Technician', updatable: true },
    COMPLETE: { id: '5', name: 'Complete', color: '#a4dd00', description: 'A Work Order marked as "Complete" has been updated with Line Items for materials and labor and is ready for Invoicing.', change: 'Changing the status to "Complete" enables the generation of an Invoice for this Work Order. Be sure all material and line items have been completed. ', caution: true, possible: true },
    INVOICED: { id: '6', name: 'Invoiced', color: '#68bc00', description: 'The Customer has been invoiced for this Work Order.', change: 'You cannot change the status to "Invoiced" from here.  Please generate an invoice from the Service Request Panel.', caution: false, possible: false },
    CLOSED: { id: '7', name: 'Closed', color: '#333333', description: 'A "Closed" work Order has been Completed, Invoiced, and Paid, or Incomplete and Closed due to Customer Request.  There are no pending actions.', change: 'Changing the status to "Closed" will remove the Work Order from active status and expect no further action.', caution: true, possible: true },
};
export const INVOICES = {
    DRAFT: { id: '1', name: 'Draft', color: '#cccccc' },
    PENDING: { id: '2', name: 'Pending', color: '#e8e475' },
    PAID: { id: '3', name: 'Paid', color: '#68bc00' },
};
export const PAYMENTS = {
    PENDING: { id: '1', name: 'Pending', color: '#e8e475' },
    FAILURE: { id: '2', name: 'Failure', color: '#d33115' },
    SUCCESS: { id: '3', name: 'Success', color: '#68bc00' },
    VOID: { id: '4', name: 'Void', color: '#000000' },
};
export const SCHEDULE_EVENT_TYPE = {
    RESERVED_TIMELINE: { id: '0', name: 'Reserved Timeline' },
    WORKORDER: { id: '1', name: 'Work Order' },
    REQUESTED_TIME_OFF: { id: '2', name: 'Requested Time Off' },
    APPROVED_TIME_OFF: { id: '3', name: 'Approved Time Off' },
    INTERNAL_WORKORDER: { id: '4', name: 'Internal Work Order' },
};
export const CALL_LOG = {
    COMPLETE: { id: '1', name: 'Complete' },
    INCOMPLETE: { id: '2', name: 'Incomplete' },
    CANCELLED: { id: '3', name: 'Cancelled' },
    TRANSFERRED: { id: '4', name: 'Transferred' },
};
export const CUSTOMERS = {
    RESIDENTIAL: { id: '1', name: 'Residential' },
    COMMERCIAL: { id: '2', name: 'Commercial' },
    PROPERTY_MANAGEMENT: { id: '3', name: 'Property Management' },
};
export const STATUS_TABLES = {
    REQUESTS: { name: 'Service Requests', singular: 'Service Request' },
    WORKORDERS: { name: 'Work Orders', singular: 'Work Order' },
    QUOTES: { name: 'Quotes', singular: 'Quote' },
    SERVICE_REQUEST_TERMS: { name: 'Service Request Terms', singular: 'Service Request Terms' },
};
export const QUOTES = {
    DRAFT: { id: '0', name: 'Draft', color: '#cccccc', description: 'A "Draft" Quote has been created but has not yet been printed or emailed to the customer for review.  It may be overwritten with a new draft.', change: 'Once a Quote has been presented to the Customer it cannot be set again to "Draft".', caution: false, possible: false },
    PENDING: { id: '1', name: 'Pending', color: '#e8e475', description: 'A Quote with the status "Pending" is awaiting Customer review.  When the Customer responds, it will change to "Accepted" or "Rejected".', change: 'You cannot change the status to "Pending" from here.  Please print or email the Quote to the Customer.', caution: false, possible: false },
    REJECTED: { id: '2', name: 'Rejected', color: '#d33115', description: 'A "Rejected" Quote has been actively rejected by the Customer and no further action can be made.', change: 'Changing the status to "Rejected" indicates that the Customer is not proceeding with the project unless a new Quote is generated and accepted.', caution: true, possible: true },
    CHANGES_REQUESTED: { id: '3', name: 'Changes Requested', color: '#FFA500', description: 'This status means that a Quote has been reviewed by the Customer and the Customer has requested changes.', change: '"Changes Requested" are submitted by the customer after reviewing a Quote.', caution: true, possible: false },
    ACCEPTED: { id: '4', name: 'Accepted', color: '#68bc00', description: 'An "Accepted" Quote is approved by the Customer, changes the status of the Service Request to "Project", and serves as the basis for the completion of the Project', change: 'Changing the status to "Accepted" from here authorizes the Project to continue, but without a Customer signature.', caution: false, possible: true },
    CLOSED: { id: '5', name: 'Closed', color: '#333333', description: 'A Quote with the status "Closed" has been replaced by another Quote,or accepted by the Customer and that project has been subsequently completed or closed as Incomplete.', change: 'An "Accepted" Quote will automatically be set to "Closed" when the associated Service Request is Closed.', caution: false, possible: false },
};
export const SERVICE_REQUEST_TERMS = {
    SERVICE_CALL: { id: '1', name: 'Service Call', code: 'SC', color: '#cccccc', description: 'A Service Call is a Service Request and an associated Work Order that is Completed and Invoiced at the of the initial visit', change: 'Changing the terms to "Service Call" from here will delete the Quote and convert the Service Request to a Service Call.', possible: true },
    ESTIMATE: { id: '2', name: 'Estimate', code: 'EST', color: 'lightblue', description: 'An Estimate is a Service Request and an associated Work Order with the purpose of inspecting the issues described in the Service Request and providing a quote(s) for the customer.', change: 'Changing the terms to "Estimate" from here will generate the start a Quote for approval by the Customer.', possible: true },
    PROJECT_QUOTE: { id: '3', name: 'Project Quote', code: 'PR', color: 'steelblue', description: 'When an estimate is created, or when a Service Request expands beyond the scope of a single Service Call, a Project Quote is created and approved by the Customer.  This quote may be divided among several technicians over several time periods.' },
    TIME_MATERIAL: { id: '4', name: 'Time & Materials', code: 'TM', color: '#000000', description: 'When a project Quote cannot be determined due to various unknowns, the Customer may approve "Time & Materials" in which the Work Orders reflect actual time and materials spent on the job.' },
    PENDING: { id: '5', name: 'Pending Approval', code: 'PA', color: '#e8e475', description: 'A Service Request with "Pending" terms is waiting for customer approval on a requested change of Terms.' },
    CALLBACK: { id: '6', name: 'Callback', code: 'CB', color: 'red', description: 'A Callback is a Service Request and an associated Work Order that was previously completed and is showing signs of being defective or improperly serviced.  It is inticipated to be completed at no additional cost.' },
};
export const SERVICE_ITEMS = {
    LABOR: { id: '1', position: 1, name: 'Labor', type: 'Service' },
    PARTS_SUPPLIES: { id: '2', position: 2, name: 'Parts & Supplies', type: 'Inventory' },
    TRAVEL: { id: '3', position: 3, name: 'Travel', type: 'NonInventory' },
};
export const CHECKIN = {
    CHECKOUT: { id: '1', name: 'CheckOut', color: '#b3b3b3' },
    ENROUTE: { id: '2', name: 'Enroute', color: '#679cca' }, // id & color matches WORKORDERS
    ONSITE: { id: '3', name: 'Onsite', color: '#937eca' }, // id & color matches WORKORDERS
    INPROGRESS: { id: '4', name: 'In Progress', color: '#FF8C00' }, // id & color matches WORKORDERS
};
export const UNITS = {
    EACH: { id: '1', name: 'Each' },
    OUNCES: { id: '2', name: 'Ounces' },
    POUNDS: { id: '3', name: 'Pounds' },
    TONS: { id: '4', name: 'Tons' },
    CORDS: { id: '5', name: 'Cords' },
};
export const DEFAULT_SCHEDULE = {
    1: { end: '17:00', isWorkDay: false, start: '07:00' },
    2: { end: '17:00', isWorkDay: true, start: '07:00' },
    3: { end: '17:00', isWorkDay: true, start: '07:00' },
    4: { end: '17:00', isWorkDay: true, start: '07:00' },
    5: { end: '17:00', isWorkDay: true, start: '07:00' },
    6: { end: '17:00', isWorkDay: true, start: '07:00' },
    7: { end: '17:00', isWorkDay: false, start: '07:00' }
};
export const PERMISSIONS_ORDER = {
    VIEW: 0,
    EDIT: 1,
    CREATE: 2,
    DELETE: 3,
};
export const PERMISSIONS = {
    ACTIVITY_LOGS_VIEW: { name: "Activity Logs - View", roles: ["99","0","2","3","4","1"] },
    ARCHIVES_DELETE: { name: "Archives - Delete", roles: ["99"] },
    ARCHIVES_RESTORE: { name: "Archives - Restore", roles: ["99"] },
    ARCHIVES_VIEW: { name: "Archives - View", roles: ["99"] },
    ATTACHMENTS_CREATE: { name: "Attachments - Create", roles: ["99","0","2","3"] },
    ATTACHMENTS_DELETE: { name: "Attachments - Delete", roles: ["99","0","2","3"] },
    ATTACHMENT_VIEW: { name: "Attachments - View", roles: ["99","0","1","2","3","4"] },
    CALL_CENTER: { name: "Call Center", roles: ["1","99","0"] },
    CUSTOMERS_CREATE: { name: "Customers - Create", roles: ["99","0","1","3","4"] },
    CUSTOMERS_DELETE: { name: "Customers - Delete", roles: ["99","0"] },
    CUSTOMERS_EDIT: { name: "Customers - Edit", roles: ["1","99","0","2","3","4"] },
    CUSTOMERS_VIEW: { name: "Customers - View", roles: ["0","1","99","2","3","4"] },
    DASHBOARD_OVERVIEW: { name: "Dashboard - Financial Overview", roles: ["99"] },
    DASHBOARD_PROJECTS: { name: "Dashboard - Projects", roles: ["99","0","3"] },
    DASHBOARD_SERVICE_MAP: { name: "Dashboard - Field Service Map", roles: ["99","0","3"] },
    EMPLOYEES_CREATE: { name: "Employees - Create", roles: ["99","0"] },
    EMPLOYEES_DELETE: { name: "Employees - Delete", roles: ["99","0"] },
    EMPLOYEES_EDIT: { name: "Employees - Edit", roles: ["99","0"] },
    EMPLOYEES_VIEW: { name: "Employees - View", roles: ["0","99"] },
    INVOICES_EDIT: { name: "Invoices - Create/Edit", roles: ["99","0","4","2","3"] },
    INVOICES_VIEW: { name: "Invoices - View", roles: ["99","0","4","2","3"] },
    MESSAGING: { name: "Messaging", roles: ["99","0","1","2","3","4"] },
    PAYMENTS_DELETE: { name: "Payments - Delete", roles: ["99","0","4"] },
    PAYMENTS_EDIT: { name: "Payments - Edit", roles: ["99","0","4"] },
    PAYMENTS_RECEIVE: { name: "Payments - Receive", roles: ["99","0","4","2","3","1"] },
    PAYMENTS_VIEW: { name: "Payments - View", roles: ["2","99","0","4","3","1"] },
    QUOTES_CREATE: { name: "Quotes - Create", roles: ["99","0","3"] },
    QUOTES_EDIT: { name: "Quotes - Edit", roles: ["99","0","3"] },
    QUOTES_VIEW: { name: "Quotes - View", roles: ["99","0","1","2","3","4"], },
    SCHEDULE_EDIT: { name: "Schedule - Edit", roles: ["2","99","0","1","3"] },
    SCHEDULE_PERSONAL_TIME_APPROVE: { name: "Schedule - Approve/Deny Personal Time", roles: ["99","0"] },
    SCHEDULE_VIEW: { name: "Schedule - View", roles: ["99","2","0","1","3","4"] },
    SERVICE_ITEMS_CREATE: { name: "Service Items - Create", roles: ["2","99","0","3"] },
    SERVICE_ITEMS_DELETE: { name: "Service Items - Delete", roles: ["99","0"] },
    SERVICE_ITEMS_EDIT: { name: "Service Items - Edit", roles: ["2","99","0","3"] },
    SERVICE_ITEMS_VIEW: { name: "Service Items - View", roles: ["99","2","0","1","3","4"] },
    SERVICE_REQUESTS_CREATE: { name: "Service Requests - Create", roles: ["2","99","0","1"] },
    SERVICE_REQUESTS_DELETE: { name: "Service Requests - Delete", roles: ["99","0"] },
    SERVICE_REQUESTS_EDIT: { name: "Service Requests - Edit", roles: ["99","2","0","1","3"] },
    SERVICE_REQUESTS_TERMS: { name: "Service Requests - Change Terms", roles: ["99","0","3","2"] },
    SERVICE_REQUESTS_VIEW: { name: "Service Requests - View", roles: ["99","2","0","1","3","4"] },
    SETTINGS_ACCOUNTING: { name: "Settings - Accounting", roles: ["99","0", "4"] },
    SETTINGS_COMPANY: { name: "Settings - Company", roles: ["99"] },
    SETTINGS_DEPARTMENTS: { name: "Settings - Departments", roles: ["99"] },
    SETTINGS_JOB_CATEGORIES: { name: "Settings - Job Categories", roles: ["99"] },
    SETTINGS_QUICKBOOKS: { name: "Settings - QuickBooks", roles: ["99","0", "4"] },
    SETTINGS_SERVICE_ITEMS: { name: "Settings - Service Items", roles: ["2","99","0","3"] },
    WORK_ORDERS_CREATE: { name: "Work Orders - Create", roles: ["99","0","1","2","3"] },
    WORK_ORDERS_DELETE: { name: "Work Orders - Delete", roles: ["2","99","0"] },
    WORK_ORDERS_EDIT: { name: "Work Orders - Edit", roles: ["2","99","0","1","3"] },
    WORK_ORDERS_VIEW: { name: "Work Orders - View", roles: ["99","0","1","4","2","3"] },
}

// ACTIVITY LOGS -----------------------------------------------------------------------------------

export const LOG = {
    ADDRESS: { key: 'ADDRESS', name: 'Address' },
    ASSET: { key: 'ASSET', name: 'Asset' },
    ASSET_TYPE: { key: 'ASSET_TYPE', name: 'Asset Type' },
    ASSET_CATEGORY: { key: 'ASSET_CATEGORY', name: 'Asset Category' },
    CALL_LOG: { key: 'CALL_LOG', name: 'Call Log' },
    CONTACT: { key: 'CONTACT', name: 'Contact' },
    COMPANY: { key: 'COMPANY', name: 'Company Settings' },
    CUSTOMER: { key: 'CUSTOMER', name: 'Customer' },
    DEPARTMENT: { key: 'DEPARTMENT', name: 'Department' },
    DEPARTMENT_TAG: { key: 'DEPARTMENT_TAG', name: 'Department Tag' },
    EMPLOYEE: { key: 'EMPLOYEE', name: 'Employee' },
    IMPORT_EXPORT: { key: 'IMPORT_EXPORT', name: 'Import/Export' },
    JOB_CATEGORY: { key: 'JOB_CATEGORY', name: 'Job Category' },
    MESSAGE: { key: 'MESSAGE', name: 'Message' },
    PARTS_CATEGORY: { key: 'PARTS_CATEGORY', name: 'Parts Category' },
    PURCHASE_ORDER: { key: 'PURCHASE_ORDER', name: 'Purchase Order' },
    QUOTE: { key: 'QUOTE', name: 'Quote' },
    SCHEDULE: { key: 'SCHEDULE', name: 'Schedule' },
    SERVICE_REQUEST: { key: 'SERVICE_REQUEST', name: 'Service Request' },
    SETTINGS: { key: 'SETTINGS', name: 'Settings' },
    STOCK_PART: { key: 'STOCK_PART', name: 'Stock Part' },
    USER: { key: 'USER', name: 'User' },
    VENDOR: { key: 'VENDOR', name: 'Vendor' },
    WORK_ORDER: { key: 'WORK_ORDER', name: 'Work Order' },
    WORK_ORDER_SCHEDULE: { key: 'WORK_ORDER_SCHEDULE', name: 'Work Order Schedule' },
};

export const ACTION = {
    VIEWED: { key: 'VIEWED', name: 'Viewed' },
    CREATED: { key: 'CREATED', name: 'Created' },
    MODIFIED: { key: 'MODIFIED', name: 'Modified' },
    DELETED: { key: 'DELETED', name: 'Deleted' },
    ARCHIVED: { key: 'ARCHIVED', name: 'Archived' },
    RESTORED: { key: 'RESTORED', name: 'Restored' },
    GENERATED: { key: 'GENERATED', name: 'Generated' },
    PRINTED: { key: 'PRINTED', name: 'Printed' },
    EMAILED: { key: 'EMAILED', name: 'Emailed' },
    MISC: { key: 'MISC', name: 'Misc' },
}

