import * as toolbox from '../components/common/toolbox';
import * as types from './action-types';
import { updateQuickbooks } from 'actions/quickbooks-actions';
import firebase from 'firebase/compat/app';
import { getDoc } from 'firebase/firestore';
import { ADDRESSES, CONTACTS, DEFAULT_SCHEDULE } from 'components/common/constants';
import { ModalAlert } from 'enspire-manager-framework';
import moment from 'moment';

const firestore = firebase.firestore();

/*-----------------------------------------------*/
/*  EMPLOYEES
/*-----------------------------------------------*/

export function subEmployees(handle) {

	return async dispatch => {

		var unsubscribe = firestore.collection(handle + '/profiles/profiles/').where('type', '==', 'employee').where('deleted', '==', false).onSnapshot(async (snapshot) => {

			dispatch({ type: types.EMPLOYEES + '_PENDING' });

			var employees = [];
			snapshot.forEach((doc) => {
				var employee = { ...doc.data(), id: doc.id };
				employee.contact = (employee.contacts && employee.contacts != {}) ? Object.values(employee.contacts)?.[0] : {};		
				employees.push(employee);
			});

			dispatch({ type: types.EMPLOYEES + '_FULFILLED', data: employees, unsubscribe });
		});
	};
}

/*-----------------------------------------------*/
/*  PROFILES
/*-----------------------------------------------*/

export function subProfile(handle, id, profile_type) {

	return dispatch => {

		dispatch({ type: types[profile_type] + '_PENDING' });

		var unsubscribe = firestore.collection(handle + '/profiles/profiles').doc(id).onSnapshot(async (doc) => {

			if (doc.exists) {
				var profile = { ...doc.data(), id: doc.id };
				var addresses = [];
				var contacts = [];
				var user = null;

				const snapshot1 = await firestore.collection(handle + '/addresses/addresses').where('profileId', '==', profile.id).get();
				snapshot1.forEach((doc) => {
					addresses.push({ ...doc.data(), id: doc.id });
				});

				const snapshot2 = await firestore.collection(handle + '/contacts/contacts').where('profileIds', 'array-contains', profile.id).where('deleted', '==', false).get();
				snapshot2.forEach((doc) => {
					contacts.push({ ...doc.data(), id: doc.id });
				});

				if (contacts[0]?.email) {
					const userDoc = await firestore.collection(handle + '/users/users').doc(contacts[0].email).get();
					user = userDoc.data();
				}

				profile = { ...profile, ...user };
				profile.addresses = addresses;
				
				if (profile_type == 'EMPLOYEE') {
					profile.contact = contacts[0];
				} else {
					profile.contacts = contacts;
				}
			}

			dispatch({ type: types[profile_type] + '_FULFILLED', data: profile, unsubscribe });
		});
	};
}

export function undoCustomersImport(handle, importId) {
	return async dispatch => {

		var batch = firestore.batch();

		// Get all Profile records with this importId
		const profilesRef = firestore.collection(handle + '/profiles/profiles').where('importId', '==', importId);
		const profilesSnapshot = await profilesRef.get();
		profilesSnapshot.forEach((doc) => {
			// Delete all Profile records with this importId
			batch.delete(firestore.collection(handle + '/profiles/profiles').doc(doc.id));
		});

		// Get all Address records with this importId
		const addressesRef = firestore.collection(handle + '/addresses/addresses').where('importId', '==', importId);
		const addressesSnapshot = await addressesRef.get();
		addressesSnapshot.forEach((doc) => {
			// Delete all Address records associated with this importId
			batch.delete(firestore.collection(handle + '/addresses/addresses').doc(doc.id));
		});

		// Get all Contact records with this importId
		const contactsRef = firestore.collection(handle + '/contacts/contacts').where('importId', '==', importId);
		const contactsSnapshot = await contactsRef.get();
		contactsSnapshot.forEach((doc) => {
			// Delete all Contact records associated with this importId
			batch.delete(firestore.collection(handle + '/contacts/contacts').doc(doc.id));
		});

		// Remove lastImport information from Quickbooks settings
		const quickbooksSettingsRef = firestore.collection(handle + '/settings/quickbooks').doc('quickbooks');
		batch.set(quickbooksSettingsRef, { lastImport: firebase.firestore.FieldValue.delete() }, { merge: true });

		batch.commit().then(() => {
			window.toastr.success('The import has been successfully undone', 'Import Undone!');
		}).catch((error) => {
			window.toastr.error('Error: ' + error, 'Import NOT Undone!');
		});

	};
}

export function mergeCustomers(options, customer, quickbooksSettings, callback) {

	return async dispatch => {
		const isDev = (process.env.REACT_APP_FIREBASE_ENV == 'development');
		if (isDev && quickbooksSettings?.useEmulator) firebase.functions().useEmulator("localhost", 5001); // Testing locally with emulator

		firebase.functions().httpsCallable('mergeCustomers')(options).then(() => {
			window.toastr.success(`${customer.DisplayName || customer.displayName} saved successfully`, 'Customer Saved!');
			if (typeof callback == 'function') callback();
		}).catch((error) => {
			window.toastr.error(error, 'Error Saving Customer!');
			if (typeof callback == 'function') callback();
		});
	};
}

export function importCustomers(handle, customers, index, importId, quickbooksSettings, callback, lastSelection, applyToAll) {

	if (!importId) importId = moment().format('YYYYMMDDHHmmssSSS');

	var customer = customers[index];

	return async dispatch => {

		if (index == 0) dispatch({ type: types.QUICKBOOKS_IMPORT + '_PENDING' });

		const existingRef = await firestore.collection(handle + '/profiles/profiles').where('type', '==', "customer").where('displayNameSearchable', '==', customer?.DisplayName.toLowerCase()).get();
		const existing = { ...existingRef.docs[0]?.data(), id: existingRef.docs[0]?.id };

		if (existing?.id) {
			if (applyToAll) {
				if (lastSelection == 'quickbooks') {
					dispatch(mergeCustomers({
						handle: handle,
						customerId: existing.id,
						priority: 'quickbooks',
						final: (index == customers.length - 1)
					}, existing, quickbooksSettings, () => {
						// Run next iteration
						if (index == customers.length - 1) {
							dispatch({ type: types.QUICKBOOKS_IMPORT + '_FULFILLED' });
						} else {
							dispatch(importCustomers(handle, customers, index + 1, importId, quickbooksSettings, callback, 'quickbooks', true));
						}
					}));
				} else if (lastSelection == 'mobiletrack') {
					dispatch(mergeCustomers({
						handle: handle,
						customerId: existing.id,
						priority: 'mobiletrack',
						final: (index == customers.length - 1)
					}, existing, quickbooksSettings, () => {
						// Run next iteration
						if (index == customers.length - 1) {
							dispatch({ type: types.QUICKBOOKS_IMPORT + '_FULFILLED' });
						} else {
							dispatch(importCustomers(handle, customers, index + 1, importId, quickbooksSettings, callback, 'mobiletrack', true));
						}
					}));
				} else {
					// Run next iteration
					if (index == customers.length - 1) {
						dispatch({ type: types.QUICKBOOKS_IMPORT + '_FULFILLED' });
					} else {
						dispatch(importCustomers(handle, customers, index + 1, importId, quickbooksSettings, callback, 'skip', true));
					}
				}
			} else {
				ModalAlert({
					title: `Existing Customer`,
					text: `"${customer.DisplayName}" will be merged with existing Customer. Which data should take priority?`,
					icon: 'warning',
					input: 'radio',
					value: (lastSelection) ? lastSelection : 'quickbooks',
					options: {
						'quickbooks': 'QuickBooks',
						'mobiletrack': 'MobileTrack',
						'skip': 'Skip Merge'
					},

					// Continue
					confirm_color: '#8FBC8B',
					confirm_text: 'Continue',
					callback_success: async (inputValue) => {
						if (inputValue == 'quickbooks') {
							dispatch(mergeCustomers({
								handle: handle,
								customerId: existing.id,
								priority: 'quickbooks',
								final: (index == customers.length - 1)
							}, existing, quickbooksSettings, () => {
								// Run next iteration
								if (index == customers.length - 1) {
									dispatch({ type: types.QUICKBOOKS_IMPORT + '_FULFILLED' });
								} else {
									dispatch(importCustomers(handle, customers, index + 1, importId, quickbooksSettings, callback, 'quickbooks'));
								}
							}));
						} else if (inputValue == 'mobiletrack') {
							dispatch(mergeCustomers({
								handle: handle,
								customerId: existing.id,
								priority: 'mobiletrack',
								final: (index == customers.length - 1)
							}, existing, quickbooksSettings, () => {
								// Run next iteration
								if (index == customers.length - 1) {
									dispatch({ type: types.QUICKBOOKS_IMPORT + '_FULFILLED' });
								} else {
									dispatch(importCustomers(handle, customers, index + 1, importId, quickbooksSettings, callback, 'mobiletrack'));
								}
							}));
						} else {
							// Run next iteration
							if (index == customers.length - 1) {
								dispatch({ type: types.QUICKBOOKS_IMPORT + '_FULFILLED' });
							} else {
								dispatch(importCustomers(handle, customers, index + 1, importId, quickbooksSettings, callback, 'skip'));
							}
						}
					},

					// Apply to All
					show_deny: (index != customers.length - 1),
					deny_color: '#4682B4',
					deny_text: 'Apply to All',
					callback_denied: (inputValue) => {
						if (inputValue == 'quickbooks') {
							dispatch(mergeCustomers({
								handle: handle,
								customerId: existing.id,
								priority: 'quickbooks',
								final: (index == customers.length - 1)
							}, existing, quickbooksSettings, () => {
								// Run next iteration
								if (index == customers.length - 1) {
									dispatch({ type: types.QUICKBOOKS_IMPORT + '_FULFILLED' });
								} else {
									dispatch(importCustomers(handle, customers, index + 1, importId, quickbooksSettings, callback, 'quickbooks', true));
								}
							}));
						} else if (inputValue == 'mobiletrack') {
							dispatch(mergeCustomers({
								handle: handle,
								customerId: existing.id,
								priority: 'mobiletrack',
								final: (index == customers.length - 1)
							}, existing, quickbooksSettings, () => {
								// Run next iteration
								if (index == customers.length - 1) {
									dispatch({ type: types.QUICKBOOKS_IMPORT + '_FULFILLED' });
								} else {
									dispatch(importCustomers(handle, customers, index + 1, importId, quickbooksSettings, callback, 'mobiletrack', true));
								}
							}));
						} else {
							// Run next iteration
							if (index == customers.length - 1) {
								dispatch({ type: types.QUICKBOOKS_IMPORT + '_FULFILLED' });
							} else {
								dispatch(importCustomers(handle, customers, index + 1, importId, quickbooksSettings, callback, 'skip', true));
							}
						}
					},

					show_cancel: false,
				});
			}
		} else {
			const newCustomer = {
				displayName: customer?.DisplayName,
				displayNameSearchable: customer?.DisplayName?.toLowerCase(),
				// customerTypeId: ,
				companyName: customer?.CompanyName,
				notes: customer?.Notes,

				// Billing Address Info
				billingAddressName: '',
				billingAddressTypeId: ADDRESSES.BILLING.id,
				billingAddressLine1: customer?.BillAddr?.Line1,
				billingAddressLine2: customer?.BillAddr?.Line2,
				billingCity: customer?.BillAddr?.City,
				billingState: customer?.BillAddr?.CountrySubDivisionCode,
				billingZip: customer?.BillAddr?.PostalCode,

				// Shipping Address Info
				shippingAddressName: '',
				shippingAddressTypeId: ADDRESSES.DELIVERY.id,
				shippingAddressLine1: customer?.ShipAddr?.Line1,
				shippingAddressLine2: customer?.ShipAddr?.Line2,
				shippingCity: customer?.ShipAddr?.City,
				shippingState: customer?.ShipAddr?.CountrySubDivisionCode,
				shippingZip: customer?.ShipAddr?.PostalCode,

				// Main Contact Info(Billing)
				contactTypeId: CONTACTS.OWNER.id,
				firstName: customer?.GivenName,
				lastName: customer?.FamilyName,
				email: customer?.PrimaryEmailAddr?.Address,
				phone: customer?.PrimaryPhone?.FreeFormNumber,
				mobile: customer?.Mobile?.FreeFormNumber,
				fax: customer?.Fax?.FreeFormNumber,
				department: '',
				notifyViaEmail: true,
				notifyViaSms: true,
				quickbooksId: customer?.Id,

				importId: importId,
			};
			Object.keys(newCustomer).forEach((key) => { if (newCustomer[key] == undefined) newCustomer[key] = ''; });

			dispatch(saveProfile(handle, newCustomer, 'CUSTOMER', 'QuickBooks Import', () => {
				window.toastr.success(`${newCustomer.displayName} saved successfully`, 'Customer Saved!');

				const quickbooksSettingsRef = firestore.collection(handle + '/settings/quickbooks').doc('quickbooks');
				quickbooksSettingsRef.set({ lastImport: { importId, type: 'customer' } }, { merge: true });

				// Run next iteration
				if (index == customers.length - 1) {
					dispatch({ type: types.QUICKBOOKS_IMPORT + '_FULFILLED' });

				} else {
					dispatch(importCustomers(handle, customers, index + 1, importId, callback));
				}
			}, true, true));

		}
	};
}

// 
//	SAVE A SINGLE PROFILE OF PROFILE_TYPE
//

export function saveProfile({ handle, profileId, profileForm, profileType, prevEmail, silent = false, callback }) {

	// Primarily Updates Profile, but also creates associated Contact, Address, & User for new profiles.

	return async dispatch => {

		dispatch({ type: types['PROFILE'] + '_SAVE_PENDING' });

		var addressId;
		var contactId;
		var user;
		var batch = firestore.batch();
		var type = profileType?.toLowerCase();

		var { addressName, addressLine1, addressLine2, city, state, zip, profileIds, email, firstName, lastName, mobile, phone, contactTypeId, 
			addressTypeId, hireDate, terminationDate, notifyViaEmail, notifyViaSms, photoUrl, roles,
			activated, ...profile_fields } = profileForm;

		const fields = [
			'displayName',
			'companyName',
			'departmentId', 
			'website',
			'tags', 
			'notes',
			'roles',
			'certifications', 
			'schedule',
			'showOnSchedule',
			'deleted',
			'type',
		];

		var profile = toolbox.sanitize(profile_fields, fields);
	
		if (roles) profile = { ...profile, roles: roles.map((role) => role.value) };

		/* New Profile -----------------------------------------------------------------*/

		if (!parseInt(profileId)) {
			// Check if an employee is already registered under this email
			if (profileType == "EMPLOYEE" && email) {
				const userRef = firestore.collection(handle + "/users/users").doc(profileForm.email);
				const userDoc = await getDoc(userRef);
				if (userDoc.exists() && !userDoc.data()?.['deleted']) {
					toolbox.process_error('A user already exists with this email!');
					dispatch({ type: types[profileType] + '_SAVE_FULFILLED' });
					return;
				}
			}
			
			profile.type = type;
			profile.schedule = DEFAULT_SCHEDULE;
			profileId = await nextProfileId(handle, batch);

			/* Contact ---------------------------------------------------*/

			if (firstName || lastName || email || mobile || phone) {
				contactId = await nextContactId(handle, batch);
				var contact = { contactTypeId: (contactTypeId) ? contactTypeId : null, firstName, lastName, email, 
					mobile, phone, hireDate, terminationDate };
				contact.profileIds = [profileId];
				contact.notifyViaEmail = true;
				contact.notifyViaSms = true;
				contact.photoUrl = photoUrl;
				contact.type = type;
				contact.deleted = false;
				contact.created = new Date();
				contact.modified = new Date();
				if (profileForm?.importId) contact.importId = profileForm.importId;

				const contactRef = firestore.collection(handle + '/contacts/contacts');
				batch.set(contactRef.doc(contactId), { ...contact }, { merge: true });
			}

			/* Address ---------------------------------------------------*/

			if (addressName || addressLine1 || addressLine2 || city || state || zip) {
				addressId = await nextAddressId(handle, batch);
				var address = { addressId, addressTypeId, addressName, addressLine1, addressLine2, city, state, zip, };
				if (contactId) address.contactIds = [contactId];
				address.profileId = profileId;
				address.deleted = false;
				address.created = new Date();
				address.modified = new Date();

				const addressRef = firestore.collection(handle + '/addresses/addresses');
				batch.set(addressRef.doc(addressId), { ...address }, { merge: true });
			}

			/* user ------------------------------------------------------*/

			if (profileType == "EMPLOYEE" && email) {
				user = { roles: roles.map((role) => role.value) };
				user.deleted = false;
				user.firstName = firstName;
				user.lastName = lastName;
				user.profileId = profileId;

				const usersRef = firestore.collection(handle + '/users/users');
				batch.set(usersRef.doc(email), user, { merge: true });
			}

			/* profile ---------------------------------------------------*/

			profile.created = new Date();
			profile.deleted = false;

		} else {
			if (profileType == "EMPLOYEE") {
				const usersRef = firestore.collection(handle + '/users/users');
				if (prevEmail && prevEmail != email) {
					batch.delete(usersRef.doc(prevEmail));
				}
				if (email) {
					user = {};
					if (roles) user.roles = roles.map((role) => role.value);
					user.deleted = false;
					user.firstName = firstName;
					user.lastName = lastName;
					user.profileId = profileId;

					batch.set(usersRef.doc(email), user, { merge: true });
				}
			}
		}

		profile.modified = new Date();

		const profileRef = firestore.collection(handle + '/profiles/profiles');
		batch.set(profileRef.doc(profileId), { ...profile }, { merge: true });

		await batch.commit().then(() => {
			dispatch({ type: types['PROFILE'] + '_SAVE_FULFILLED', data: profile });
			(!silent) && window.toastr.success('The Profile has been successfully saved/updated', 'Profile Saved!');
			if (typeof callback === 'function') callback(profileId, addressId, contactId);
		}).catch((error) => {
			toolbox.process_error(error, 'Profile NOT Saved!');
		});
	};
}

export function deleteProfile(handle, profileId, profile_type, callback) {

	return async dispatch => {

		dispatch({ type: types[profile_type] + '_SAVE_PENDING' });

		var batch = firestore.batch();

		const profileRef = firestore.collection(handle + '/profiles/profiles');
		batch.set(profileRef.doc(profileId), { deleted: true, modified: new Date() }, { merge: true });

		batch.commit().then(() => {
			dispatch({ type: types[profile_type] + '_SAVE_FULFILLED' });
			window.toastr.success('The Profile record has been Archived', 'Profile Archived!');
			if (typeof callback === 'function') callback();
		}).catch((error) => {
			toolbox.process_error(error, 'Profile NOT Archived!');
		});
	};
}

/*-----------------------------------------------*/
/*  ADDRESSES
/*-----------------------------------------------*/

export function getAddress(handle, id) {

	return dispatch => {

		dispatch({ type: types.ADDRESS + '_PENDING' });

		firestore.collection(handle + '/addresses/addresses').doc(id).get().then((doc) => {
			var address = { ...doc.data(), id: doc.id };
			dispatch({ type: types.ADDRESS + '_FULFILLED', data: address });
		});
	};
}
export function clearAddress() {

	return dispatch => {
		dispatch({ type: types.ADDRESS + '_CLEAR' });
	};
}
export function saveAddress(handle, profileId, address, callback) {

	return async dispatch => {

		dispatch({ type: types.ADDRESS + '_SAVE_PENDING' });

		const fields = [
			'addressTypeId', 
			'addressName',
			'addressLine1', 
			'addressLine2', 
			'city', 
			'state', 
			'zip',
			'deleted',
		];

		var batch = firestore.batch();
		var addressId = address.id;
		var update = toolbox.sanitize(address, fields);

		if (!parseInt(addressId)) {
			addressId = await nextAddressId(handle, batch);
			update.profileId = profileId;
			update.created = new Date();
		}
		update.modified = new Date();

		var { addressTypeId, addressName, addressLine1, addressLine2, city, state, zip } = address;
		update = { ...update, addressTypeId: (addressTypeId) ? addressTypeId : null, addressName, addressLine1, addressLine2, 
			city, state, zip };

		batch.set(firestore.collection(handle + '/addresses/addresses').doc(addressId), { ...update }, { merge: true });

		batch.commit().then((doc) => {
			dispatch({ type: types.ADDRESS + '_FULFILLED', data: address });
			window.toastr.success('The Address record has been successfully saved/updated', 'Address Saved!');
			if (typeof callback === 'function') callback(addressId);
		});
	};
}
export function deleteAddress(handle, address, callback) {

	return async dispatch => {

		dispatch({ type: types.ADDRESS + '_SAVE_PENDING' });
		
		var batch = firestore.batch();
		batch.delete(firestore.collection(handle + '/addresses/addresses').doc(address.id));
		batch.commit().then((doc) => {
			dispatch({ type: types.ADDRESS + '_FULFILLED', data: null });
			window.toastr.success('The Address record has been successfully deleted', 'Address Deleted!');
			if (typeof callback === 'function') callback(address.id);
		});
	};
}

/*-----------------------------------------------*/
/*  CONTACTS
/*-----------------------------------------------*/

export function getContact(handle, id) {
	
	return dispatch => {

		dispatch({ type: types.CONTACT + '_PENDING' });
		
		firestore.collection(handle + '/contacts/contacts').doc(id).get().then((doc) => {
			var contact = { ...doc.data(), id: doc.id };
			
			dispatch({ type: types.CONTACT + '_FULFILLED', data: contact });
		});
	};
}
export function clearContact() {

	return dispatch => {
		dispatch({ type: types.CONTACT + '_CLEAR' });
	};
}
export function saveContact(handle, profileId, contact, type, callback) {

	return async dispatch => {

		dispatch({ type: types.CONTACT + '_SAVE_PENDING' });

		const fields = [
			'contactTypeId', 
			'profileIds',
			'firstName', 
			'lastName', 
			'email', 
			'mobile', 
			'phone',
			'notes',
			'hireDate', 
			'terminationDate',
			'photoUrl',
			'type',
			'deleted',
		];
		
		var batch = firestore.batch();
		var contactId = contact.id;
		var update = toolbox.sanitize(contact, fields);

		if (!parseInt(contactId)) {
			contactId = await nextContactId(handle, batch);
			update.profileIds = firebase.firestore.FieldValue.arrayUnion(profileId);
			update.type = type;
			update.created = new Date();
			update.deleted = false;
		} else {
			contactId = contact.id;
		}
		update.modified = new Date();

		if (parseInt(contact.prevLinkAddressId)) {
			batch.update(firestore.collection(handle + '/addresses/addresses').doc(contact.prevLinkAddressId), {
				contactIds: firebase.firestore.FieldValue.arrayRemove(contactId)
			});
		}
		if (parseInt(contact.linkAddressId)) {
			batch.update(firestore.collection(handle + '/addresses/addresses').doc(contact.linkAddressId), {
				contactIds: firebase.firestore.FieldValue.arrayUnion(contactId)
			});
		}

		batch.set(firestore.collection(handle + '/contacts/contacts').doc(contactId), { ...update }, { merge: true });
		batch.set(firestore.collection(handle + '/profiles/profiles').doc(profileId), { modified: new Date() }, { merge: true });

		batch.commit().then((doc) => {
			dispatch({ type: types.CONTACT + '_FULFILLED', data: contact });
			if (typeof callback === 'function') callback(contactId);
		});
	};
}
export function deleteContact(handle, contact, callback) {

	return async dispatch => {

		dispatch({ type: types.CONTACT + '_SAVE_PENDING' });
		
		var batch = firestore.batch();
		batch.delete(firestore.collection(handle + '/contacts/contacts').doc(contact.id));
		batch.commit().then((doc) => {
			dispatch({ type: types.CONTACT + '_FULFILLED', data: null });
			window.toastr.success('The Contact record has been successfully deleted', 'Contact Deleted!');
			if (typeof callback === 'function') callback(contact.id);
		});
	};
}

/*-----------------------------------------------*/
/*  CUSTOMERS
/*-----------------------------------------------*/

export function getCustomerFromSearch(handle, id) {

	return dispatch => {

		dispatch({ type: types.CUSTOMER_SEARCH + '_PENDING' });

		firestore.collection(handle + '/profiles/profiles').doc(id).get().then(async (doc) => {

			if (doc.exists) {
				var customer = { ...doc.data(), id: doc.id };
				var addresses = [];
				var contacts = [];

				var snapshot1 = await firestore.collection(handle + '/addresses/addresses').where('profileId', '==', customer.id).where('deleted', '==', false).get();
				snapshot1.forEach((doc) => {
					addresses.push({ ...doc.data(), addressId: doc.id });
				});

				var snapshot2 = await firestore.collection(handle + '/contacts/contacts').where('profileIds', 'array-contains', customer.id).where('deleted', '==', false).get();
				snapshot2.forEach((doc) => {
					contacts.push({ ...doc.data(), contactId: doc.id });
				});

				customer.addresses = addresses;
				customer.contacts = contacts;
			}

			dispatch({ type: types.CUSTOMER_SEARCH + '_FULFILLED', data: customer });
		});
	};
}
export function clearCustomerFromSearch(handle, id) {

	return dispatch => {

		dispatch({ type: types.CUSTOMER_SEARCH + '_CLEAR' });
	};
}

/*-----------------------------------------------*/
/*  NEXT IDS
/*-----------------------------------------------*/

async function nextProfileId(handle, batch) {
	const table = 'profiles';
	const field = 'nextProfileId';
	const startingId = 1000;

	return toolbox.nextId(handle, batch, table, field, startingId);
}
async function nextAddressId(handle, batch) {
	const table = 'addresses';
	const field = 'nextAddressId';
	const startingId = 1000;

	return toolbox.nextId(handle, batch, table, field, startingId);
}
async function nextContactId(handle, batch) {
	const table = 'contacts';
	const field = 'nextContactId';
	const startingId = 1000;

	return toolbox.nextId(handle, batch, table, field, startingId);
}
