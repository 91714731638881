import * as actions_admin from 'actions/admin-actions';
import * as actions_authentication from 'actions/authentication-actions';
import NavbarSearch from 'components/layout/navbar/navbar-search';
import React, { useState } from 'react';
import ServiceRequestForm from 'components/service-requests/service-request/service-request-form/service-request-form';
import StatusModal from 'components/common/status-modal';
import store from 'store';
import { Link } from "react-router-dom";
import { numPanels } from 'components/common/toolbox';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Lightbox from "yet-another-react-lightbox";
import { Thumbnails, Zoom } from "yet-another-react-lightbox/plugins";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

var moment = require('moment');

export default function Navbar(props) {

    const dispatch = useDispatch();
	const routeLocation = useLocation();
    const segments = routeLocation.pathname.split('/');

    const admin = useSelector((store) => store.admin);
    const company = useSelector((store) => store.company.company);
    const statusModal = useSelector((store) => store.settings.statusModal);
    const user_permissions = useSelector((store) => store.users?.user_permissions);
    const [callLogOpen, setCallLogOpen] = useState(false);

    const logOut = (event) => {
        event.preventDefault();
        store.dispatch(actions_authentication.logout());
    }
    const openHelp = () => dispatch(actions_admin.showHelp(true, '/getting-started/'));

    const closeModal = () => {
        setCallLogOpen(false);
    }
    const MobileLink = (props) => 
        <li className="nav-item">
            <Link replace={ !props.push } id={props.id} className={props.className} to={props.to} data-toggle={ (window.flutter)?'':'collapse'} data-target=".navbar-collapse">{props.name} </Link>
        </li>

    return (

        <div className="row border-bottom white-bg" style={{ marginLeft: (numPanels(1)) ? '0':'80px', ...(window.flutter)?{display: 'none'}:{} }}>
            <nav className="navbar navbar-expand-lg navbar-static-top" 
                style={{ justifyContent: 'normal', ...(numPanels(1)) ? { backgroundColor: '#8FBC8B' } : {}}} 
                role="navigation"
            >
                <span className="position-relative">
                    <div className="navbar-brand" style={{ border: 'none' }}>
                        <button className="navbar-toggler collapsed m-0 p-0 pr-4" type="button" data-toggle="collapse" data-target="#navbar">
                            <i className="fa fa-bars" style={{ fontSize: '18px' }}></i>
                        </button>
                        <i className="fas fa-paper-plane" aria-hidden="true"></i>
                        { !numPanels(1) &&
                            <>
                                &nbsp; Service Manager &nbsp;
                                {process.env.REACT_APP_FIREBASE_ENV == 'development' &&
                                    <small style={{ color: 'red' }}>{process.env.REACT_APP_FIREBASE_ENV?.toUpperCase()}</small>
                                }
                            </>
                        }
                        <span className="mx-2">|</span>
                        { company?.companyName }
                    </div>
                </span>

                <div className="navbar-collapse collapse" id="navbar">
                    <NavbarSearch />
                    <ul className="nav navbar-nav mr-auto bg-white">    
                        { numPanels(1) &&
                            <>
                                <MobileLink name="Dashboard" id="link-dashboard" to={`/${props.handle}/dashboard`} />
                                <MobileLink name="Schedule" id="link-schedule" to={`/${props.handle}/schedule/${moment().format('YYYY-MM-DD')}`} />
                                <MobileLink name="Customers" id="link-customers" to={`/${props.handle}/customers`} />
                                <MobileLink name="Inventory" id="link-stock-parts" to={`/${props.handle}/stock_parts/`} />
                                <MobileLink name="Employees" id="link-employees" to={`/${props.handle}/employees`} />
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" id="settings-submenu" data-toggle="dropdown">Settings</a>
                                    <ul className="dropdown-menu" aria-labelledby="settings-submenu">
                                        <MobileLink push={ segments[2] !== 'settings' } name="Company" id="link-settings-company" to={`/${props.handle}/settings/company`} />
                                        <MobileLink push={ segments[2] !== 'settings' } name="Preferences" id="link-settings-prefs" to={`/${props.handle}/settings/company_preferences`} />
                                        <MobileLink push={ segments[2] !== 'settings' } name="User Preferences" id="link-user-prefs" to={`/${props.handle}/settings/user_preferences`} />
                                        <MobileLink push={ segments[2] !== 'settings' } name="Delete Account" id="link-delete-account" to={`/${props.handle}/account_delete`} className="text-danger" />
                                    </ul>
                                </li>
                                <li className="nav-item"><p className="float-right px-4">{ window.build }</p></li>
                            </>
                        }
                    </ul>
                    <ul className="nav navbar-top-links navbar-right bg-white">
                        <li><a className="btn btn-info btn-xs text-white callcenter" role="button" onClick={() => setCallLogOpen(!callLogOpen)}><i className="fa fa-phone fa-flip-horizontal" aria-hidden="true"></i> CALL CENTER</a></li>
                        <li><a title="logout" id="link-logout" style={{ padding: '10px' }} role="button" onClick={logOut}> <i className="fas fa-sign-out-alt fa-2x text-muted"></i></a></li>
                        <li><a title="help" id="link-help" style={{ padding: '10px' }} role="button" onClick={openHelp}> <i className="fas fa-question-circle fa-2x text-muted"></i></a></li>
                    </ul>
                </div>
            </nav>
            {callLogOpen && user_permissions?.CALL_CENTER &&
                <ServiceRequestForm closeModal={closeModal} isCall={true} />
            }
            {statusModal &&
                <StatusModal />
            }
            <Lightbox
                open={admin.lightbox_show}
                close={() => dispatch(actions_admin.lightboxShow(false))}
                slides={admin.lightbox_sources}
                plugins={[Thumbnails, Zoom]}
                zoom={{
                    maxZoomPixelRatio: 4,
                    pinchZoomDistanceFactor: 200,
                }}
                carousel={{ finite: true }}
                render={{
                  buttonPrev: admin.lightbox_sources.length <= 1 ? () => null : undefined,
                  buttonNext: admin.lightbox_sources.length <= 1 ? () => null : undefined,
                }}
                toolbar={{
                    buttons: [
                      <button key="my-button" type="button" className="yarl__button">
                        <span class="material-icons">add_a_photo</span>
                      </button>,
                      "close",
                    ],
                }}
            />
        </div>
    );
}
