import React from 'react';
import { Route } from "react-router-dom";
import FieldServiceEmployees from 'components/dashboard/field-service-map/field-service-employees';
import EmployeeMap from 'components/employees/employee/employee-map';
import CatchErrors from 'components/common/catch-errors';

const FieldServiceMap = (props) => {

    return (
        <>
            <div className="col-12">
                <div className="row">

                    <Route
                        path={props.match.path + '/:employee_id?'} render={() => (
                            <div className="column col-3 animate-left mt-4">
                                <CatchErrors className={'m-t-lg'}>
                                    <FieldServiceEmployees />
                                </CatchErrors>
                            </div>
                        )}
                    />

                    <Route path={props.match.path + '/:employee_id/:date/:tab_id?'} render={(route_props) =>
                        <>
                            <div className="column col animate-left">
                                <CatchErrors className={'m-t-lg'}>
                                    <EmployeeMap {...route_props} source='dashboard' />
                                </CatchErrors>
                            </div>
                        </>
                    } />

                </div>
            </div>
        </>
    );
};
export default FieldServiceMap;
