import * as toolbox from 'components/common/toolbox';
import * as types from './action-types';
import firebase from 'firebase/compat/app';
import { toastr } from 'react-redux-toastr';
import moment from 'moment';
import { SERVICE_ITEMS, UNITS } from 'components/common/constants';
import { ModalAlert } from 'enspire-manager-framework';

const firestore = firebase.firestore();


/*-----------------------------------------------*/
/*  SERVICE ITEMS CATEGORIES
/*-----------------------------------------------*/
export function saveServiceItemCategories(handle, categories, callback) {

	return async dispatch => {

		dispatch({ type: types.SERVICE_ITEM_CATEGORIES + '_SAVE_PENDING' });

		firestore.collection(handle).doc('service-items').set({
			serviceItemCategories: categories,
			categoriesModified: new Date(),
		}, { merge: true }).then(() => {
			dispatch({ type: types.SERVICE_ITEM_CATEGORIES + '_SAVE_FULFILLED' });
			if (typeof callback === 'function') callback();

		}).catch((error) => {
			console.error("Error updating document: ", error);
		});
	};
}
export function subServiceItemSettings(handle, callback) {

	return async dispatch => {

		dispatch({ type: types.SERVICE_ITEM_SETTINGS + '_PENDING' });

		var unsubscribe = firestore.collection(handle).doc('service-items').onSnapshot((doc) => {
			var settings = { ...doc.data(), id: doc.id };

			dispatch({ type: types.SERVICE_ITEM_SETTINGS + '_FULFILLED', data: settings, unsubscribe });
			if (typeof callback === 'function') callback(settings);
		});
	};
}

export function saveServiceItemSettings(handle, update, callback) {

	return async dispatch => {

		dispatch({ type: types.SERVICE_ITEM_SETTINGS + '_SAVE_PENDING' });

		firestore.collection(handle).doc('service-items').set(update, { merge: true }).then(() => {
			dispatch({ type: types.SERVICE_ITEM_SETTINGS + '_SAVE_FULFILLED' });
			if (typeof callback === 'function') callback();

		}).catch((error) => {
			console.error("Error updating document: ", error);
		});
	};
}
/*-----------------------------------------------*/
/*  SERVICE ITEMS
/*-----------------------------------------------*/

// export function update() {

// 	return async dispatch => {

// 		firestore.collection('hukillsftw/service-items/service-items').where('serviceItemTypeId', '==', 1).get().then(async(querySnapshot) => {

// 			var serviceItems = [];
// 			querySnapshot.forEach((doc) => {
// 				serviceItems.push({ ...doc.data(), id: doc.id });
// 			});

// 			for (var item of serviceItems) {
// 				console.log(item.id);
// 				await firestore.collection('hukillsftw/service-items/service-items').doc(item.id).update({ 
// 					category: firebase.firestore.FieldValue.delete(),
// 					categoryId: firebase.firestore.FieldValue.delete(),
// 					serviceItemTypeId: '2',
// 					unitTypeId: '1',
// 				});
// 			}
// 		});
// 	};
// }
export function addServiceItemById(handle, id, callback) {

	return async dispatch => {

		dispatch({ type: types.SERVICE_ITEM + '_PENDING' });

		firestore.collection(handle + '/service-items/service-items').doc(id).get().then((doc) => {
			var item = { ...doc.data(), id: doc.id };

			dispatch({ type: types.SERVICE_ITEM + '_FULFILLED', data: item });
			dispatch({ type: types['SERVICE_ITEMS'] + '_ADD', data: item });
			if (typeof callback === 'function') callback(item);
		});
	};
}
export function subServiceItemById(handle, id) {

	return dispatch => {

		dispatch({ type: types.SERVICE_ITEM + '_PENDING' });

		var unsubscribe = firestore.collection(handle + '/service-items/service-items').doc(id).onSnapshot((doc) => {
			var item = { ...doc.data(), id: doc.id };

			dispatch({ type: types.SERVICE_ITEM + '_FULFILLED', data: item, unsubscribe });
		});
	};
}
export function subServiceItemsByWorkOrderID(handle, work_order_id, callback) {

	return dispatch => {

		dispatch({ type: types.WORK_ORDER_ITEMS + '_CLEAR' });
		dispatch({ type: types.WORK_ORDER_ITEMS + '_PENDING' });

		let unsubscribe = firestore.collection(handle + '/work-orders/work-orders/' + work_order_id + '/serviceItems').doc(work_order_id).onSnapshot(querySnapshot => {

			let serviceItems = (querySnapshot.exists) ? querySnapshot.data().serviceItems : [];

			dispatch({ type: types.WORK_ORDER_ITEMS + '_FULFILLED', data: serviceItems, unsubscribe });
			if (typeof callback === 'function') callback(serviceItems);
		});
	};
}
export function undoServiceItemsImport(handle, importId) {
	return async dispatch => {

		var batch = firestore.batch();

		// Get all Item records with this importId
		const itemsRef = firestore.collection(handle + '/service-items/service-items').where('importId', '==', importId);
		const itemsSnapshot = await itemsRef.get();
		itemsSnapshot.forEach((doc) => {
			// Delete all Item records associated with this importId
			batch.delete(firestore.collection(handle + '/service-items/service-items').doc(doc.id));
		});

		// Remove lastImport information from Quickbooks settings
		const quickbooksSettingsRef = firestore.collection(handle + '/settings/quickbooks').doc('quickbooks');
		batch.set(quickbooksSettingsRef, { lastImport: firebase.firestore.FieldValue.delete() }, { merge: true });

		batch.commit().then(() => {
			window.toastr.success('The import has been successfully undone', 'Import Undone!');
		}).catch((error) => {
			window.toastr.error('Error: ' + error, 'Import NOT Undone!');
		});

	};
}
export function mergeItems(options, item, quickbooksSettings, callback) {

	return async dispatch => {
		const isDev = (process.env.REACT_APP_FIREBASE_ENV == 'development');
		if (isDev && quickbooksSettings?.useEmulator) firebase.functions().useEmulator("localhost", 5001); // Testing locally with emulator

		firebase.functions().httpsCallable('mergeItems')(options).then(() => {
			window.toastr.success(`${item.Name || item.name} saved successfully`, 'Item Saved!');
			if (typeof callback == 'function') callback();
		}).catch((error) => {
			window.toastr.error(error, 'Error Saving Item!');
			if (typeof callback == 'function') callback();
		});
	};
}
export function importServiceItems(handle, serviceItems, index, importId, quickbooksSettings, callback, lastSelection, applyToAll) {

	if (!importId) importId = moment().format('YYYYMMDDHHmmssSSS');

	var item = serviceItems[index];

	return async dispatch => {

		if (index == 0) dispatch({ type: types.QUICKBOOKS_IMPORT + '_PENDING' });

		const existingRef = await firestore.collection(handle + '/service-items/service-items').where('nameSearchable', '==', item.Name.toLowerCase()).get();
		const existing = { ...existingRef.docs[0]?.data(), id: existingRef.docs[0]?.id };

		// Duplicate found
		if (existing.id) {

			// Apply to all 
			if (applyToAll) {
				if (lastSelection == 'quickbooks') {
					dispatch(mergeItems({
						handle: handle,
						itemId: existing.id,
						priority: 'quickbooks',
						final: (index == serviceItems.length - 1)
					}, existing, quickbooksSettings, () => {
						// Run next iteration
						if (index == serviceItems.length - 1) {
							dispatch({ type: types.QUICKBOOKS_IMPORT + '_FULFILLED' });
						} else {
							dispatch(importServiceItems(handle, serviceItems, index + 1, importId, quickbooksSettings, callback, 'quickbooks', true));
						}
					}));
				} else if (lastSelection == 'mobiletrack') {
					dispatch(mergeItems({
						handle: handle,
						itemId: existing.id,
						priority: 'mobiletrack',
						final: (index == serviceItems.length - 1)
					}, existing, quickbooksSettings, () => {
						// Run next iteration
						if (index == serviceItems.length - 1) {
							dispatch({ type: types.QUICKBOOKS_IMPORT + '_FULFILLED' });
						} else {
							dispatch(importServiceItems(handle, serviceItems, index + 1, importId, quickbooksSettings, callback, 'mobiletrack', true));
						}
					}));
				} else {
					// Run next iteration
					if (index == serviceItems.length - 1) {
						dispatch({ type: types.QUICKBOOKS_IMPORT + '_FULFILLED' });
					} else {
						dispatch(importServiceItems(handle, serviceItems, index + 1, importId, quickbooksSettings, callback, 'skip', true));
					}
				}
			} else {
				// Select update priority
				ModalAlert({
					title: `Existing Item: "${item.Name}" Already Exists`,
					text: "These Items will be merged into one. Which data should take priority?",
					icon: 'warning',
					input: 'radio',
					value: (lastSelection) ? lastSelection : 'quickbooks',
					options: {
						'quickbooks': 'QuickBooks',
						'mobiletrack': 'MobileTrack',
						'skip': 'Skip Merge'
					},

					// Continue
					confirm_color: '#8FBC8B',
					confirm_text: 'Continue',
					callback_success: async (inputValue) => {

						if (inputValue == 'quickbooks') {
							dispatch(mergeItems({
								handle: handle,
								itemId: existing.id,
								priority: 'quickbooks',
								final: (index == serviceItems.length - 1)
							}, existing, quickbooksSettings, () => {
								// Run next iteration
								if (index == serviceItems.length - 1) {
									dispatch({ type: types.QUICKBOOKS_IMPORT + '_FULFILLED' });
								} else {
									dispatch(importServiceItems(handle, serviceItems, index + 1, importId, quickbooksSettings, callback, 'quickbooks'));
								}
							}));
						} else if (inputValue == 'mobiletrack') {
							dispatch(mergeItems({
								handle: handle,
								itemId: existing.id,
								priority: 'mobiletrack',
								final: (index == serviceItems.length - 1)
							}, existing, quickbooksSettings, () => {
								// Run next iteration
								if (index == serviceItems.length - 1) {
									dispatch({ type: types.QUICKBOOKS_IMPORT + '_FULFILLED' });
								} else {
									dispatch(importServiceItems(handle, serviceItems, index + 1, importId, quickbooksSettings, callback, 'mobiletrack'));
								}
							}));
						} else {
							// Run next iteration
							if (index == serviceItems.length - 1) {
								dispatch({ type: types.QUICKBOOKS_IMPORT + '_FULFILLED' });
							} else {
								dispatch(importServiceItems(handle, serviceItems, index + 1, importId, quickbooksSettings, callback, 'skip'));
							}
						}
					},

					// Apply to All
					show_deny: (index != serviceItems.length - 1),
					deny_color: '#4682B4',
					deny_text: 'Apply to All',
					callback_denied: async (inputValue) => {

						if (inputValue == 'quickbooks') {
							dispatch(mergeItems({
								handle: handle,
								itemId: existing.id,
								priority: 'quickbooks',
								final: (index == serviceItems.length - 1)
							}, existing, quickbooksSettings, () => {
								// Run next iteration
								if (index == serviceItems.length - 1) {
									dispatch({ type: types.QUICKBOOKS_IMPORT + '_FULFILLED' });
								} else {
									dispatch(importServiceItems(handle, serviceItems, index + 1, importId, quickbooksSettings, callback, 'quickbooks', true));
								}
							}));
						} else if (inputValue == 'mobiletrack') {
							dispatch(mergeItems({
								handle: handle,
								itemId: existing.id,
								priority: 'mobiletrack',
								final: (index == serviceItems.length - 1)
							}, existing, quickbooksSettings, () => {
								// Run next iteration
								if (index == serviceItems.length - 1) {
									dispatch({ type: types.QUICKBOOKS_IMPORT + '_FULFILLED' });
								} else {
									dispatch(importServiceItems(handle, serviceItems, index + 1, importId, quickbooksSettings, callback, 'mobiletrack', true));
								}
							}));
						} else {
							// Run next iteration
							if (index == serviceItems.length - 1) {
								dispatch({ type: types.QUICKBOOKS_IMPORT + '_FULFILLED' });
							} else {
								dispatch(importServiceItems(handle, serviceItems, index + 1, importId, quickbooksSettings, callback, 'skip', true));
							}
						}
					},

					show_cancel: false,
				});
			}

		} else {
			// Not a duplicate, save as usual
			const newItem = {
				serviceItemTypeId: (item.Type == "Service")
					? SERVICE_ITEMS.LABOR.id
					: (item.Type == "Inventory")
						? SERVICE_ITEMS.PARTS_SUPPLIES.id
						: SERVICE_ITEMS.LABOR.id
				,
				name: item.Name,
				nameSearchable: item.Name.toLowerCase(),
				description: item?.Description,
				pricePerUnit: item.UnitPrice,
				unitTypeId: UNITS.EACH.id,
				isTaxable: item?.Taxable,

				quickbooksId: item.Id,
				importId: importId,
			};

			Object.keys(newItem).forEach((key) => { if (newItem[key] == undefined) newItem[key] = ''; });

			dispatch(saveServiceItem(handle, newItem, () => {
				window.toastr.success(`${item.Name} saved successfully`, 'Item Saved!');

				const quickbooksSettingsRef = firestore.collection(handle + '/settings/quickbooks').doc('quickbooks');
				quickbooksSettingsRef.set({ lastImport: { importId, type: 'service-item' } }, { merge: true });

				// Run next iteration
				if (index == serviceItems.length - 1) {
					dispatch({ type: types.QUICKBOOKS_IMPORT + '_FULFILLED' });
				} else {
					dispatch(importServiceItems(handle, serviceItems, index + 1, importId, callback));
				}
			}));
		}
	};
}
export function saveServiceItem(handle, serviceItem, callback) {

	return async dispatch => {

		dispatch({ type: types.SERVICE_ITEM + "_SAVE_PENDING" });

		var id = serviceItem.id;
		const batch = firestore.batch();
		serviceItem.nameSearchable = serviceItem.name.toLowerCase();

		// Check for Service Item existing under this name
		const existingRef = await firestore.collection(handle + '/service-items/service-items').where('nameSearchable', '==', serviceItem.name.toLowerCase()).get();
		const existing = { ...existingRef.docs[0]?.data(), id: existingRef.docs[0]?.id };
		if (existing?.id && existing?.id != serviceItem.id) {
			dispatch({ type: types.SERVICE_ITEM + '_SAVE_FULFILLED' });
			return window.toastr.error('Another Service Item is already using this name. Please use a different name.', 'Item NOT Saved!');
		}

		if (!parseInt(id)) {
			id = await nextServiceItemId(handle, batch);
			serviceItem.created = new Date();
			serviceItem.deleted = false;
		}

		delete serviceItem.id;
		serviceItem.modified = new Date();

		batch.set(firestore.collection(handle + '/service-items/service-items').doc(id), { ...serviceItem }, { merge: true });

		await batch.commit().then(() => {

			dispatch({
				type: types.SERVICE_ITEM + '_SAVE_FULFILLED',
				data: serviceItem,
			});

			toastr.success('The Service Item record has been successfully saved/updated');
			if (typeof callback === 'function') callback(id);

		}).catch((error) => {
			toolbox.process_error(error, 'Service Item NOT Saved');
		});
	};
}
export function archiveServiceItem(handle, serviceItem, callback) {

	return async dispatch => {

		dispatch({ type: types.SERVICE_ITEM + "_SAVE_PENDING" });

		var id = serviceItem.id;
		const batch = firestore.batch();

		delete serviceItem.id;
		serviceItem.modified = new Date();

		batch.set(firestore.collection(handle + '/service-items/service-items').doc(id), { deleted: true }, { merge: true });

		batch.commit().then(() => {

			dispatch({ type: types.SERVICE_ITEM + '_SAVE_FULFILLED' });

			window.toastr.success('The Service Item record has been Archived', 'Service Item Archived!');
			if (typeof callback === 'function') callback();

		}).catch((error) => {
			toolbox.process_error(error, 'Service Item NOT Saved');
		});
	};
}

/*-----------------------------------------------*/
/*  FUNCTIONS
/*-----------------------------------------------*/

async function nextServiceItemId(handle, batch) {
	let collection = handle;
	const table = 'service-items';
	const field = 'nextServiceItemId';
	const startingId = 1000;

	return toolbox.nextId(collection, batch, table, field, startingId);
}
