import * as toolbox from 'components/common/toolbox';
import * as types from './action-types';
import firebase from 'firebase/compat/app';
import { batch } from 'react-redux';

const firestore = firebase.firestore();

/*-----------------------------------------------*/
/*  COMPANY
/*-----------------------------------------------*/

export function subCallLog(handle, id) {

	return dispatch => {

		dispatch({ type: types['CALL_LOG'] + '_PENDING' });

		var unsubscribe = firestore.collection(handle + '/call-log/call-log').where('userId', '==', id).onSnapshot((querySnapshot) => {

			var call_log = [];
			querySnapshot.forEach((doc) => {
				call_log.push({ ...doc.data(), callId: doc.id });
			});

			dispatch({ type: types['CALL_LOG'] + '_FULFILLED', data: call_log, unsubscribe });
		});
	};
}

export function saveCallLog(handle, call, callback) {

	return async dispatch => {

		dispatch({ type: types['CALL_LOG'] + '_SAVE_PENDING' });

		const batch = firestore.batch();
		var callLogId = call.id;

		if (!callLogId) {
			callLogId = await nextCallLogId(handle, batch);
			call.created = new Date();
		}
		delete call.id;
		call.modified = new Date();

		Object.keys(call).forEach((key) => { if (call[key] == undefined) delete call[key]; });

		batch.set(firestore.collection(handle + '/call-log/call-log').doc(callLogId), { ...call });
		batch.commit().then((doc) => {
			dispatch({ type: types['CALL_LOG'] + '_SAVE_FULFILLED' });
			// dispatch({ type: types['CALL_LOG_FORM'] + '_CLEAR'});
			window.toastr.success('The Call record has been successfully saved/updated', 'Call Saved!');
			if (typeof callback === 'function') callback(callLogId);
		});
	};
}

export function updateCallLogForm(call_log) {

	return dispatch => {
		dispatch({ type: types['CALL_LOG_FORM'] + '_FULFILLED', data: call_log });
	};

}

// export function resetCallLogForm() {
// 	return dispatch => {
// 		dispatch({ type: types['CALL_LOG_FORM'] + '_CLEAR' });
// 	}
// }

async function nextCallLogId(handle, batch) {
	const table = 'call-log';
	const field = 'nextCallLogId';
	const startingId = 1000;

	return toolbox.nextId(handle, batch, table, field, startingId);
}
