// AUTHENTICATION

export const HELP = 'HELP';
export const FLUTTER_KEYBOARD = 'FLUTTER_KEYBOARD';

export const CALL_LOG = 'CALL_LOG';
export const CALL_LOG_FORM = 'CALL_LOG_FORM';

export const COMPANY = 'COMPANY';

export const CUSTOMER = 'CUSTOMER';
export const CUSTOMER_SEARCH = 'CUSTOMER_SEARCH';
export const CUSTOMER_MAPBOX_SEARCH = 'CUSTOMER_MAPBOX_SEARCH';
export const CUSTOMER_FROM_SEARCH = 'CUSTOMER_FROM_SEARCH';

export const ADDRESS = 'ADDRESS';
export const CONTACT = 'CONTACT';
export const EMPLOYEE = 'EMPLOYEE';
export const EMPLOYEES = 'EMPLOYEES';

export const SERVICE_REQUEST = 'SERVICE_REQUEST';
export const SERVICE_REQUEST_MAPBOX_SEARCH = 'SERVICE_REQUEST_MAPBOX_SEARCH';
export const SERVICE_REQUESTS = 'SERVICE_REQUESTS';
export const REQUEST_OFF = 'REQUEST_OFF';
export const PROJECTS = 'PROJECTS';

export const INVOICE = 'INVOICE';
export const INVOICES = 'INVOICES';
export const QUOTE = 'QUOTE';
export const QUOTES = 'QUOTES';
export const QUOTE_ITEMS = 'QUOTE_ITEMS';
export const QUOTE_ATTACHMENTS = 'QUOTE_ATTACHMENTS';
export const QUOTE_APPROVED = 'QUOTE_APPROVED';
export const OPTIONS_SELECTED = 'OPTIONS_SELECTED';
export const PAYMENTS = 'PAYMENTS';

export const ATTACHMENT = 'ATTACHMENT';
export const ATTACHMENTS = 'ATTACHMENTS';

export const LIGHTBOX_SHOW = 'LIGHTBOX_SHOW';
export const LIGHTBOX_SOURCES = 'LIGHTBOX_SOURCES';

export const WORK_ORDER = 'WORK_ORDER';
export const WORK_ORDER_DRAG_START = 'WORK_ORDER_DRAG_START';
export const WORK_ORDER_DRAG_MOVE = 'WORK_ORDER_DRAG_MOVE';
export const WORK_ORDER_DRAG_END = 'WORK_ORDER_DRAG_END';
export const WORK_ORDER_ITEMS = 'WORK_ORDER_ITEMS';
export const WORK_ORDER_ATTACHMENTS = 'WORK_ORDER_ATTACHMENTS';
export const WORK_ORDER_POPUP = 'WORK_ORDER_POPUP';
export const WORK_ORDERS = 'WORK_ORDERS';
export const WORK_ORDERS_INCOMPLETE = 'WORK_ORDERS_INCOMPLETE';
export const WORK_ORDERS_UNASSIGNED = 'WORK_ORDERS_UNASSIGNED';

export const NEW_WORK_ORDER = 'NEW_WORK_ORDER';
export const NEW_WORK_ORDER_DRAG_START = 'NEW_WORK_ORDER_DRAG_START';
export const NEW_WORK_ORDER_DRAG_MOVE = 'NEW_WORK_ORDER_DRAG_MOVE';
export const NEW_WORK_ORDER_DRAG_END = 'NEW_WORK_ORDER_DRAG_END';

export const SETTINGS = 'SETTINGS';
export const SETTINGS_COMPANY = 'SETTINGS_COMPANY';
export const SETTINGS_VERSION = 'SETTINGS_VERSION';
export const SETTINGS_TOGGLE_COLLAPSED = 'SETTINGS_TOGGLE_COLLAPSED';
export const SETTINGS_TOGGLE_STATUS_MODAL = 'SETTINGS_TOGGLE_STATUS_MODAL';
export const SETTINGS_UPDATE_STATUS_MODAL = 'SETTINGS_UPDATE_STATUS_MODAL';
export const SCHEDULE_PANEL_BUTTON = 'SCHEDULE_PANEL_BUTTON';
export const UPDATE_BUTTON = 'UPDATE_BUTTON';

export const SERVICE_ITEM = 'SERVICE_ITEM';
export const SERVICE_ITEMS = 'SERVICE_ITEMS';
export const SERVICE_ITEM_CATEGORIES = 'SERVICE_ITEM_CATEGORIES';
export const SERVICE_ITEM_SETTINGS = 'SERVICE_ITEM_SETTINGS';

export const USER = 'USER';
export const USER_PERMISSIONS = 'USER_PERMISSIONS';
export const USER_SELECTED = 'USER_SELECTED';
export const USER_LOCATIONS = 'USER_LOCATIONS';
export const USER_ACTIVATED = 'USER_ACTIVATED';
export const USERS = 'USERS';
export const USER_RECENT_ITEMS = 'USER_RECENT_ITEMS';

export const ARCHIVED_PROFILES = 'ARCHIVED_PROFILES';
export const ARCHIVED_SERVICE_REQUESTS = 'ARCHIVED_SERVICE_REQUESTS';
export const ARCHIVED_WORK_ORDERS = 'ARCHIVED_WORK_ORDERS';
export const ARCHIVED_SERVICE_ITEMS = 'ARCHIVED_SERVICE_ITEMS';
export const ARCHIVED_PAYMENTS = 'ARCHIVED_PAYMENTS';

export const DUAL_MONITORS = 'DUAL_MONITORS';
export const SEND_EMAIL = 'SEND_EMAIL';

export const CONVERSATIONS = 'CONVERSATIONS';
export const MESSAGES = 'MESSAGES';
export const UNREAD_MESSAGES = 'UNREAD_MESSAGES';

export const PROFILE = 'PROFILE';
export const PROFILES = 'PROFILES';

export const QUICKBOOKS = 'QUICKBOOKS';
export const QUICKBOOKS_IMPORT = 'QUICKBOOKS_IMPORT';
export const QUICKBOOKS_SYNC = 'QUICKBOOKS_SYNC';

export const ACTIVITY_LOG = 'ACTIVITY_LOG';
export const ERROR_LOG = 'ERROR_LOG';

export const DEPARTMENTS = 'DEPARTMENTS';
export const DEPARTMENT_TAGS = 'DEPARTMENT_TAGS';
export const JOB_CATEGORIES = 'JOB_CATEGORIES';

export const GRAPH_QUOTES = 'GRAPH_QUOTES';
export const GRAPH_INVOICES = 'GRAPH_INVOICES';

export const TIME_CLOCK = 'TIME_CLOCK';
export const TIME_ENTRIES = 'TIME_ENTRIES';
export const TIME_SHEET = 'TIME_SHEET';

export const CHECKIN = 'CHECKIN';
