import * as actions_authentication from './authentication-actions';
import { Avatar } from 'enspire-manager-framework';
import { Spinner } from 'enspire-manager-framework';
import React, { useEffect, useState } from 'react';

import { getAuth, signInWithRedirect } from "firebase/auth";
import { GoogleAuthProvider } from "firebase/auth";
const provider = new GoogleAuthProvider();

export const Register = (props) => {

	const params = props.match.params;
	const history = props.history;
	const dispatch = props.dispatch;
    const firebase = props.firebase;

	const [state, setState] = useState({
		first_name: (params.first) ? params.first : '',
		last_name: (params.last) ? params.last : '',
		email: (params.email) ? params.email : '',
		password: '',
		verify_password: '',

		passwords_mismatch: false,
		loading: false,
		authorizing: false,
		company: {},
	});

	useEffect(() => {
		var user = firebase.auth().currentUser;

		if (user) {
			props.history.push(props.landing);

		} else if (params.handle !== 'default' && !state.company?.id) {
			setState(prev => ({ ...prev, loading: true }));
			dispatch(actions_authentication.verifyHandle(params.handle, firebase, (companyDoc) => {
				setState(prev => ({ ...prev, loading: false }));
				if (companyDoc.exists) {
					setState(prev => ({ ...prev, company: { ...companyDoc.data(), id: companyDoc.id }}));
				} else {
					window.toastr.error('This Company ID cannot be found.  Please check for errors and try again.', 'Not Found');
				}
			}));
		}
	}, []);

	const handleChange = (event) => {
        if (event.target.name == 'email') event.target.value = event.target.value.toLowerCase();
		setState(prev => ({ ...prev, [event.target.name]: event.target.value }));
		if (event.target.name == 'password' || event.target.name == 'verify_password') {
			setState(prev => ({ ...prev, passwords_mismatch: false }));
		}
	}
	const submitForm = (event) => {
		event.preventDefault();

        if (state.password !== state.verify_password || state.password === '' || state.verify_password === '') {
			setState(prev => ({ ...prev, passwords_mismatch: true }));
            document.getElementById('password').style = "border: 1px solid red";
            document.getElementById('verify-password').style = "border: 1px solid red";
            window.toastr.error('Passwords do not match');
        }
        else if (state.password === state.verify_password && state.password !== '' && state.verify_password !== '') {
			setState(prev => ({ ...prev, authorizing: true }));
            dispatch(actions_authentication.register(state.email, state.password, firebase, (user) => {
				if (typeof props.callback === "function") props.callback(user);
            }));
        }
	}

	const googleLogin = () => {
        const auth = getAuth();
        provider.setCustomParameters({ prompt: 'select_account' });
        signInWithRedirect(auth, provider);
    }

	const passwordStyle = (state.passwords_mismatch) ? { border: '1px solid red' } : {}

	return (

		<div id="wrapper" className="gray-bg" style={ { paddingBottom: '300px' } }>

			<div className="middle-box text-center loginscreen animated fadeInDown">
				<div>
					{ state.loading
						?	<div style={ { margin: '60px 0' } }>
								<Spinner />
							</div>
						: 	<>
								{ state.company
									? <div style={ { margin: '20px 0' } }>
                                        { state.company.logoUrl
                                            ? <img src={ state.company.logoUrl } width="100%" alt={ state.company.companyName + ' Logo' } />
                                            : <Avatar
                                                className="mx-auto"
                                                color="white"
                                                bgColor="saddleBrown"
                                                size={250}
                                                name={state.company.handle}
                                                initials={false}
                                                border="8px solid white"
                                            />
                                        }
										<h3 className="mt-3">{ state.company.companyName }</h3>
									</div>
									: <div style={ { margin: '20px 0' } }>
										<img src={ 'images/logo.png' } width="100%" alt="Mobile Track Logo" />
									</div>
								}
							</>
					}

					<form className="register-form" style={ { marginTop: '40px' } } onSubmit={ submitForm }>
						<div className="form-group">
							<input type="text" name="first_name" className="form-control" placeholder="First Name" value={ state.first_name } onChange={ handleChange } />
						</div>
						<div className="form-group">
							<input type="text" name="last_name" className="form-control" placeholder="Last Name" value={ state.last_name } onChange={ handleChange } />
						</div>
						<div className="form-group">
							<input type="text" name="email" className="form-control" placeholder="Email Address" inputMode="email" value={ state.email } onChange={ handleChange } />
						</div>
						<div className="form-group">
							<input type="password" id="password" name="password" className="form-control" placeholder="Password" style={ passwordStyle }value={ state.password } onChange={ handleChange } autoComplete="off" />
						</div>
						<div className="form-group">
							<input type="password" id="verify-password" name="verify_password" className="form-control" placeholder="Verify Password" style={ passwordStyle }value={ state.verify_password } onChange={ handleChange } autoComplete="off" />
						</div>
						{ !state.authorizing
							? <button type="submit" className="btn btn-primary block full-width m-b mt-4" disabled={ state.handle_pending }>REGISTER NEW USER</button>
							: <div style={ { margin: '15px 0' } }><Spinner /></div>
						}

						<div className="line-through mb-3">OR REGISTER WITH</div>
                        <div className="row no-gutters">
                            <div className="col">
                                <a className="btn btn-outline-dark block mr-1" onClick={googleLogin}>
                                    <img width="20px" style={{ marginBottom: '3px', marginRight: '10px' }}  src="/images/google-logo-60.png" />
                                    Google
                                </a>
                            </div>
                            <div className="col">
                                <a className="btn btn-outline-dark block ml-1" onClick={googleLogin}>
                                    <img width="20px" style={{ marginBottom: '3px', marginRight: '10px' }}  src="/images/apple-logo-60.png" />
                                    Apple
                                </a>
                            </div>
                        </div><br/>

						<a onClick={ () => { history.push('/' + params.handle + '/login'); } }>Login Existing User</a>

					</form>
				</div>
			</div>

		</div>
	);
};