import * as toolbox from 'components/common/toolbox';
import * as types from './action-types';
import firebase from 'firebase/compat/app';

const firestore = firebase.firestore();

/*-----------------------------------------------*/
/*  Quotes
/*-----------------------------------------------*/

export function subQuotes(handle) {

    return async dispatch => {

        dispatch({ type: types.GRAPH_QUOTES + '_PENDING' });

        var unsubscribe = firestore.collection(handle + '/graphs/graphs').doc('quotes').onSnapshot((doc) => {

            var quotes = doc.data()?.quotes ?? [];

            dispatch({ type: types.GRAPH_QUOTES + '_FULFILLED', data: quotes, unsubscribe });
        });

    };
}

/*-----------------------------------------------*/
/*  Invoices
/*-----------------------------------------------*/

export function subInvoices(handle) {

    return async dispatch => {

        dispatch({ type: types.GRAPH_INVOICES + '_PENDING' });

        var unsubscribe = firestore.collection(handle + '/graphs/graphs').doc('invoices').onSnapshot((doc) => {

            var invoices = doc.data()?.invoices ?? [];

            dispatch({ type: types.GRAPH_INVOICES + '_FULFILLED', data: invoices, unsubscribe });
        });

    };
}
