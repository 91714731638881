import * as types from 'actions/action-types';

var initialState = {
    graph_quotes: null,
    graph_quotes_pending: false,
    graph_quotes_save_pending: false,

    graph_invoices: null,
    graph_invoices_pending: false,
    graph_invoices_save_pending: false,
};

const graphsReducer = (state = initialState, action) => {

    switch (action.type) {
        case types.GRAPH_QUOTES + '_PENDING': pending('graph_quotes'); break;
        case types.GRAPH_QUOTES + '_SAVE_PENDING': save_pending('graph_quotes'); break;
        case types.GRAPH_QUOTES + '_FULFILLED': fulfilled('graph_quotes'); break;

        case types.GRAPH_INVOICES + '_PENDING': pending('graph_invoices'); break;
        case types.GRAPH_INVOICES + '_SAVE_PENDING': save_pending('graph_invoices'); break;
        case types.GRAPH_INVOICES + '_FULFILLED': fulfilled('graph_invoices'); break;
        
        default: break;
    }

    return state;

    function pending(table) {
        state = {
            ...state,
            [table + '_pending']: true,
        };
    }
    function fulfilled(table) {
        state = {
            ...state,
            [table]: action.data,
            [table + '_pending']: false,
            [table + '_save_pending']: false,
            [table + '_unsubscribe']: (action.unsubscribe) ? action.unsubscribe : state[table + '_unsubscribe'],
        };
    }
    function save_pending(table) {
        state = {
            ...state,
            [table + '_save_pending']: true,
        };
    }
};
export default graphsReducer;


