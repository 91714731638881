import * as types from 'actions/action-types';

var initialState = {
	customer: null,
	customer_map_coordinates: null,
	customer_pending: false,
	customer_save_pending: false,
	customer_unsubscribe: false,

	customer_search: null,
	customer_search_map_coordinates: null,
	customer_search_pending: false,
	customer_search_save_pending: false,
	customer_search_unsubscribe: false,

	address: null,
	address_pending: false,
	address_save_pending: false,

	contact: null,
	contact_pending: false,
	contact_save_pending: false,
};

const customersReducer = (state = initialState, action) => {
	switch(action.type) {
		case types.CUSTOMER + '_PENDING': pending('customer'); break;
		case types.CUSTOMER + '_SAVE_PENDING': save_pending('customer'); break;
		case types.CUSTOMER + '_SAVE_FULFILLED': save_fulfilled('customer'); break;
		case types.CUSTOMER + '_FULFILLED': fulfilled('customer'); break;
		case types.CUSTOMER + '_CLEAR': state = { ...state, customer: {} }; break;

		case types.CUSTOMER_SEARCH + '_PENDING': pending('customer_search'); break;
		case types.CUSTOMER_SEARCH + '_SAVE_PENDING': save_pending('customer_search'); break;
		case types.CUSTOMER_SEARCH + '_FULFILLED': fulfilled('customer_search'); break;
		case types.CUSTOMER_SEARCH + '_CLEAR': state = { ...state, customer_search: {} }; break;

		case types.ADDRESS + '_PENDING': pending('address'); break;
		case types.ADDRESS + '_SAVE_PENDING': save_pending('address'); break;
		case types.ADDRESS + '_FULFILLED': fulfilled('address'); break;
		case types.ADDRESS + '_CLEAR': state = { ...state, address: {} }; break;

		case types.CONTACT + '_PENDING': pending('contact'); break;
		case types.CONTACT + '_SAVE_PENDING': save_pending('contact'); break;
		case types.CONTACT + '_FULFILLED': fulfilled('contact'); break;
		case types.CONTACT + '_CLEAR': state = { ...state, contact: {} }; break;

		case types.CUSTOMER_MAPBOX_SEARCH + '_PENDING': pending('customer_map_coordinates'); break;
		case types.CUSTOMER_MAPBOX_SEARCH + '_FULFILLED': fulfilled('customer_map_coordinates'); break;
		
		case types.CUSTOMER_FROM_SEARCH :
			state = { ...state,
				customer: action.customer
			}; break;

		default : break;
	}

	return state;

	function pending(table) {
		state = { ...state,
			[table + '_pending']: true,
		};
	}
	function save_pending(table) {
		state = { ...state,
			[table + '_save_pending']: true,
		};
	}
	function save_fulfilled(table) {
		state = { ...state,
			[table + '_save_pending']: false,
		};
	}
	function fulfilled(table) {
		state = { ...state,
			[table]: action.data,
			[table + '_pending']: false,
			[table + '_save_pending']: false,
			[table + '_unsubscribe']: (action.unsubscribe) ? action.unsubscribe : state[table + '_unsubscribe'],
		};
	}
}
export default customersReducer;


