import React from 'react';
import { Ibox, Table } from 'enspire-manager-framework';
import { useSelector } from 'react-redux';
import { REQUESTS } from 'components/common/constants';
import * as toolbox from 'components/common/toolbox';
import { useHistory, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import _ from 'lodash';
import StatusKey from 'components/common/status-key';


const ProjectsSearch = () => {

    /* Hooks ----------------------------------------------------------------------------------------------------------------------------------------------------------------*/

    const history = useHistory();
    const location = useLocation();
    const params = useParams();

    const services = useSelector(store => store?.services);

    /* Handlers ----------------------------------------------------------------------------------------------------------------------------------------------------------------*/

    const handleClick = (item) => {
        let newLocation = toolbox.modifyPath(location.pathname, 3, 'projects/' + item.id);

        let segments = location.pathname.split('/').length;

        if (segments == 4) history.push({ pathname: newLocation });
        else history.replace({ pathname: newLocation });
    };
    /* Constants ----------------------------------------------------------------------------------------------------------------------------------------------------------------*/

    const columns = [
        { name: 'ID', field: 'customId', width: 14 },
        { name: 'Requested', field: 'created', type: 'date', format: 'MMM Do, YYYY', width: 23 },
        { name: 'Summary', field: 'summary', width: 63 },
    ];

    const statusStyle = { position: 'absolute', top: '-36px', left: 0, right: 0, marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' };

    var projects = [];
    services?.projects?.forEach((request) => {
        let status = _.find(Object.values(REQUESTS), { id: request.statusId });
        if (status) projects.push({ ...request, status: status.name, _stripe_color: status.color });
    });

    /* Render ----------------------------------------------------------------------------------------------------------------------------------------------------------------*/
    return (
        <Ibox
            title={'Projects'}
        >
            <StatusKey
                style={statusStyle}
                statusTypes={Object.values(REQUESTS)}
            />
            <Table
                active_field={'id'}
                active_id={params.project_id}
                columns={columns}
                data={projects}
                click_callback={handleClick}
            />
        </Ibox>
    );
};

export default ProjectsSearch;