import "react-datepicker/dist/react-datepicker.css";
import * as actions_work_orders from 'actions/work-orders-actions';
import * as actions_users from 'actions/users-actions';
import * as toolbox from 'components/common/toolbox';
import DatePicker from "react-datepicker";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Map, { FullscreenControl, GeolocateControl, Marker, NavigationControl, Layer, Source } from 'react-map-gl';
import React, { useEffect, useState } from 'react';
import { Ibox, Avatar } from 'enspire-manager-framework';
import { useHistory } from 'react-router-dom';
import { useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector, useDispatch } from 'react-redux';
import moment from "moment";
import { Badge } from "react-bootstrap";
import _ from "lodash";

export default function EmployeeMap(props) {

    /* Hooks ----------------------------------------------------------------------------------------------------------------------------------------------------------------*/

    const dispatch = useDispatch();
    const params = useParams();
    const location = useLocation();
    const history = useHistory();
    const mapRef = React.useRef();

    const employees = useSelector((store) => store.employees);
    const workOrders = useSelector((store) => store.workOrders);
    const work_orders_unassigned = useSelector((store) => store.workOrders.work_orders_unassigned);
    const company = useSelector((store) => store.company);
    const users = useSelector((store) => store.users);

    const selectedDate = (params.employee_id == 'all') ? moment() : moment(params.date, 'YYYY-MM-DD');
    const user_locations = users.user_locations;
    const employee = employees.employees?.find((employee) => employee.id == params.employee_id);
    const currentLocation = (moment(employee?.location?.timestamp?.toDate()).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD') && selectedDate.toDate().getDate() == moment().toDate().getDate()) && employee?.location;

    /* States ----------------------------------------------------------------------------------------------------------------------------------------------------------------*/

    const [cursor, setCursor] = useState('auto');
    const [markers, setMarkers] = useState([]);
    const [startMarker, setStartMarker] = useState(null);
    const [selectedMarker, setSelectedMarker] = useState(null);
    const [currentLocationMarkers, setCurrentLocationMarkers] = useState([]);
    const [focalPoint, setFocalPoint] = useState(null);
    const [stillData, setStillData] = useState(null);
    const [stillMarkers, setStillMarkers] = useState(null);
    const [gpsCoordinates, setGpsCoordinates] = useState(null);
    const [viewport, setViewport] = useState({
        latitude: company?.company?.geopoint?.latitude ?? 39.833333,
        longitude: company?.company?.geopoint?.longitude ?? -98.583333,
        zoom: 11,
    });
    const [rendering, setRendering] = useState(false);

    /* Effects ----------------------------------------------------------------------------------------------------------------------------------------------------------------*/

    // Fetch incomplete work orders for locations
    useEffect(() => {
        setRendering(true);
        dispatch(actions_work_orders.subIncompleteWorkOrders(params.handle, (params.employee_id == 'all') ? moment() : selectedDate, null, (params.employee_id == 'all') ? null : params.employee_id));

        return () => {
            let unsubscribe = workOrders.work_orders_incomplete_unsubscribe;
            if (typeof unsubscribe === 'function') unsubscribe();
        };
    }, [params.employee_id, employees.employees, params.date]);


    // Fetch Employee locations for this date
    useEffect(() => {
        dispatch(actions_users.subUserLocationsByDate(params.handle, 'joelfunkhouser@gmail.com', (selectedDate.toDate())));
        // dispatch(actions_users.subUserLocationsByDate(params.handle, employee?.contact?.email, (selectedDate.toDate())));

        return () => {
            let unsubscribe = users.user_locations_unsubscribe;
            if (typeof unsubscribe === 'function') unsubscribe();
        };
    }, [params.employee_id, employee, params.date]);

    // Once incomeplete work orders are fetched, set markers
    useEffect(() => {

        // Set Incomplete Work Order Markers
        var newMarkers = incompleteWorkOrders?.filter((wo) => wo.id != focalPoint?.id && wo.geopoint)?.map((workOrder) => {
            // if (!workOrder.geopoint.latitude || !workOrder.geopoint.longitude) return null;
            return (
                <div key={workOrder.id} onMouseEnter={() => setCursor('pointer')} onMouseLeave={() => setCursor('auto')}>
                    <Marker
                        // color={'purple'}
                        draggable={false}
                        key={workOrder.id}
                        longitude={workOrder?.geopoint?.longitude}
                        latitude={workOrder?.geopoint?.latitude}
                        anchor="bottom"
                        onClick={(e) => {
                            e.originalEvent.stopPropagation();
                            setFocalPoint({ id: workOrder.id, longitude: workOrder?.geopoint?.longitude, latitude: workOrder?.geopoint?.latitude });
                        }}
                    />
                </div>
            );
        });

        // Set Unassigned Work Order Markers
        if (work_orders_unassigned.length) {
            work_orders_unassigned?.filter((wo) => wo.id != focalPoint?.id && wo.geopoint)?.forEach((workOrder) => {
                if (workOrder?.geopoint) {
                    newMarkers.push(<div key={workOrder.id} onMouseEnter={() => setCursor('pointer')} onMouseLeave={() => setCursor('auto')}>
                        <Marker
                            color={'red'}
                            draggable={false}
                            key={workOrder.id}
                            longitude={workOrder?.geopoint?.longitude}
                            latitude={workOrder?.geopoint?.latitude}
                            anchor="bottom"
                            onClick={(e) => {
                                e.originalEvent.stopPropagation();
                                setFocalPoint({ id: workOrder.id, longitude: workOrder?.geopoint?.longitude, latitude: workOrder?.geopoint?.latitude });
                            }}
                        />
                    </div>);
                }
            });
        }
        setMarkers(newMarkers);

        // Set Selected Marker
        const selectedWorkOrder = incompleteWorkOrders?.find((wo) => wo.id == focalPoint?.id) || work_orders_unassigned?.find((wo) => wo.id == params.tab_id);
        if (selectedWorkOrder?.geopoint) setSelectedMarker(<div onMouseEnter={() => setCursor('pointer')} onMouseLeave={() => setCursor('auto')}>
            <Marker
                color={'gold'}
                // scale={1.25} //In case you wanted to change scale as well
                draggable={false}
                key={selectedWorkOrder.id}
                longitude={selectedWorkOrder?.geopoint?.longitude}
                latitude={selectedWorkOrder?.geopoint?.latitude}
                anchor="bottom"
                onClick={() => handleClick(selectedWorkOrder)}
            />
        </div>);
        else setSelectedMarker(null);
    }, [workOrders.work_orders_incomplete.data, params.tab_id, params.date, work_orders_unassigned, focalPoint]);

    // Once markers are set, fit bounds
    useEffect(() => {
        !rendering && fitBounds();
    }, [rendering, params.tab_id]);

    // If focal point is selected, fit bounds to focal point
    useEffect(() => {
        if (focalPoint) {
            fitBounds();
        }
    }, [focalPoint]);

    // Set Current location Markers
    useEffect(() => {
        if (currentLocation && currentLocation) setCurrentLocationMarkers([<div onMouseEnter={() => setCursor('pointer')} onMouseLeave={() => setCursor('auto')}>
            <Marker
                draggable={false}
                key={'currentLocation'}
                longitude={currentLocation?.lng}
                latitude={currentLocation?.lat}
                anchor="bottom"
            >
                <Avatar
                    className="mx-auto"
                    color="white"
                    bgColor="darkGreen"
                    size={50}
                    fontSize={'21'}
                    name={employee?.contact?.firstName + " " + employee?.contact?.lastName}
                    image={employee?.contact?.photoUrl ? employee?.contact?.photoUrl : null}
                    border="4px solid white"
                />
            </Marker>
        </div>]);
        else if (params.employee_id == 'all') {
            const activeEmployees = employees.employees?.filter((employee) => employee?.location?.timestamp?.toDate().getDate() == moment().toDate().getDate() && selectedDate.toDate().getDate() == moment().toDate().getDate());

            setCurrentLocationMarkers(activeEmployees.map((employee) =>
                <div onMouseEnter={() => setCursor('pointer')} onMouseLeave={() => setCursor('auto')}>
                    <Marker
                        draggable={false}
                        key={'currentLocation'}
                        longitude={employee?.location?.lng}
                        latitude={employee?.location?.lat}
                        anchor="bottom"
                    >
                        <Avatar
                            className="mx-auto"
                            color="white"
                            bgColor="darkGreen"
                            size={50}
                            fontSize={'21'}
                            name={employee?.contact?.firstName + " " + employee?.contact?.lastName}
                            image={employee?.contact?.photoUrl ? employee?.contact?.photoUrl : null}
                            border="4px solid white"
                        />
                    </Marker>
                </div>
            ));
        } else setCurrentLocationMarkers([]);
    }, [currentLocation, params.employee_id, employees.employees]);

    // Set Location Path
    useEffect(() => {
        // Need to wrap in async function to utilize Snap To Map
        const fetchData = async () => {
            try {
                const coordinatesArray = [];
                var activityArray = [];

                // Separate trip into separate arrays by activity
                var activity = user_locations?.[0]?.activity;
                user_locations?.forEach((coordinate, index) => {
                    // If activity changes, push array to coordinatesArray (except if in_vehicle activity only occurs once consecutively)
                    if (index == user_locations?.length - 1 || coordinate.activity != activity && activity == 'in_vehicle' || activityArray.length == 99 || activity != 'in_vehicle' && coordinate.activity == 'in_vehicle' && user_locations[index + 1]?.activity == 'in_vehicle') {
                        activityArray.push(coordinate);
                        activity = coordinate.activity;
                        coordinatesArray.push(activityArray);
                        activityArray = [];
                    }

                    activityArray.push(coordinate);
                });

                if (coordinatesArray.length) {
                    const layers = [];
                    const stillArray = [];
                    const stillData = [];
                    var still;

                    var stillIndex = 1;
                    // Create layers(lines) for each activity
                    for (let i = 0; i < coordinatesArray.length; i++) {
                        const coordinates = coordinatesArray[i];
                        var formattedCoordinates = coordinates.map((coordinate) => [coordinate.lng, coordinate.lat]);

                        // Snap to road
                        // if (coordinates[0].activity == 'in_vehicle') {
                        //     const coordinatesString = formattedCoordinates.map((coordinate) => coordinate.join(',')).join(';');

                        //     const response = await axios.get(`https://api.mapbox.com/matching/v5/mapbox/driving/${coordinatesString}?access_token=pk.eyJ1IjoiYmxha2Vjb2RleiIsImEiOiJja2twemEyZ3ozMXZtMnVudzh6ajRkdG5wIn0.ZJ6dJHj6rJbOrDiBUuY2MA`);
                        //     const snapToRoadCoordinates = response.data;

                        //     formattedCoordinates = snapToRoadCoordinates.tracepoints.map((tracepoint, index) => (index == 0 || index == snapToRoadCoordinates.tracepoints.length - 1 || !tracepoint?.location) ? formattedCoordinates[index] : tracepoint.location);
                        // }

                        // If first coordinate, add start marker
                        if (i == 0) {
                            stillData.push({ index: 'start', longitude: coordinates[0].lng, latitude: coordinates[0].lat });
                            setStartMarker(<div onMouseEnter={() => setCursor('pointer')} onMouseLeave={() => setCursor('auto')}>
                                <Marker
                                    draggable={false}
                                    key={'startMarker'}
                                    longitude={coordinates[0].lng}
                                    latitude={coordinates[0].lat}
                                    anchor="top"
                                    onClick={(e) => {
                                        e.originalEvent.stopPropagation();
                                        setFocalPoint({ index: 'start', longitude: coordinates[0].lng, latitude: coordinates[0].lat });
                                    }}
                                    captureClick={true}
                                >
                                    <div
                                        style={{
                                            backgroundColor: '#007BFF', // Customize the background color
                                            color: '#fff', // Customize the text color
                                            borderRadius: '50%',
                                            width: '40px', // Set the width and height to make it a circle
                                            height: '40px', // Set the width and height to make it a circle
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            fontSize: '12px', // Customize the font size
                                            textAlign: 'center',
                                            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)', //
                                        }}
                                    >
                                        Start
                                    </div>
                                </Marker>
                            </div>);
                        }

                        // If still, add still marker
                        if (!still && coordinates[0].activity == 'in_vehicle' && coordinates[coordinates.length - 1].activity != 'in_vehicle') {
                            still = coordinates[coordinates.length - 1];
                        } else if (still && (coordinates[0].activity == 'in_vehicle' || i == coordinatesArray.length - 1)) {
                            // Calculate still time
                            var stillTime = moment(coordinates[0].timestamp.toDate()).diff(moment(still.timestamp.toDate()), 'minutes');
                            stillTime = getTime(stillTime);
                            if (stillTime) {
                                const stillCopy = _.cloneDeep(still);
                                const stillIndexCopy = stillIndex;

                                stillData.push({ index: stillIndexCopy, longitude: stillCopy.lng, latitude: stillCopy.lat });

                                stillArray.push(<div onMouseEnter={() => setCursor('pointer')} onMouseLeave={() => setCursor('auto')}>
                                    <Marker
                                        draggable={false}
                                        key={`stillMarker${i}`}
                                        longitude={still.lng}
                                        latitude={still.lat}
                                        anchor="bottom"
                                        onClick={(e) => {
                                            e.originalEvent.stopPropagation();
                                            setFocalPoint({ index: stillIndexCopy, longitude: stillCopy.lng, latitude: stillCopy.lat });
                                        }}
                                    >
                                        <div className="d-flex justify-content-center">
                                            <span className={'circle my-auto'}>
                                                {stillIndex}
                                            </span>
                                            <div className="my-auto ml-1">
                                                <Badge style={{ backgroundColor: 'green', color: 'white', fontSize: '15px' }}>{stillTime}</Badge>
                                            </div>

                                        </div>
                                    </Marker>
                                </div>);
                                stillIndex += 1;
                            }

                            if (coordinates[0].activity == 'in_vehicle' && coordinates[coordinates.length - 1].activity != 'in_vehicle') {
                                still = coordinates[coordinates.length - 1];
                            } else {
                                still = null;
                            }
                        }

                        const dataOne = {
                            type: "Feature",
                            properties: {},
                            geometry: {
                                type: "LineString",
                                coordinates: formattedCoordinates
                            }
                        };

                        const layer = (
                            <Source id={`polylineLayer${i}`} key={`polylineLayer${i}`} type="geojson" data={dataOne}>
                                <Layer
                                    id={"lineLayer" + i}
                                    key={"lineLayer" + i}
                                    type="line"
                                    source="my-data"
                                    layout={{
                                        "line-join": "round",
                                        "line-cap": "round"
                                    }}
                                    paint={{
                                        "line-color": (coordinates[0].activity == 'in_vehicle') ? '#8FBC8B' : '#aaaaaa',
                                        "line-width": (coordinates[0].activity == 'in_vehicle') ? 6 : 4,
                                        'line-dasharray': (coordinates[0].activity == 'in_vehicle') ? [] : [1, 3],
                                    }}
                                />
                            </Source>
                        );
                        layers.push(layer);
                    }
                    setStillMarkers(stillArray);
                    setStillData(stillData);
                    setGpsCoordinates(layers);
                } else {
                    setStillMarkers(null);
                    setGpsCoordinates(null);
                    setStartMarker(null);
                    setStillData(null);
                }
            } catch (error) {
                window.toastr.error(error);
            }
        };

        fetchData();
        setRendering(false);
    }, [user_locations]);

    /* Handlers ----------------------------------------------------------------------------------------------------------------------------------------------------------------*/

    const handleClick = (workOrder) => {
        let newLocation = toolbox.modifyPath(location.pathname, 6, workOrder.id);

        history.replace({ pathname: newLocation });
    };

    const changeDate = (action, date) => {
        setFocalPoint(null);
        setSelectedMarker(null);

        if (action == 'select') {
            history.replace({ pathname: toolbox.modifyPath(location.pathname, 5, moment(date).format('YYYY-MM-DD'), 5) });
        } else if (action == 'next') {
            history.replace({ pathname: toolbox.modifyPath(location.pathname, 5, selectedDate.add(1, 'day').format('YYYY-MM-DD'), 5) });
        } else {
            history.replace({ pathname: toolbox.modifyPath(location.pathname, 5, selectedDate.subtract(1, 'day').format('YYYY-MM-DD'), 5) });
        }
    };

    /* Actions ----------------------------------------------------------------------------------------------------------------------------------------------------------------*/

    const getTime = (min) => {
        const hours = Math.floor(min / 60);
        const minutes = min % 60;
        return `${(hours) ? hours + 'h' : ''}${(minutes) ? ' ' + minutes + 'm' : ''}`;
    };

    const fitBounds = () => {
        if (mapRef.current) {

            var activeEmployees = employees.employees?.filter((employee) => employee?.location?.timestamp?.toDate().getDate() == moment().toDate().getDate());
            if (params.employee_id != 'all') activeEmployees = activeEmployees.filter((employee) => employee.id == params.employee_id);

            var markerLongitudes =
                // If focal point, center on that
                (focalPoint) ? [focalPoint.longitude]
                    // Else if selected marker, center on that
                    : (selectedMarker)
                        ? [incompleteWorkOrders?.find((wo) => wo.id == params.tab_id)?.geopoint?.longitude || work_orders_unassigned.find((wo) => wo.id == params.tab_id)?.geopoint?.longitude]
                        // Else, if no work orders && no locations
                        : ((incompleteWorkOrders?.length == 0 || !incompleteWorkOrders) && !user_locations.length)
                            // If all employees, center around employees
                            ? (params.employee_id == "all" && activeEmployees.length > 0)
                                ? []
                                // Else, center on company
                                : [COMPANY_LOCATION.longitude]
                            // Else, center on all work orders
                            : incompleteWorkOrders?.map((workOrder) => workOrder?.geopoint?.longitude);



            // If no work orders, center on company
            var markerLatitudes =
                (focalPoint) ? [focalPoint.latitude]
                    // If selected marker, center on that
                    : (selectedMarker)
                        ? [incompleteWorkOrders?.find((wo) => wo.id == params.tab_id)?.geopoint?.latitude || work_orders_unassigned.find((wo) => wo.id == params.tab_id)?.geopoint?.latitude]
                        // Else, if no work orders, center on company
                        : ((incompleteWorkOrders?.length == 0 || !incompleteWorkOrders) && !user_locations.length)
                            // If all employees, center around employees
                            ? (params.employee_id == "all" && activeEmployees.length > 0)
                                ? []
                                // Else, center on company
                                : [COMPANY_LOCATION.latitude]
                            // Else, center on all work orders
                            : incompleteWorkOrders?.map((workOrder) => workOrder?.geopoint?.latitude);

            if (user_locations.length && !selectedMarker && !focalPoint) user_locations.forEach((location) => {
                markerLongitudes.push(location.lng);
                markerLatitudes.push(location.lat);
            });

            if (activeEmployees.length > 0 && (params.employee_id == "all" || params.date == moment().format('YYYY-MM-DD'))) {
                activeEmployees.forEach((employee) => {
                    markerLongitudes.push(employee?.location?.lng);
                    markerLatitudes.push(employee?.location?.lat);
                });
            }

            // Calculate the bounds of the markers
            const minLng = Math.min(...markerLongitudes);
            const maxLng = Math.max(...markerLongitudes);
            const minLat = Math.min(...markerLatitudes);
            const maxLat = Math.max(...markerLatitudes);
            var options = { padding: 100, duration: 2500, maxZoom: 15 };

            // If no company location, zoom out to see continental US
            if (!company?.company?.geopoint && !incompleteWorkOrders?.length) options.zoom = 3;

            // Fit bounds
            mapRef.current.fitBounds(
                [
                    [minLng, minLat],
                    [maxLng, maxLat]
                ],
                options
            );
        }
    };

    /* Constants ----------------------------------------------------------------------------------------------------------------------------------------------------------------*/

    const incompleteWorkOrders = workOrders.work_orders_incomplete.data?.filter((wo) => wo.geopoint && wo.endDate.toDate().getDate() == selectedDate.toDate().getDate());

    // If no company location, center on continental US
    const COMPANY_LOCATION = {
        latitude: company?.company?.geopoint?.latitude ?? 39.833333,
        longitude: company?.company?.geopoint?.longitude ?? -98.583333,
    };

    const CalendarIcon = React.forwardRef(
        ({ value, onClick }, ref) => (
            <OverlayTrigger placement={'top'} delay={100} overlay={<Tooltip>Set Date</Tooltip>}>
                <i className="far fa-calendar-alt" onClick={onClick} style={{ fontSize: '20px', cursor: 'pointer', color: '#666666' }}></i>
            </OverlayTrigger>
        )
    );

    const Today =
        <>
            <i className="far fa-calendar-alt" style={{ fontSize: '20px', color: '#666666' }}></i>
            <h5 style={{ marginLeft: '20px' }} className={'my-auto'}>
                Today
                {rendering && <i className={"fa fa-lg fa-spinner fa-spin ml-2"} aria-hidden="true"></i>}            </h5>
        </>;

    const Title =
        <>
            <div style={{ position: 'absolute', zIndex: 2, width: '100px' }}>
                <DatePicker
                    style={{ position: 'absolute', left: 0 }}
                    selected={selectedDate.toDate()}
                    onChange={(date) => changeDate('select', date)}
                    customInput={<CalendarIcon />}
                />
            </div>
            <h1 style={{ position: 'absolute', top: '3px', left: '45px', zIndex: '100' }}>
                <OverlayTrigger placement={'top'} delay={300} overlay={<Tooltip>Previous</Tooltip>}>
                    <i className="fas fa-chevron-left" style={{ color: 'rgb(187, 187, 187)', padding: '5px', cursor: 'pointer' }} onClick={() => changeDate('previous')}></i>
                </OverlayTrigger>
                <OverlayTrigger placement={'top'} delay={300} overlay={<Tooltip>Next</Tooltip>}>
                    <i className="fas fa-chevron-right" style={{ color: 'rgb(187, 187, 187)', padding: '5px', cursor: 'pointer' }} onClick={() => changeDate('next')}></i>
                </OverlayTrigger>
            </h1>
            <h5 style={{ marginLeft: '90px' }} className={'my-auto'}>
                {selectedDate.format('dddd, D MMMM, YYYY')}
                {rendering && <i className={"fa fa-lg fa-spinner fa-spin ml-2"} aria-hidden="true"></i>}
            </h5>
            {focalPoint && focalPoint.index &&
                <h1 style={{ position: 'absolute', top: '3px', left: '350px', zIndex: '100' }} className="d-flex">
                    <div className={`py-auto ${(focalPoint.index == 'start') ? 'start-marker-title' : 'circle'}`}>
                        {(focalPoint.index == 'start')
                            ? 'Start'
                            : focalPoint.index
                        }
                    </div>

                    <OverlayTrigger placement={'top'} delay={300} overlay={<Tooltip>Previous</Tooltip>}>
                        <i
                            className="fas fa-chevron-left"
                            style={{ color: (focalPoint.index != 'start') ? 'rgb(187, 187, 187)' : 'rgba(187, 187, 187, .5)', padding: '5px', cursor: (focalPoint.index != 'start') && 'pointer' }}
                            onClick={() => (focalPoint.index != 'start') && setFocalPoint(stillData.find((sd) => sd.index == ((focalPoint.index == 1) ? 'start' : focalPoint.index - 1)))}
                        />
                    </OverlayTrigger>
                    <OverlayTrigger placement={'top'} delay={300} overlay={<Tooltip>Next</Tooltip>}>
                        <i
                            className="fas fa-chevron-right"
                            style={{ color: (focalPoint.index != stillData[stillData.length - 1].index) ? 'rgb(187, 187, 187)' : 'rgba(187, 187, 187, .5)', padding: '5px', cursor: (focalPoint.index != stillData[stillData.length - 1].index) && 'pointer' }}
                            onClick={() => (focalPoint.index != stillData[stillData.length - 1].index) && setFocalPoint(stillData.find((sd) => sd.index == ((focalPoint.index == 'start') ? 1 : focalPoint.index + 1)))}
                        />
                    </OverlayTrigger>
                </h1>
            }
        </>;

    /* Render ----------------------------------------------------------------------------------------------------------------------------------------------------------------*/
    return (
        <>
            <Ibox className="mt-3" title={(params.employee_id == 'all') ? Today : Title}>
                <Map
                    {...viewport}
                    ref={mapRef}
                    onClick={() => setFocalPoint(null)}
                    onDrag={() => setFocalPoint(null)}
                    // onWheel={() => setFocalPoint(null)}
                    onMove={evt => setViewport(evt.viewState)}
                    mapboxAccessToken="pk.eyJ1IjoiYmxha2Vjb2RleiIsImEiOiJja2twemEyZ3ozMXZtMnVudzh6ajRkdG5wIn0.ZJ6dJHj6rJbOrDiBUuY2MA"
                    style={{ height: 'calc(100vh - 230px)' }}
                    mapStyle="mapbox://styles/mapbox/streets-v9"
                    cursor={cursor}
                >
                    <GeolocateControl position="top-left" />
                    <FullscreenControl position="top-right" />
                    <NavigationControl position="bottom-right" />
                    {!rendering && startMarker}
                    {!rendering && gpsCoordinates}
                    {!rendering && stillMarkers}
                    {!rendering && markers}
                    {!rendering && selectedMarker}
                    {!rendering && currentLocationMarkers}
                </Map>
            </Ibox>
        </>
    );
}
