import * as toolbox from '../components/common/toolbox';
import * as types from './action-types';
import firebase from 'firebase/compat/app';
import firebaseAssets from 'firebase-assets';

const firestore = firebase.firestore();
const firestoreAssets = firebaseAssets.firestore();

/*-----------------------------------------------*/
/*  SETTINGS
/*-----------------------------------------------*/

export function subSettings(handle) {

	return async dispatch => {

		dispatch({ type: types.SETTINGS + '_PENDING' });

		var unsubscribe = firestore.collection(handle).doc('settings').onSnapshot(async (doc) => {
			var settings = doc.data();
			dispatch({ type: types.SETTINGS + '_FULFILLED', data: settings, unsubscribe });
		});

		dispatch({ type: types.SETTINGS_VERSION + '_PENDING' });

		var unsubscribe = firestore.collection('_mobiletrack').doc('settings').onSnapshot((doc) => {
			var version = doc.data();
			dispatch({ type: types.SETTINGS_VERSION + '_FULFILLED', data: version, unsubscribe });
		});

		dispatch({ type: types.SETTINGS_COMPANY + '_PENDING' });

		// The following subscriptions combine into one store.
		// The reason for 2 subscriptions is to update the store when either 'company' or 'company_settings' change.
		var unsubscribe = firestore.collection('_companies').doc(handle).onSnapshot(async (doc) => {
			var settings = doc.data();

			const document = await firestore.collection(handle).doc('company').get();
			settings = { ...settings, ...document.data() };

			dispatch({ type: types.SETTINGS_COMPANY + '_FULFILLED', data: settings, unsubscribe });
		});
		var unsubscribe = firestore.collection(handle).doc('company').onSnapshot(async (doc) => {
			var settings = doc.data();

			const document = await firestore.collection('_companies').doc(handle).get();
			settings = { ...settings, ...document.data() };

			dispatch({ type: types.SETTINGS_COMPANY + '_FULFILLED', data: settings, unsubscribe });
		});

		// const snapshot = await firestoreAssets.collection('testing/assets/assets').get();
		// let assets = [];
		// snapshot.forEach((doc) => {
		// 	assets.push({ ...doc.data(), id: doc.id });
		// });
		
	};
}
export function updateSettings(handle, record, callback) {

	return dispatch => {

		let isDualMonitorSettings = ('dualMonitors' in record || 'dualMonitors' in record || 'dualMonitor1Url' in record || 'dualMonitor2Url' in record);

		if (record) {
			dispatch({ type: types.SETTINGS + '_SAVE_PENDING' });

			firestore.collection(handle).doc('settings').set(record, { merge: true }).then(() => {
				dispatch({ type: types.SETTINGS + '_SAVE_FULFILLED' });
				if (!isDualMonitorSettings) window.toastr.success('Settings have been saved successfully!', 'Settings Saved!');

				if (isDualMonitorSettings) clearUrlSettings();
				if (typeof callback === 'function') callback();

			}).catch((error) => {
				toolbox.process_error(error, 'Settings NOT Saved!');
			});
		}

		function clearUrlSettings() {
			setTimeout(() => {
				firestore.collection(handle).doc('settings').set({ dualMonitor1Url: '', dualMonitor2Url: '' }, { merge: true }).then(() => {
					dispatch({ type: types.SETTINGS + '_SAVE_FULFILLED' });
				}).catch((error) => {
					toolbox.process_error(error, 'Settings NOT Saved!');
				});
			}, 1500);
		}
	};
}
export function updateCompanySettings(handle, company, sms) {

	return dispatch => {

		dispatch({ type: types.SETTINGS_COMPANY + '_SAVE_PENDING' });

		var batch = firestore.batch();

		batch.set(firestore.collection(handle).doc('company'), sms, { merge: true });
		batch.set(firestore.collection('_companies').doc(handle), company, { merge: true });

		batch.commit().then(() => {
			dispatch({ type: types.SETTINGS_COMPANY + '_SAVE_FULFILLED' });

			window.toastr.success('Settings have been saved successfully!', 'Settings Saved!');
		}).catch((error) => {
			toolbox.process_error(error, 'Settings NOT Saved!');
		});
	};
}
export function setDualMonitorMode(number, active) {

	return dispatch => {

		dispatch({ type: types.DUAL_MONITORS, number, active });
	};
}
export function toggleMenuCollapsed() {

	return dispatch => {

		dispatch({ type: types.SETTINGS_TOGGLE_COLLAPSED });
	};
}
export function toggleStatusModal(statusTable, statusId, currentId, confirmCallback) {

	return dispatch => {

		dispatch({ type: types.SETTINGS_TOGGLE_STATUS_MODAL, statusTable, statusId, currentId, confirmCallback });
	};
}
export function updateStatusModal(statusId) {

	return dispatch => {

		dispatch({ type: types.SETTINGS_UPDATE_STATUS_MODAL, statusId });
	};
}

/*-----------------------------------------------*/
/*  DEPARTMENTS
/*-----------------------------------------------*/

export function subDepartments(handle) {

	return async dispatch => {

		dispatch({ type: types['DEPARTMENTS'] + '_PENDING' });

		var unsubscribe = firestore.collection(handle + '/settings/departments').onSnapshot((snapshot) => {
			var departments = [];
			snapshot.docs.forEach((doc) => {
				departments.push({ ...doc.data(), id: doc.id });
			});
			dispatch({ type: types['DEPARTMENTS'] + '_FULFILLED', data: departments, unsubscribe });
		});
	};
}

export function saveDepartment(handle, newDepartment, departmentId, silent, callback) {

	return async dispatch => {

		dispatch({ type: types.DEPARTMENTS + '_SAVE_PENDING' });

		var batch = firestore.batch();

		if (!departmentId) {
			departmentId = await nextDepartmentId(handle, batch);
		}

		batch.set(firestore.collection(handle + "/settings/departments").doc(departmentId), newDepartment, { merge: true });
		batch.commit().then((doc) => {
			dispatch({ type: types.DEPARTMENTS + '_SAVE_FULFILLED' });
			if (!silent) window.toastr.success('The Department has been successfully saved/updated', 'Department Saved!');
			if (typeof callback == "function") callback(departmentId);
		});
	};
}
export function updateDepartmentPositions(handle, departments) {

	return async dispatch => {

		dispatch({ type: types.DEPARTMENTS + '_SAVE_PENDING' });

		var batch = firestore.batch();
		const ref = firestore.collection(handle + "/settings/departments");
		departments.forEach((department) => batch.update(ref.doc(department.id), { position: department.position, value: department.value }));

		batch.commit().then((doc) => {
			dispatch({ type: types.DEPARTMENTS + '_SAVE_FULFILLED' });
			window.toastr.success('The Departments have been successfully saved/updated', 'Departments Saved!');
		});
	};
}

async function nextDepartmentId(handle, batch) {
	const table = 'settings';
	const field = 'nextDepartmentId';
	const startingId = 100;

	return toolbox.nextId(handle, batch, table, field, startingId);
}

/*-----------------------------------------------*/
/*  JOB CATEGORIES
/*-----------------------------------------------*/

export function saveJobCategory(handle, jobCategory, id, callback) {

	return async dispatch => {

		dispatch({ type: types.DEPARTMENTS + '_SAVE_PENDING' });

		var batch = firestore.batch();

		if (!id) id = await nextJobCategoryId(handle, batch);

		const ref = firestore.collection(handle + '/settings/job-categories').doc(id);

		batch.set(ref, jobCategory, { merge: true });
		batch.commit().then(() => {
			dispatch({ type: types.DEPARTMENTS + '_SAVE_FULFILLED' });
			window.toastr.success(`The Job Category has been successfully saved/updated`, 'Job Category Saved!');
			if (typeof callback === 'function') callback(id);
		});
	};
}
export function subJobCategories(handle) {

	return dispatch => {

		dispatch({ type: types.JOB_CATEGORIES + '_PENDING' });

		var unsubscribe = firestore.collection(handle + "/settings/job-categories").onSnapshot((snapshot) => {
			var jobCategories = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
			dispatch({ type: types.JOB_CATEGORIES + '_FULFILLED', data: jobCategories, unsubscribe });
		});
	};
}

/*-----------------------------------------------*/
/*  Department Tags
/*-----------------------------------------------*/

export function subDepartmentTags(handle) {

	return dispatch => {

		dispatch({ type: types.DEPARTMENT_TAGS + '_PENDING' });

		var unsubscribe = firestore.collection(handle + "/settings/departmentTags").onSnapshot((snapshot) => {
			var departmentTags = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
			dispatch({ type: types.DEPARTMENT_TAGS + '_FULFILLED', data: departmentTags, unsubscribe });
		});
	};
}

export function saveTag(handle, tag, id, callback) {

	return async dispatch => {

		dispatch({ type: types.DEPARTMENT_TAGS + '_SAVE_PENDING' });

		var batch = firestore.batch();

		if (!id) id =  await nextDepartmentTagId(handle, batch);

		const stockPartsRef = firestore.collection(handle + '/settings/departmentTags').doc(id);
		batch.set(stockPartsRef, tag, { merge: true });
		batch.commit().then(() => {
			dispatch({ type: types.DEPARTMENT_TAGS + '_SAVE_FULFILLED' });
			window.toastr.success(`The Tag has been successfully saved/updated`, 'Tag Saved!');
			if (typeof callback == "function") callback(id);
		});
	};
}
export function deleteTag(handle, tagId) {

	return async dispatch => {

		dispatch({ type: types.DEPARTMENT_TAGS + '_SAVE_PENDING' });

		var batch = firestore.batch();

		const ref = firestore.collection(handle + '/settings/departmentTags').doc(tagId);
		batch.delete(ref);
		batch.commit().then(() => {
			dispatch({ type: types.DEPARTMENT_TAGS + '_SAVE_FULFILLED' });
			window.toastr.success(`The Tag has been successfully removed`, 'Tag Deleted!');
		});
	};
}



async function nextDepartmentTagId(handle, batch) {
	const table = 'settings';
	const field = 'nextDepartmentTagId';
	const startingId = 4000;

	return toolbox.nextId(handle, batch, table, field, startingId);
}
async function nextJobCategoryId(handle, batch) {
	const table = 'settings';
	const field = 'nextJobCategoryId';
	const startingId = 10000;

	return toolbox.nextId(handle, batch, table, field, startingId);
}

/*-----------------------------------------------*/
/*  LOCAL STATES
/*-----------------------------------------------*/

export function updateButton(button, update) {

	return async dispatch => {
		dispatch({ type: types.UPDATE_BUTTON, button, update });
	};
}
