
import * as types from 'actions/action-types';

var initialState = {
    employee: null,
    employee_pending: false,
    employee_save_pending: false,
    employee_unsubscribe: false,

    employees: [],
    employees_pending: false,
    employees_save_pending: false,
    employees_unsubscribe: false,

    address: null,
	address_pending: false,
	address_save_pending: false,
};

const EmployeesReducer = (state = initialState, action) => {

    switch (action.type) {
        case types.EMPLOYEE + '_PENDING': pending('employee'); break;
        case types.EMPLOYEE + '_SAVE_PENDING': save_pending('employee'); break;
        case types.EMPLOYEE + '_SAVE_FULFILLED': save_fulfilled('employee'); break;
        case types.EMPLOYEE + '_FULFILLED': fulfilled('employee'); break;
        case types.EMPLOYEE + '_CLEAR': clear('employee', {}); break;

        case types.EMPLOYEES + '_PENDING': pending('employees'); break;
        case types.EMPLOYEES + '_SAVE_PENDING': save_pending('employees'); break;
        case types.EMPLOYEES + '_SAVE_FULFILLED': save_fulfilled('employees'); break;
        case types.EMPLOYEES + '_FULFILLED': fulfilled('employees'); break;
        case types.EMPLOYEES + '_CLEAR': clear('employees', {}); break;

        case types.ADDRESS + '_PENDING': pending('address'); break;
		case types.ADDRESS + '_SAVE_PENDING': save_pending('address'); break;
		case types.ADDRESS + '_FULFILLED': fulfilled('address'); break;
		case types.ADDRESS + '_CLEAR': state = { ...state, address: {} }; break;

        default: break;
    }

    return state;

    function pending(table) {
        state = {
            ...state,
            [table + '_pending']: true,
        };
    }
    function save_pending(table) {
        state = {
            ...state,
            [table + '_save_pending']: true,
        };
    }
    function save_fulfilled(table) {
        state = {
            ...state,
            [table + '_save_pending']: false,
        };
    }
    function fulfilled(table) {
        state = {
            ...state,
            [table]: action.data,
            [table + '_pending']: false,
            [table + '_save_pending']: false,
            [table + '_unsubscribe']: (action.unsubscribe) ? action.unsubscribe : state[table + '_unsubscribe'],
        };
    }
    function clear(table, value) {
        state = {
            ...state,
            [table]: value,
        }
    }
}
export default EmployeesReducer;


