import React, { useEffect, useState } from 'react';
import { Bar, BarChart, XAxis, YAxis } from 'recharts';
import { Ibox } from 'enspire-manager-framework';
import moment from 'moment';
import { useSelector } from 'react-redux';

const DashboardGraph = (props) => {

    /* Hooks --------------------------*/

    const graphs = useSelector((store) => store.graphs);
    const graphInvoices = graphs.graph_invoices;
    const graphQuotes = graphs.graph_quotes;
    const [quotes, setQuotes] = useState([]);
    const [invoices, setInvoices] = useState([]);
    const [dropdown, setDropdown] = useState('');
    const [state, setState] = useState({
        graphSize: 1,
        resized: false,
    });

    /* Effects --------------------------*/

    useEffect(() => {
        setDropdown((props.type == 'invoices') ? 'Invoiced' : 'Quoted');
    }, [props.type]);

    useEffect(() => {
        if (!state.resized && document.getElementById('dashboard-graph')?.offsetWidth) {
            setState(prev => ({ ...prev, graphSize: document.getElementById('dashboard-graph').offsetWidth - 20, resized: true }));
        }
    }, [document.getElementById('dashboard-graph')?.offsetWidth]);

    useEffect(() => {
        graphQuotes && setQuotes(graphQuotes?.filter((o) => o.date.toDate() >= props.startDate && o.date.toDate() <= props.endDate));
        graphInvoices && setInvoices(graphInvoices?.filter((o) => o.date.toDate() >= props.startDate && o.date.toDate() <= props.endDate));
    }, [props.startDate, props?.endDate, graphs]);

    /* Actions --------------------------*/

    const formatData = () => {
        return (props.type == 'invoices')
            ? invoices.map((invoiceSummary) => ({ day: moment(invoiceSummary.date.toDate()).format('MMM DD'), invoiced: invoiceSummary.invoiced.toFixed(2), collected: invoiceSummary.collected.toFixed(2) }))
            : quotes.map((quoteSummary) => ({ day: moment(quoteSummary.date.toDate()).format('MMM DD'), quoted: quoteSummary.quoted.toFixed(2), approved: quoteSummary.approved.toFixed(2) }));
    };

    /* Constants --------------------------*/

    const dropdownArray = (props.type == 'invoices')
        ? ['Invoiced', 'Collected']
        : ['Quoted', 'Approved'];

    return (
        <Ibox
            id='dashboard-graph'
            className="mt-3"
            title={dropdown}
            dropdown_array={dropdownArray}
            dropdown_callback={(e) => setDropdown(e.target.value)}
            dropdown_value={dropdown}
            show_spinner={false}
            no_fade={true}
        >
            <p className="text-center mb-2">Dollars {dropdown} ($)</p>
            <BarChart width={state.graphSize} height={120} data={formatData()} style={{ marginLeft: '-40px' }}>
                <Bar dataKey={dropdown.toLowerCase()} fill="cadetBlue" barSize={30} background={{ fill: '#eee' }} />
                <XAxis dataKey="day" reversed />
                <YAxis />
            </BarChart>
            <div style={{ clear: 'both' }}></div>
        </Ibox>
    );
};

export default DashboardGraph;