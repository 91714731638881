import * as actions_quotes from 'actions/quotes-actions';
import * as toolbox from 'components/common/toolbox';
import LineItemsAccordion from 'components/service-items/line-items/line-items-accordion';
import React, { useEffect, useState } from 'react';
import ServiceItemsOption from 'components/service-items/line-items/service-items-option';
import _ from 'lodash';
import { Ibox, Spinner } from 'enspire-manager-framework';
import { QUOTES } from 'components/common/constants';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useParams } from 'react-router-dom';

export default function QuoteOptions(props) {

    /* Hooks ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

    const params = useParams();
    var dispatch = useDispatch();
    const history = useHistory();

    const quotes = useSelector((state) => state.quotes);
    const quote = quotes?.quote;
    const [errorMessage, setErrorMessage] = useState('');

    /* Effects ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

    // Subscribe to quote
    useEffect(() => {
        dispatch(actions_quotes.subQuoteById(params.handle, params.quote_id));

        return () => {
            var unsubscribe = quotes.quote_unsubscribe;
            if (typeof unsubscribe == 'function') unsubscribe();
        };
    }, []);

    // Update error message if signature link has expired
    useEffect(() => {
        if (params.validateSignature < Date.now()) {
            setErrorMessage('Link Expired');
        } else if (quote && (quote?.validateSignature != params.validateSignature)) {
            setErrorMessage('Invalid Link ');
        } else {
            setErrorMessage(null);
        }
    }, [params.validateSignature, quote]);

    /* Handlers/Actions ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

    const handleApprove = () => {
        history.push({ pathname: toolbox.modifyPath(history.location.pathname, 2, 'signature/quote/' + params.quote_id + '/' + params.validateSignature + '/' + quote.profileId) });
    };

    const handleDecline = () => {
        dispatch(actions_quotes.updateQuote(params.handle, { id: params.quote_id, statusId: QUOTES.REJECTED.id }, () => {
            setErrorMessage("Thank You!  Please call our office if there's anything else we can do to serve you!");
        }));
    };

    /* Render ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

    return (
        <div id="signature-body" >
            <Ibox className='container' title={`Quote ${(quote?.options?.length > 1) ? "Options" : ''}`}>
                <div style={{ color: '#666666', maxHeight: '80vh', overflowY: 'auto', overflowX: 'hidden' }}>
                    {errorMessage
                        ? <h2 className={'py-5'} style={{ textAlign: 'center' }}>{errorMessage}</h2>
                        : (quotes?.quote_pending || quotes?.quote_save_pending)
                            ? <Spinner />
                            : (quote?.statusId !== QUOTES.DRAFT.id && quote?.statusId !== QUOTES.PENDING.id && quote?.statusId !== QUOTES.CHANGES_REQUESTED.id)
                                ? <h1>This Quote cannot be Approved / Rejected at this time</h1>
                                : (quote.statusId === QUOTES.CHANGES_REQUESTED.id)
                                    ? <h1>Thank you for your Requested Changes!  You will hear from us shortly</h1>
                                    : (quote?.options?.length > 1)
                                        ? <>
                                            <LineItemsAccordion options={quote?.options} source={'quote-options'} />
                                            <div className='d-flex justify-content-end'>
                                                <button className={'mt-3 btn btn-danger'} onClick={handleDecline}>Decline all Options</button>
                                            </div>
                                        </>
                                        : <div className='row'>
                                            <div className='col-12'>
                                                <ServiceItemsOption option={quote?.options?.[0]} source={'quote-options'} />
                                            </div>
                                            <div className='col-12'>
                                                <div className='d-flex mt-3'>
                                                    <button className="btn btn-sm btn-success mr-2 col" onClick={handleApprove}>Approve</button>
                                                    <button className="btn btn-sm btn-danger col" onClick={handleDecline}>Decline</button>
                                                </div>
                                            </div>
                                        </div>
                    }
                </div>
            </Ibox>
        </div>
    );
};
