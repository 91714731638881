import React from 'react';
import { Ibox, Table } from 'enspire-manager-framework';
import { useSelector } from 'react-redux';
import { ROLES } from 'components/common/constants';
import * as toolbox from 'components/common/toolbox';
import { useHistory, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import _ from 'lodash';

const FieldServiceEmployees = () => {

    /* Hooks ----------------------------------------------------------------------------------------------------------------------------------------------------------------*/

    const history = useHistory();
    const location = useLocation();
    const params = useParams();

    const employees_raw = useSelector(store => store?.employees?.employees);
    const employees = employees_raw.filter((employee) => employee?.roles?.includes(ROLES.TECHNICIAN.id));

    /* Handlers ----------------------------------------------------------------------------------------------------------------------------------------------------------------*/

    const handleClick = (item) => {
        let newLocation = toolbox.modifyPath(location.pathname, 4, item.id, 5);

        let segments = location.pathname.split('/').length;

        if (segments == 4) history.push({ pathname: newLocation });
        else history.replace({ pathname: newLocation });
    };
    /* Constants ----------------------------------------------------------------------------------------------------------------------------------------------------------------*/

    const columns = [
        { name: 'Name', field: 'name', width: 14 },
    ];

    var data = [{ name: 'All Technicians', id: 'all' }];
    employees.forEach((employee) => data.push({ name: employee.contact.firstName + ' ' + employee.contact.lastName, id: employee?.id }));

    /* Render ----------------------------------------------------------------------------------------------------------------------------------------------------------------*/
    return (
        <Ibox
            title={'Technicians'}
        >
            <Table
                active_field={'id'}
                active_id={params.employee_id}
                columns={columns}
                data={data}
                click_callback={handleClick}
            />
        </Ibox>
    );
};

export default FieldServiceEmployees;