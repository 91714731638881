import 'App.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-bootstrap-typeahead/css/Typeahead.css';

import * as actions_authentication from 'actions/authentication-actions';
import * as actions_call_log from 'actions/call-log-actions';
import * as actions_company from 'actions/company-actions';
import * as actions_graphs from 'actions/graphs-actions';
import * as actions_profile from 'actions/profiles-actions';
import * as actions_quickbooks from 'actions/quickbooks-actions';
import * as actions_service_items from 'actions/service-items-actions';
import * as actions_service_requests from 'actions/service-request-actions';
import * as actions_settings from 'actions/settings-actions';
import * as actions_users from 'actions/users-actions';
import Archive from './archive/archive';
import CatchErrors from 'components/common/catch-errors';
import Dashboard from 'components/dashboard/dashboard';
import Footer from './layout/footer';
import Navbar from './layout/navbar/navbar';
import QuoteOptions from 'components/quotes/quote/quote-options';
import React, { useEffect, useState } from 'react';
import SideMenu from './layout/side-menu';
import Signature from './signature/signature';
import _ from 'lodash';
import firebase from 'firebase/compat/app';
import metadata from '../metadata.json';
import { PasswordReset, Spinner, UserNotFound } from 'enspire-manager-framework';
import { ROLES } from 'components/common/constants';
import { Redirect, Route, Switch } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { numPanels, matchURL } from 'components/common/toolbox';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';

import { Login } from 'components/test/login';
import { Register } from 'components/test/register';

const Customers = React.lazy(() => import('./customers/customers'));
const Employees = React.lazy(() => import('./employees/employees'));
const Schedule = React.lazy(() => import('./schedule/schedule'));
const Settings = React.lazy(() => import('./settings/settings'));
const ServiceItems = React.lazy(() => import('./service-items/service-items'));
const QuickbooksConnect = React.lazy(() => import('./settings/accounting/quickbooks/quickbooks-connect'));

export default function App(props) {

	const routeLocation = useLocation();
	const history = useHistory();
	const params = useParams();
	const match = useRouteMatch();
	const dispatch = useDispatch();
	const company = useSelector((store) => store.company);
	const users = useSelector((store) => store.users);
	const settings = useSelector((store) => store.settings);
	const [isSinglePanel, setIsSinglePanel] = useState(false);	

	// constructor(props) {
	// 	super(props);
	// 	this.state = {
	// 		menuId: 1,
	// 		newVersionNotified: false,
	// 		monitor2: false,
	// 	};
	// 	this.menu = React.createRef();
	// }

	// componentDidMount() {
	// 	this.componentDidUpdate(null);
	// }

	/* Listeners -----------------------------------------------------------------------------------------------------------------------*/

	useEffect(() => {
		handleResize();
		window.addEventListener("resize", _.debounce(handleResize, 250));
		// return () => { window.removeEventListener("resize"), _.debounce(handleResize, 250))};
	}, []);
	
	useEffect(() => {

		const unsubscribe = onAuthStateChanged(getAuth(), (user) => {
			if (user) {
				dispatch(actions_authentication.validateLogin(match.params.handle, user, (result) => {

					if (result === 'not_found') history.push('/' + match.params.handle + '/user_not_found/' + user.email);
					if (result === 'success') {
						dispatch(actions_settings.subSettings(match.params.handle));
						dispatch(actions_settings.subDepartments(match.params.handle));
						dispatch(actions_settings.subJobCategories(params.handle));
						dispatch(actions_settings.subDepartmentTags(params.handle));
						dispatch(actions_service_items.subServiceItemSettings(match.params.handle));
						dispatch(actions_quickbooks.subQuickbooksSettings(match.params.handle));
						dispatch(actions_service_requests.subProjects(match.params.handle));
						dispatch(actions_company.subCompany(match.params.handle));
						dispatch(actions_call_log.subCallLog(match.params.handle, user.email));
						dispatch(actions_profile.subEmployees(match.params.handle));
						dispatch(actions_users.subUserRecentItems(match.params.handle, user.email));
						dispatch(actions_graphs.subQuotes(match.params.handle));
						dispatch(actions_graphs.subInvoices(match.params.handle));
						dispatch(actions_users.subUserPermissions(match.params.handle, user.email));
						dispatch(actions_users.subUser(match.params.handle, user.email, (userdata) => {

							if (routeLocation.pathname.includes('/login') || routeLocation.pathname.includes('/register_user')) {

								if (userdata.roles?.includes(ROLES.ADMINISTRATOR.id) || userdata.roles?.includes(ROLES.OWNER.id)) { // administrator
									history.push('/' + match.params.handle + '/dashboard/overview');
								} else if (userdata.roles?.includes(ROLES.DISPATCHER.id)) { // administrator
									history.push('/' + match.params.handle + '/customers');
								} else if (userdata.roles?.includes(ROLES.TECHNICIAN.id)) { // administrator
									history.push('/' + match.params.handle + '/schedule');
								} else {
									history.push('/' + match.params.handle + '/user_not_found/' + user.email);
								}
							}
						}));
					}
				}));
			} else {
				console.log('logged out');
				if (
					!routeLocation.pathname.includes('/login') &&
					!routeLocation.pathname.includes('/register_user') &&
					!routeLocation.pathname.includes('/email_link') &&
					!routeLocation.pathname.includes('signature') &&
					!routeLocation.pathname.includes('quote_options')
				) {
					history.push('/' + match.params.handle + '/login');
				}
			}
		});

		// if (routeLocation.pathname.includes('/register_user')) dispatch(actions_authentication.logout());
		// if (routeLocation.pathname.endsWith('/dual_monitor')) {
		// 	this.setState({ monitor2: true }, () => {
		// 		history.push('/' + match.params.handle + '/schedule/' + moment().format('YYYY-MM-DD'), '_blank');
		// 	});
		// }
			
		// if (!this.state.newVersionNotified && metadata.buildRevision && this.props.settings.settings_version?.build && metadata.buildRevision < this.props.settings.settings_version?.build) {
		// 	this.setState({ newVersionNotified: true }, () => {
		// 		window.toastr.info('Please click "Refresh" to download the latest version of this software.', 'UPDATED VERSION AVAILABLE!');
		// 	});
		// }

		return () => {
			if (typeof unsubscribe === 'function') unsubscribe();
		};	
	}, [params.handle]);	

	/* Handlers ----------------------------------------------------------------------------------------------------------------------*/

	const handleResize = () => {
		setIsSinglePanel(numPanels(1));
	}
	const toggleMenuCollapsed = () => {
		dispatch(actions_settings.toggleMenuCollapsed());
	}


	const handle = match.params.handle;
	const P = users?.user_permissions;

	return (

		<Switch>

			<Route exact path={'/' + handle} render={(route_props) =>
				<Redirect to={'/' + handle + '/login'} />
			} />
			<Route path={match.path + '/login/:base64?'} render={(route_props) =>
				<Login landing={'/'} firebase={firebase} params={params} history={history} dispatch={dispatch} />
			} />
			<Route path={match.path + '/register_user/:first?/:last?/:email?'} render={(route_props) =>
				<Register landing={'/'} firebase={firebase} history={history} dispatch={dispatch} {...route_props} />
			} />
			<Route path={match.path + '/user_not_found/:email'} render={(route_props) =>
				<UserNotFound firebase={firebase} />
			} />
			<Route path={match.path + '/password_reset'} render={(route_props) =>
				<PasswordReset firebase={firebase} />
			} />
			<Route path={match.path + '/quickbooks'} render={(route_props) =>
				<QuickbooksConnect firebase={firebase} {...route_props} />
			} />
			<Route path={match.path + '/signature/:tableName/:quote_id/:validateSignature/:customer_id'} render={(route_props) =>
				<Signature firebase={firebase} {...route_props} />
			} />
			<Route path={match.path + '/quote_options/:quote_id?/:validateSignature?'} render={(route_props) =>
				<QuoteOptions  {...route_props} />
			} />
			<Route path={match.path} render={(route_props) =>
				<>
					{ !isSinglePanel &&
						<SideMenu handle={company?.company?.handle}
							location={routeLocation}
							user={users?.user}
							userRoles={Object.values(ROLES)}
						/>
					}
					<Navbar handle={handle} />
					
					<div id="wrapper">

						<div id="page-wrapper" className="gray-bg px-0" style={{ marginLeft: (isSinglePanel) ? '0' : '79px' }}>

							<div className="container-fluid">

								{!company.company || !users.users // only check for these two.  Other tables, check on individual pages.
									? < div style={{ position: 'absolute', top: '50%', left: '50%' }}>
										<Spinner />
									</div>
									: <>
										{(	P?.DASHBOARD_OVERVIEW ||
											P?.DASHBOARD_PROJECTS ||
											P?.DASHBOARD_SERVICE_MAP ) &&
											<Route path={match.path + '/dashboard'} render={(route_props) =>
												<CatchErrors className={'m-t-lg'}>
													<Dashboard {...route_props} />
												</CatchErrors>
											} />
										}
										{P?.CUSTOMERS_VIEW &&
											<Route path={match.path + '/customers'} render={(route_props) =>
												<React.Suspense fallback={<Spinner style={{ marginTop: '20%' }} />}>
													<CatchErrors className={'m-t-lg'}>
														<Customers {...route_props} />
													</CatchErrors>
												</React.Suspense>
											} />}
										{P?.SCHEDULE_VIEW &&
											<Route path={match.path + '/schedule'} render={(route_props) =>
												<React.Suspense fallback={<Spinner style={{ marginTop: '20%' }} />}>
													<CatchErrors className={'m-t-lg'}>
														<Schedule {...route_props} app="dispatch" />
													</CatchErrors>
												</React.Suspense>
											} />
										}
										{P?.EMPLOYEES_VIEW &&
											<Route path={match.path + '/employees'} render={(route_props) =>
												<React.Suspense fallback={<Spinner style={{ marginTop: '20%' }} />}>
													<CatchErrors className={'m-t-lg'}>
														<Employees {...route_props} app="dispatch" />
													</CatchErrors>
												</React.Suspense>
											} />
										}
										<Route path={match.path + '/settings'} render={(route_props) =>
											<React.Suspense fallback={<Spinner style={{ marginTop: '20%' }} />}>
												<CatchErrors className={'m-t-lg'}>
													<Settings {...route_props} />
												</CatchErrors>
											</React.Suspense>
										} />
										{P?.SERVICE_ITEMS_VIEW &&
											<Route path={match.path + '/service-items'} render={(route_props) =>
												<React.Suspense fallback={<Spinner style={{ marginTop: '20%' }} />}>
													<CatchErrors className={'m-t-lg'}>
														<ServiceItems {...route_props} />
													</CatchErrors>
												</React.Suspense>
											} />
										}
										{P?.ARCHIVES_VIEW &&
											<Route path={match.path + '/archive'} render={(route_props) =>
												<CatchErrors className={'m-t-lg'}>
													<Archive {...route_props} />
												</CatchErrors>
											} />
										}
									</>
								}

								{/* <Footer monitor2={this.state.monitor2} /> */}
							</div>
						</div>
					</div>
				</>
			} />
		</Switch>
	);
};