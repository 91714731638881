import * as actions_archive from 'actions/archive-actions';
import * as actions_quickbooks from 'actions/quickbooks-actions';
import * as actions_profiles from 'actions/profiles-actions';
import React, { useEffect } from 'react';
import firebase from 'firebase/compat/app';
import { Ibox, ModalAlert, Table } from 'enspire-manager-framework';
import { useDispatch, useSelector } from 'react-redux';

const firestore = firebase.firestore();

export default function ArchiveSearch(props) {

    /* Hooks ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

    const dispatch = useDispatch();

    const archive = useSelector((store) => store.archive);
    const quickbooksSettings = useSelector((store) => store.quickbooks.quickbooks);
    const user_permissions = useSelector((store) => store.users?.user_permissions ?? {});

    const archivedCustomers = archive.archived_profiles?.filter((o) => o.type == 'customer');
    const archivedEmployees = archive.archived_profiles?.filter((o) => o.type == 'employee');


    /* Effects ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

    // Subscribe to archived items
    useEffect(() => {
        dispatch(actions_archive.subArchivedProfiles(props.match.params.handle));
        dispatch(actions_archive.subArchivedServiceRequest(props.match.params.handle));
        dispatch(actions_archive.subArchivedWorkOrders(props.match.params.handle));
        dispatch(actions_archive.subArchivedServiceItems(props.match.params.handle));
        dispatch(actions_archive.subArchivedPayments(props.match.params.handle));

        return () => {
            var unsubscribe = archive.archived_profiles_unsubscribe;
            if (typeof unsubscribe == 'function') unsubscribe();

            unsubscribe = archive.archived_service_requests_unsubscribe;
            if (typeof unsubscribe == 'function') unsubscribe();

            unsubscribe = archive.archived_work_orders_unsubscribe;
            if (typeof unsubscribe == 'function') unsubscribe();

            unsubscribe = archive.archived_service_items_unsubscribe;
            if (typeof unsubscribe == 'function') unsubscribe();

            unsubscribe = archive.archived_payments_unsubscribe;
            if (typeof unsubscribe == 'function') unsubscribe();
        };
    }, []);

    /* Handlers/Actions ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

    const restoreItem = async (type, name, item) => {

        try {
            var serviceRequest;
            var profileId;
            if (type == 'work-order') {
                serviceRequest = await firestore.collection(props.match.params.handle + '/service-requests/service-requests').doc(item.serviceRequestId).get();
                profileId = serviceRequest.data()['profileId'];
            }

            ModalAlert({
                title: 'Are you sure?',
                text: "This " + name + " will be restored and will appear in " + name + " search",
                icon: 'warning',
                show_cancel: true,
                confirm_color: '#8FBC8B',
                confirm_text: 'Yes, restore it!',
                callback_success: () => {
                    if (type == 'customer') dispatch(actions_archive.restoreProfile(props.match.params.handle, item.id, 'CUSTOMER', null, () => {
                        props.history.replace('/' + props.match.params.handle + '/customers');

                        dispatch(actions_quickbooks.updateQuickbooks({
                            handle: props.match.params.handle,
                            type: 'customer',
                        }, quickbooksSettings, (errors) => {
                            if (errors?.data?.length && errors.data?.[0]?.code != '6240') {
                                window.toastr.error(errors.data?.[0]?.error?.detail, 'Error Syncing to QuickBooks');
                            } else if (errors?.data?.length > 0) {
                                confirmHandleDuplicates(errors.data, 0);
                            }
                        }));
                    }));
                    if (type == 'employee') dispatch(actions_archive.restoreProfile(props.match.params.handle, item.id, 'EMPLOYEE', item, () => {
                        props.history.replace('/' + props.match.params.handle + '/customers');
                    }));
                    if (type == 'service-request') dispatch(actions_archive.restoreServiceRequest(props.match.params.handle, item.id, () => {
                        props.history.replace('/' + props.match.params.handle + '/customers/' + item.profileId + '/form/0/0/service_requests');
                    }));
                    if (type == 'work-order') dispatch(actions_archive.restoreWorkOrder(props.match.params.handle, item.id, () => {
                        props.history.replace('/' + props.match.params.handle + '/customers/' + profileId + '/form/0/0/service_requests/' + item.serviceRequestId + '/work_orders/' + item.id + '/items');
                    }));
                    if (type == 'service-item') dispatch(actions_archive.restoreServiceItem(props.match.params.handle, item.id, () => {
                        props.history.replace('/' + props.match.params.handle + '/service-items');

                        dispatch(actions_quickbooks.updateQuickbooks({
                            handle: props.match.params.handle,
                            type: 'service-item',
                        }, quickbooksSettings));
                    }));
                }
            });
        } catch (error) {
            window.toastr.error(error);
        }
    };
    const deleteItem = (type, name, item) => {

        var text;
        if (type == 'customer') text = "This Customer will be PERMANENTLY DELETED along with all associated SERVICE REQUESTS, WORK ORDERS, INVOICES and ATTACHMENTS!";
        if (type == 'service-request') text = "This Service Request will be PERMANENTLY DELETED along with all associated WORK ORDERS, and ATTACHMENTS!";

        ModalAlert({
            title: 'Are you sure?',
            text: text,
            icon: 'error',
            input: 'text',
            label: 'To confirm, type "PERMANENTLY DELETE"',
            validator: (value) => {
                if (value != 'PERMANENTLY DELETE') return 'Phrase Not Correct';
            },
            show_cancel: true,
            confirm_color: '#ED5565',
            confirm_text: 'Yes, PERMANENTLY DELETE!',
            callback_success: () => {
                if (type == 'customer') dispatch(actions_archive.deleteProfile(props.match.params.handle, item.id, 'CUSTOMER', null));
                if (type == 'employee') dispatch(actions_archive.deleteProfile(props.match.params.handle, item.id, 'EMPLOYEE', item));
                if (type == 'service-item') dispatch(actions_archive.deleteServiceItem(props.match.params.handle, item.id));
                if (type == 'work-order') dispatch(actions_archive.deleteWorkOrder(props.match.params.handle, item.id));
            }
        });
    };

    // Handle each duplicate one by one
    const confirmHandleDuplicates = (errors, index) => {
        if (index >= errors.length) return;
        const error = errors[index];

        ModalAlert({
            title: `Existing Customer: "${error.displayName}" Found in QuickBooks`,
            text: "These Customers will be merged into one. Which data should take priority?",
            icon: 'warning',

            // MobileTrack
            confirm_color: '#8FBC8B',
            confirm_text: 'MobileTrack',
            callback_success: () => {
                dispatch(actions_profiles.mergeCustomers({
                    handle: props.match.params.handle,
                    customerId: error.id,
                    priority: 'mobiletrack',
                }, error, quickbooksSettings, () => {
                    // Run next iteration
                    if (index == errors.length - 1) {
                        return;
                    } else {
                        confirmHandleDuplicates(errors, index + 1);
                    }
                }));
            },

            // QuickBooks
            show_deny: true,
            deny_color: '#8FBC8B',
            deny_text: 'QuickBooks',
            callback_denied: () => {
                dispatch(actions_profiles.mergeCustomers({
                    handle: props.match.params.handle,
                    customerId: error.id,
                    priority: 'quickbooks',
                }, error, quickbooksSettings, () => {
                    // Run next iteration
                    if (index == errors.length - 1) {
                        return;
                    } else {
                        confirmHandleDuplicates(errors, index + 1);
                    }
                }));
            },

            show_cancel: true,
            cancel_text: 'Skip Merge',
            callback_cancel: () => {
                if (index == errors.length - 1) {
                    return;
                } else {
                    confirmHandleDuplicates(errors, index + 1);
                }
            },
        });
    };

    /* Render ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

    return (

        <Ibox className="mt-3" title={'Archived Items'} no_fade={true}>

            {!!archivedCustomers?.length && user_permissions?.CUSTOMERS_DELETE &&
                <>
                    <h4>Customers</h4>
                    <Table
                        data={archivedCustomers}
                        show_limit={false}
                        show_search={true}
                        highlight_search={true}
                        columns={[
                            { name: 'Name', field: 'displayName', width: 45 },
                            { name: 'Modified', field: 'modified', type: 'date', format: 'MMM Do, YYYY', width: 25 },
                            { name: 'Restore', field: 'id', type: 'button', button: { name: 'Restore Customer', className: 'btn-primary btn-xs' }, callback: restoreItem.bind(this, 'customer', 'Customer'), width: 25 },
                            { name: '', field: 'id', type: 'button', button: { name: <i className="far fa-trash-alt"></i>, className: 'btn-default btn-xs' }, callback: deleteItem.bind(this, 'customer', 'Customer'), width: 5 }
                        ]}
                    />
                </>
            }
            {!!archivedEmployees?.length && user_permissions?.EMPLOYEES_DELETE &&
                <>
                    <h4>Employees</h4>
                    <Table
                        data={archivedEmployees?.map((o) => ({ ...o, displayName: o.contacts[Object.keys(o.contacts)[0]].firstName + ' ' + o.contacts[Object.keys(o.contacts)[0]].lastName }))}
                        show_limit={false}
                        show_search={true}
                        highlight_search={true}
                        columns={[
                            { name: 'Name', field: 'displayName', width: 20 },
                            { name: 'Modified', field: 'modified', type: 'date', format: 'MMM Do, YYYY', width: 10 },
                            { name: 'Restore', field: 'id', type: 'button', button: { name: 'Restore Employee', className: 'btn-primary btn-xs' }, callback: restoreItem.bind(this, 'employee', 'Employee'), width: 20 },
                            { name: '', field: 'id', type: 'button', button: { name: <i className="far fa-trash-alt"></i>, className: 'btn-default btn-xs' }, callback: deleteItem.bind(this, 'employee', 'Employee'), width: 5 }
                        ]}
                    />
                </>
            }
            {!!archive.archived_service_requests?.length && user_permissions?.SERVICE_REQUESTS_DELETE &&
                <>
                    <h4>Service Requests</h4>
                    <Table
                        data={archive.archived_service_requests}
                        show_limit={false}
                        show_search={true}
                        highlight_search={true}
                        active_id={props.match.params.id}
                        active_field={'id'}
                        columns={[
                            { name: 'ID', field: 'id', width: 20 },
                            { name: 'Description', field: 'description', width: 45 },
                            { name: 'Modified', field: 'modified', type: 'date', format: 'MMM Do, YYYY', width: 10 },
                            { name: 'Restore', field: 'id', type: 'button', button: { name: 'Restore Service Request', className: 'btn-primary btn-xs' }, callback: restoreItem.bind(this, 'service-request', 'Service Request'), width: 20 },
                            { name: '', field: 'id', type: 'button', button: { name: <i className="far fa-trash-alt"></i>, className: 'btn-default btn-xs' }, callback: deleteItem.bind(this, 'service-request', 'Service Request'), width: 5 }
                        ]}
                    />
                </>
            }
            {!!archive.archived_work_orders?.length && user_permissions?.WORK_ORDERS_DELETE &&
                <>
                    <h4>Work Orders</h4>
                    <Table
                        data={archive.archived_work_orders}
                        show_limit={false}
                        show_search={true}
                        highlight_search={true}
                        active_id={props.match.params.id}
                        active_field={'id'}
                        columns={[
                            { name: 'ID', field: 'customId', width: 20 },
                            { name: 'Description', field: 'description', width: 45 },
                            { name: 'Modified', field: 'modified', type: 'date', format: 'MMM Do, YYYY', width: 10 },
                            { name: 'Restore', field: 'id', type: 'button', button: { name: 'Restore Work Order', className: 'btn-primary btn-xs' }, callback: restoreItem.bind(this, 'work-order', 'Work Order'), width: 20 },
                            { name: '', field: 'id', type: 'button', button: { name: <i className="far fa-trash-alt"></i>, className: 'btn-default btn-xs' }, callback: deleteItem.bind(this, 'work-order', 'Work Order'), width: 5 }
                        ]}
                    />
                </>
            }
            {!!archive.archived_service_items?.length && user_permissions?.SERVICE_ITEMS_DELETE &&
                <>
                    <h4>Service Items</h4>
                    <Table
                        data={archive.archived_service_items}
                        show_limit={false}
                        show_search={true}
                        highlight_search={true}
                        active_id={props.match.params.id}
                        active_field={'id'}
                        columns={[
                            { name: 'Category', field: 'category', width: 15 },
                            { name: 'Name', field: 'name', width: 15 },
                            { name: 'Description', field: 'description', width: 35 },
                            { name: 'Modified', field: 'modified', type: 'date', format: 'MMM Do, YYYY', width: 10 },
                            { name: 'Restore', field: 'id', type: 'button', button: { name: 'Restore Service Item', className: 'btn-primary btn-xs' }, callback: restoreItem.bind(this, 'service-item', 'Service Item'), width: 20 },
                            { name: '', field: 'id', type: 'button', button: { name: <i className="far fa-trash-alt"></i>, className: 'btn-default btn-xs' }, callback: deleteItem.bind(this, 'service-item', 'Service Item'), width: 5 }
                        ]}
                    />
                </>
            }
            {!!archive.archived_payments?.length && user_permissions.PAYMENTS_DELETE &&
                <>
                    <h4>Payments</h4>
                    <Table
                        data={archive.archived_payments.map((o) => ({ ...o, date: o.date }))}
                        show_limit={false}
                        show_search={true}
                        highlight_search={true}
                        active_id={props.match.params.id}
                        active_field={'id'}
                        columns={[
                            { name: 'Customer ID#', field: 'customerId', width: 25 },
                            { name: 'Amount', field: 'amount', prefix: '$', width: 25 },
                            { name: 'Date', field: 'date', type: 'date', width: 25, format: 'MMM Do, YYYY' },
                            { name: '', field: 'id', type: 'button', button: { name: <i className="far fa-trash-alt"></i>, className: 'btn-default btn-xs' }, callback: deleteItem.bind(this, 'service-item', 'Service Item'), width: 5 }
                        ]}
                    />
                </>
            }
            {
                !archivedCustomers?.length &&
                !archive.archived_service_requests?.length &&
                !archive.archived_work_orders?.length &&
                !archive.archived_service_items?.length &&
                !archivedEmployees?.length &&
                !archive.archived_payments?.length &&
                <h2>There are no Archived Items at this time</h2>
            }

        </Ibox>
    );
}
