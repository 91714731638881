import React, { useRef } from 'react';
import { Route } from "react-router-dom";
import ProjectsSearch from 'components/dashboard/projects/project-search';
import ProjectAnalysis from 'components/dashboard/projects/project-analysis';
import ServiceRequestDashboard from 'components/service-requests/service-request/service-request-dashboard';
import CatchErrors from 'components/common/catch-errors';

const Projects = (props) => {

    const col2Ref = useRef();

    return (
        <>
            <div className="col-12">
                <div className="row">

                    <Route
                        path={props.match.path + '/:project_id?'} render={() => (
                            <div id="column1" className="column col-4 animate-left">
                                <h1><strong>PROJECTS</strong></h1>
                                <CatchErrors className={'m-t-lg'}>
                                    <ProjectsSearch />
                                </CatchErrors>
                            </div>
                        )}
                    />

                    <Route path={props.match.path + '/:request_id'} render={(route_props) =>
                        <>
                            <div id="column2" className="column col-4 animate-left" ref={col2Ref}>
                                <CatchErrors className={'m-t-lg'}>
                                    <ServiceRequestDashboard {...route_props} width={col2Ref.current?.offsetWidth} source={'projects'} />
                                </CatchErrors>
                            </div>
                            <div id="column3" className="column col-4 animate-left">
                                <CatchErrors className={'m-t-lg'}>
                                    <ProjectAnalysis {...route_props} />
                                </CatchErrors>
                            </div>
                        </>
                    } />

                </div>
            </div>
        </>
    );
};
export default Projects;
