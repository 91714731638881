import DateRange from 'components/common/date-range';
import StatWidgets from 'components/common/stat-widgets/stat-widgets';
import DashboardGraph from 'components/dashboard/overview/dashboard-graph';
import React from 'react';
import { useDispatch } from 'react-redux';
import CatchErrors from 'components/common/catch-errors';

const Overview = () => {

    const dispatch = useDispatch();

    return (
        <div>
            <h1><strong>FINANCIAL OVERVIEW</strong></h1>
            <CatchErrors className={'m-t-lg'}>
                <DateRange
                    showPresets={true}
                    buttonTitle={'Update Range'}
                    className={'mt-3'}
                >
                    <StatWidgets admin={true} />
                    <DashboardGraph />
                    {/* <button onClick={ () => {
                    dispatch(actions_admin.moveData());
                } }>Move</button> */}
                </DateRange>
            </CatchErrors>
        </div>
    );
};

export default Overview;