import React, { Fragment } from 'react';
import { StateOptions } from 'enspire-manager-framework';
import { ADDRESSES } from 'components/common/constants';

export function customer_fields() {

	return (
		{
			displayName: '',
			customerTypeId: '',
			companyName: '',
			notes: '',
			// Service Address Info
			addressName: '',
			addressTypeId: ADDRESSES.BILLING.id,
			addressLine1: '',
			addressLine2: '',
			city: '',
			state: '',
			zip: '',
			// Main Contact Info(Billing)
			firstName: '',
			lastName: '',
			email: '',
			phone: '',
			mobile: '',
			fax: '',
			department: '',
			notifyViaEmail: true,
			notifyViaSms: true,
		}
	);
}
export function customer_form_layout(existing, settings, CUSTOMERS, CONTACTS) {

	var treatAddressAsCustomer = settings.settings?.customer?.treatAddressAsCustomer;

	var customer_type_options = Object.values(CUSTOMERS).map((type, index) => <option key={index} value={type.id}>{type.name}</option>);
	var contact_type_options = Object.values(CONTACTS).map((type, index) => <option key={index} value={type.id}>{type.name}</option>);
	var address_type_options = Object.keys(ADDRESSES).map((type) => (<option key={type} value={ADDRESSES[type].id}>{ADDRESSES[type].name}</option>));
	address_type_options = address_type_options.slice(0, 3);

	if (existing) return (
		[{
			column_class: 'col-md-12',
			body: [{
				section:
					<Fragment>
						<h3 className="m-t-none m-b">Customer Information</h3>
					</Fragment>,
				layout: [{
					grid: 'col-12',
					label: (treatAddressAsCustomer) ? 'Customer Address' : 'Display Name',
					field: 'displayName',
					type: 'text',
					valid: ['required'],
				}, {
					grid: 'col-6',
					label: 'Customer Type ID',
					field: 'customerTypeId',
					type: 'select',
					options:
						<Fragment>
							<option value="">Choose...</option>
							{customer_type_options}
						</Fragment>,
				}, {
					grid: 'col-6',
					label: 'Company Name',
					field: 'companyName',
					type: 'text',
				}, {
					grid: 'col-12',
					label: 'Customer Notes',
					field: 'notes',
					type: 'textarea',
				}],
			}],
		}]
	);
	if (!existing) return (
		[{
			column_class: 'col-md-12',
			body: [
				{
					section:
						<Fragment>
							<h3 className="m-t-none m-b">Main Contact</h3>
						</Fragment>,
					layout: [{
						grid: 'col-6',
						label: 'First Name',
						field: 'firstName',
						type: 'text',
						valid: ['required'],
					}, {
						grid: 'col-6',
						label: 'Last Name',
						field: 'lastName',
						type: 'text',
						valid: ['required'],
					}, {
						grid: 'col-6',
						label: 'Contact Type',
						field: 'contactTypeId',
						type: 'select',
						valid: ['required'],
						options:
							<Fragment>
								<option value="">Choose...</option>
								{contact_type_options}
							</Fragment>,
					}, {
						grid: 'col-6',
						label: 'Email',
						field: 'email',
						type: 'text',
						valid: ['email'],
					}, {
						grid: 'col-4',
						label: 'Mobile',
						field: 'mobile',
						type: 'text',
						valid: ['phone'],
					}, {
						grid: 'col-4',
						label: 'Phone',
						field: 'phone',
						type: 'text',
						valid: ['phone'],
					}, {
						grid: 'col-4',
						label: 'Fax',
						field: 'fax',
						type: 'text',
						valid: ['phone'],
					}],
				}, {
					section:
						<Fragment>
							<h3 className="m-t-none m-b">Notifications</h3>
						</Fragment>,
					layout: [{
						grid: 'col-4',
						label: 'Email Notifications',
						field: 'notifyViaEmail',
						type: 'checkbox',
					}, {
						grid: 'col-4',
						label: 'SMS Text Messages',
						field: 'notifyViaSms',
						type: 'checkbox',
					}],
				}, {
					section:
						<Fragment>
							<h3 className="m-t-none m-b">Customer Information</h3>
						</Fragment>,
					layout: [{
						grid: 'col-12',
						label: (treatAddressAsCustomer) ? 'Customer Address' : 'Display Name',
						field: 'displayName',
						type: 'text',
						valid: ['required'],
					}, {
						grid: 'col-6',
						label: 'Customer Type',
						field: 'customerTypeId',
						type: 'select',
						valid: ['required'],
						options:
							<Fragment>
								<option value="">Choose...</option>
								{customer_type_options}
							</Fragment>,
					}, {
						grid: 'col-6',
						label: 'Company Name',
						field: 'companyName',
						type: 'text',
					}, {
						grid: 'col-12',
						label: 'Customer Notes',
						field: 'notes',
						type: 'textarea',
					}],
				}, {
					section:
						<Fragment>
							<h3 className="m-t-none m-b">Service Address</h3>
						</Fragment>,
					layout: [{
						grid: 'col-12',
						label: 'Address Name (optional)',
						field: 'addressName',
						placeholder: 'e.g. Primary Residence, Vacation Home, etc.',
						type: 'text',
					}, {
						grid: 'col-8',
						label: 'Address',
						field: 'addressLine1',
						type: 'location-autocomplete',
						valid: ['required'],
					}, {
						grid: 'col-4',
						label: 'Secondary Address',
						field: 'addressLine2',
						type: 'text',
					}, {
						grid: 'col-4',
						label: 'City',
						field: 'city',
						type: 'text',
						valid: ['required'],
					}, {
						grid: 'col-4',
						label: 'State',
						field: 'state',
						type: 'select',
						options:
							<Fragment>
								<option value="">- Select -</option>
								<StateOptions />,
							</Fragment>,
						valid: ['required'],
					}, {
						grid: 'col-4',
						label: 'Zip',
						field: 'zip',
						type: 'text',
						valid: ['required', 'numeric'],
					}],
				}
			],
		}]
	);
}