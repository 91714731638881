import * as actions_settings from 'actions/settings-actions';
import React, { useEffect, useState } from 'react';
import StatusSelect from 'components/common/status-select';
import _ from 'lodash';
import { ModalForm, Textarea } from 'enspire-manager-framework';
import { REQUESTS, SERVICE_REQUEST_TERMS, WORKORDERS, QUOTES } from 'components/common/constants';
import { STATUS_TABLES } from 'components/common/constants';
import { useDispatch, useSelector } from 'react-redux';

export default function StatusModal(props) {

    const dispatch = useDispatch();

    const user_permissions = useSelector((store) => store.users.user_permissions);
    const statusTable = useSelector((store) => store.settings.statusTable);
    const statusId = useSelector((store) => store.settings.statusId); // status id of current tab
    const currentId = useSelector((store) => store.settings.currentId); // id of current record
    const confirmCallback = useSelector((store) => store.settings.confirmCallback);
    const serviceRequests = useSelector((store) => store.services.service_requests);
    const workOrders = useSelector((store) => store.workOrders.work_orders);
    const quotes = useSelector((store) => store.quotes.quotes);
    const [reason, setReason] = useState('');
    const [error, setError] = useState([]);

    useEffect(() => {
        if (statusTable == 'WORKORDERS') {
            record = _.find(workOrders, { id: currentId });
            setReason(record.reason);
        }
    }, [currentId]);


    const closeStatusModal = () => {
        dispatch(actions_settings.toggleStatusModal());
    };
    const handleTab = (statusId) => {
        dispatch(actions_settings.updateStatusModal(statusId));
    };
    const handleCurrentStatusButton = (statusTable, statusId) => {
        dispatch(actions_settings.updateStatusModal(statusId));
    };
    const handleChange = (event) => {
        setReason(event.target.value);
        setError([]);
    };

    const tableName = STATUS_TABLES[statusTable].singular;

    var statusTypes;
    var record;
    var recordStatusId;

    if (statusTable == 'REQUESTS') {
        statusTypes = Object.values(REQUESTS);
        record = _.find(serviceRequests, { id: currentId });
        recordStatusId = record?.statusId;
    }
    if (statusTable == 'WORKORDERS') {
        statusTypes = Object.values(WORKORDERS);
        record = _.find(workOrders, { id: currentId });
        recordStatusId = record?.statusId;
    }
    if (statusTable == 'QUOTES') {
        statusTypes = Object.values(QUOTES);
        record = _.find(quotes, { id: currentId });
        recordStatusId = record?.statusId;
    }
    if (statusTable == 'SERVICE_REQUEST_TERMS') {
        statusTypes = Object.values(SERVICE_REQUEST_TERMS);
        record = _.find(serviceRequests, { id: currentId });
        recordStatusId = record?.serviceRequestTermsId;
    }

    var selectedStatus = _.find(statusTypes, { id: statusId });
    const restricted = statusTable == 'WORKORDERS' && !user_permissions?.WORK_ORDERS_EDIT ||
        statusTable == "REQUESTS" && !user_permissions?.["edit_service_requests"] ||
        statusTable == "SERVICE_REQUEST_TERMS" && !user_permissions?.SERVICE_REQUESTS_TERMS ||
        statusTable == "QUOTES" && !user_permissions?.["edit_quotes"];

    return (
        <ModalForm {...props}
            style={1}
            visible={true}
            no_padding={true}
            modal_header={tableName + ' Status'}
            cancel_button_title="Cancel"
            cancel_button_callback={closeStatusModal}
            // save_button_title={'Fnished'}
            confirm_button_title={(
                (statusTable == 'WORKORDERS' && statusId != recordStatusId) ||
                statusTable == 'QUOTES' ||
                statusTable == 'SERVICE_REQUEST_TERMS' &&
                (statusId == SERVICE_REQUEST_TERMS.SERVICE_CALL.id || statusId == SERVICE_REQUEST_TERMS.ESTIMATE.id)
            ) ? 'Status ' + selectedStatus.name
                : (statusId == recordStatusId && selectedStatus.updatable)
                    ? "Update" : 'Finished'
            }
            confirm_button_level={(
                statusId != recordStatusId && (
                    statusTable == 'WORKORDERS' ||
                    statusTable == 'QUOTES' ||
                    statusTable == 'SERVICE_REQUEST_TERMS' &&
                    (statusId == SERVICE_REQUEST_TERMS.SERVICE_CALL.id || statusId == SERVICE_REQUEST_TERMS.ESTIMATE.id)
                )
            ) ? 'primary' : 'primary'}
            confirm_button_disabled={
                selectedStatus.possible === false ||
                (statusId == recordStatusId && !selectedStatus.updatable) ||
                restricted
            }
            confirm_button_callback={() => {
                if (statusTable == 'WORKORDERS' && selectedStatus.id == WORKORDERS.INCOMPLETE.id && reason == '') {
                    setError([{ field: 'reason', type: 'required' }]);
                } else {
                    if (typeof confirmCallback === 'function') confirmCallback(statusId, reason);
                }
            }}
        >
            <div className="tabs-container">
                <div className="tabs-left">
                    <ul className="nav nav-tabs" style={{ display: 'inline' }}>
                        {
                            statusTypes.map((status) => {
                                return (
                                    <li key={status.id} onClick={() => { handleTab(status.id); }}><a className={'nav-link ' + ((status.id == statusId) && 'active')}>
                                        <span style={{ color: status.color }}><i className="fa fa-circle"></i></span> &nbsp; {status.name}
                                    </a></li>
                                );
                            })
                        }
                    </ul>
                    <div className="tab-content ">

                        {
                            statusTypes.map((status, i) => {
                                return (
                                    <div key={status.id} className={'tab-pane ' + ((status.id == statusId) && 'active')}>
                                        <div className="panel-body pr-5" style={{ height: '309px' }}>

                                            <span className="float-right pt-3 pb-3">
                                                <span className="h6">Current Status: &nbsp; </span>
                                                <StatusSelect
                                                    selectedStatusId={recordStatusId}
                                                    statusTable={statusTable}
                                                    statusTypes={statusTypes}
                                                    onClick={handleCurrentStatusButton}
                                                    static={true}
                                                />
                                            </span>

                                            <h1 className="font-weight-bold mt-5">{status.name}</h1>
                                            <p style={{ fontSize: '18px', lineHeight: '26px' }}>{status.description}</p>


                                            {statusTable == 'WORKORDERS' && status.id == WORKORDERS.INCOMPLETE.id && selectedStatus.id == WORKORDERS.INCOMPLETE.id &&
                                                <>
                                                    <p className="text-muted mb-2"><smaller>Reason for Incomplete and Steps to Resolve</smaller></p>
                                                    <div className={'d-flex'} style={{ flexDirection: 'column', alignContent: 'stretch' }}>
                                                        <Textarea
                                                            name={'reason'}
                                                            onChange={handleChange}
                                                            rows={2}
                                                            value={reason}
                                                            form_error={error}
                                                        />
                                                    </div>
                                                </>
                                            }

                                            <hr width="100%" className="mt-4 mb-4" />

                                            {selectedStatus.change &&
                                                <>
                                                    <h4 className="text-muted">{(selectedStatus.caution) ? '** CAUTION **' : 'NOTE'}</h4>
                                                    <p style={{ fontSize: '14px', lineHeight: '22px' }}>{selectedStatus.change}</p>
                                                </>
                                            }
                                            {statusTable == 'REQUESTS' &&
                                                <p className="text-muted mb-2"><smaller>Service Request Statuses are updated automatically</smaller></p>
                                            }
                                            <div className="mb-5">&nbsp;</div>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
            <div style={{ clear: 'both' }}></div>

        </ModalForm>
    );
};
