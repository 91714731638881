import React, { useState } from 'react';
import { Table, ModalAlert } from 'enspire-manager-framework';
import * as toolbox from 'components/common/toolbox';
import { useHistory, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { QUOTES, SERVICE_ITEMS } from 'components/common/constants';
import * as actions_quotes from 'actions/quotes-actions';

const ServiceItemsOption = (props) => {

    /* Hooks ------------------------------------------------------------------------------------------------------------------------------------*/

    const history = useHistory();
    const location = useLocation();
    const params = useParams();
    const dispatch = useDispatch();

    const user_permissions = useSelector((state) => state.users?.user_permissions);
    const quotes = useSelector((state) => state.quotes);
    const workOrders = useSelector((state) => state.workOrders);

    const source = props?.source;
    const isWorkOrder = props.isWorkOrder;
    const option = props?.option;
    const quote = (source == 'quote-options')
        ? quotes?.quote
        : (source == 'project-dashboard')
            ? _.find(quotes.quotes, { id: params.project_id })
            : _.find(quotes.quotes, { id: params.quote_id });

    const [requestedChanges, setRequestedChanges] = useState('');

    /* Handlers/Actions ------------------------------------------------------------------------------------------------------------------------------------*/

    const confirmDelete = (option) => {
        ModalAlert({
            title: 'Are you sure you want to delete this Option?',
            text: "This Option will be permanently deleted.",
            icon: 'question',
            confirm_color: '#8FBC8B',
            confirm_text: 'Yes',
            deny_text: 'No, take me back',
            show_deny: true,
            callback_success: () => deleteOption(option)
        });
    };

    const deleteOption = (option) => {
        var newServiceItems = quote?.serviceItems?.filter((item) => item.option != option);
        var newOptions = quote?.options?.filter((opt) => opt != option);

        dispatch(actions_quotes.updateQuote(params.handle, { id: params.quote_id, options: newOptions, serviceItems: newServiceItems }, () => {
            if (quotes?.options_selected?.find((opt) => opt == option)) dispatch(actions_quotes.updateSelectedOptions(quotes?.options_selected?.filter((opt) => opt != option)));
        }));
    };

    const handleClick = (item, index) => {
        let newLocation = toolbox.modifyPath(location.pathname, 4, 'line-items/' + (index + 1) + ((option) ? ('/' + option) : ''));
        history.push({ pathname: newLocation });
    };

    const handleButton = () => {
        let newLocation = toolbox.modifyPath(location.pathname, 4, 'line-items/0' + ((option) ? ('/' + option) : ''));
        history.push({ pathname: newLocation });
    };

    const handleChangesRequested = (e) => setRequestedChanges(e.target.value);

    const handleApprove = (option) => {
        var quote = { id: params.quote_id, approvedOption: option };
        dispatch(actions_quotes.updateQuote(params.handle, quote, () => {
            history.push({ pathname: toolbox.modifyPath(history.location.pathname, 2, 'signature/quote/' + params.quote_id + '/' + params.validateSignature + '/' + quote.profileId) });
        }));
    };

    const handleRequestChanges = (option) => {
        dispatch(actions_quotes.updateQuote(params.handle, { id: params.quote_id, approvedOption: option ?? '', statusId: QUOTES.CHANGES_REQUESTED.id, requestedChanges: requestedChanges }, () => { }));
    };

    /* Constants ------------------------------------------------------------------------------------------------------------------------------------*/

    // Format Items
    let items = (isWorkOrder || source == 'project-work-orders')
        ? workOrders.work_order_items
        : (quote?.changeOrder)
            ? quote?.quoteItems?.concat(quote?.changedItems.map((item) => { return { ...item, _accent: { backgroundColor: (item.changeOrder == 'added') ? "lightgreen" : 'pink' } }; }))
            : quote?.serviceItems;
    items = items?.map((item) => ({
        ...item,
        serviceItemTypePosition: _.find(Object.values(SERVICE_ITEMS), { id: item.serviceItemTypeId })?.position.toString(),
        serviceItemType: _.find(Object.values(SERVICE_ITEMS), { id: item.serviceItemTypeId })?.name
    }));
    if (option) items = items?.filter((item) => item.option == option);

    // Get total
    var total = 0;
    items?.forEach((item, index) => {
        items[index].calculatedPrice = item?.customPricePerUnit * item?.count;
        total += items[index]?.calculatedPrice;
    });

    const columns = [
        { name: 'Item ID', field: 'serviceItemId', width: 15 },
        { name: 'Name', field: 'name' },
        { name: 'Count', field: 'count' },
        { name: 'Price', field: 'calculatedPrice', type: 'number', format: 'usd', text_align: 'right' },
    ];

    const groupByOptions = {
        fields: ['serviceItemTypePosition'],
        display: ['serviceItemType'],
        direction: ['asc'],
        collapsible: true,
        defaultOpen: true,
    };

    const orderByOptions = {
        fields: ['serviceItemId'],
        direction: ['asc'],
        collapsible: true,
        defaultOpen: true,
    };

    /* Render ------------------------------------------------------------------------------------------------------------------------------------*/

    return (
        <>
            <Table
                data={items}
                show_limit={false}
                show_search={(source != 'quote-options') && true}
                active_field={'id'}
                highlight_search={true}
                columns={columns}
                order_by={orderByOptions}
                group_by={groupByOptions}
                click_callback={(source != "quote-options") && (isWorkOrder && user_permissions?.WORK_ORDERS_EDIT || quote?.statusId < QUOTES.ACCEPTED.id && user_permissions?.QUOTES_EDIT) && handleClick}
                button={(source != "quote-options") && (isWorkOrder && user_permissions?.WORK_ORDERS_EDIT || quote?.statusId < QUOTES.ACCEPTED.id && user_permissions?.QUOTES_EDIT) && '+ Line Items'}
                button_in_ibox={true}
                button_callback={handleButton}
            />
            {quote?.options?.length > 1 && source != 'quote-options' && quote?.statusId != QUOTES.ACCEPTED.id &&
                <button className="btn btn-sm btn-danger float-left mr-2" onClick={() => confirmDelete(option)}>Delete Option</button>
            }
            <h4 className="text-right mr-2">Subtotal: &nbsp; ${toolbox.formatNumber(total)}</h4>
            {source == "quote-options" &&
                <>
                    <div className="mb-2">
                        <label htmlFor="changesRequested">Changes Requested</label>
                        <textarea className="form-control" id="changesRequested" rows="3" onChange={handleChangesRequested} value={requestedChanges} />
                    </div>
                    <button className="btn btn-sm btn-warning float-left mr-2 my-2" onClick={() => handleRequestChanges(option)} disabled={!requestedChanges.length}>Request Changes</button>
                    {quote?.options?.length > 1 && <div>
                        <button className="btn btn-sm btn-success float-left mr-2 my-2 mb-3" onClick={() => handleApprove(option)}>Approve this Option</button>
                    </div>}
                </>
            }
        </>
    );
};

export default ServiceItemsOption;