import * as toolbox from '../components/common/toolbox';
import * as types from './action-types';
import _ from 'lodash';
import firebase from 'firebase/compat/app';
import { ROLES } from 'components/common/constants';
import { collection, deleteField, getCountFromServer, getFirestore, query, where } from 'firebase/firestore';

const firestore = firebase.firestore();

/*-----------------------------------------------*/
/*  USERS
/*-----------------------------------------------*/

export function subUser(handle, email, callback) {

	return async dispatch => {

		dispatch({ type: types.USER + '_PENDING' });

		var unsubscribe = firestore.collection(handle + '/users/users').doc(email).onSnapshot((doc) => {
			var user = { ...doc.data(), id: doc.id };

			dispatch({ type: types.USER + '_FULFILLED', data: { ...user, email }, unsubscribe });
			if (typeof callback === 'function') callback(user);
		});
	};
}
export function subUserPermissions(handle, email, roleId) {

	return async dispatch => {

		dispatch({ type: types.USER_PERMISSIONS + '_PENDING' });

		if (email) {
			var unsubscribe = firestore.collection(handle + '/users/users').doc(email).onSnapshot(async (doc) => {

				const userRoles = doc.data().roles;
				const userId = doc.data().profileId;
				var settingsDoc = await firestore.collection(handle).doc('settings').get();
				const permissionsData = settingsDoc.data().permissions ?? {};

				var permissions = { OWNER: userRoles.includes(ROLES.OWNER.id), ADMINISTRATOR: userRoles.includes(ROLES.ADMINISTRATOR.id) };

				Object.keys(permissionsData).forEach((key) => {
					permissions[key] = (permissionsData[key].excluded?.find((exc) => exc.value == userId)) 
						? false
						: (permissionsData[key].included?.find((inc) => inc.value == userId)) 
							? true
							: !!(permissionsData[key].roles?.find((role) => userRoles.includes(role)));
				});

				dispatch({ type: types.USER_PERMISSIONS + '_FULFILLED', data: permissions, unsubscribe });
			});

			var unsubscribe = firestore.collection(handle).doc('settings').onSnapshot(async (doc) => {

				var userDoc = await firestore.collection(handle + '/users/users').doc(email).get();
				const userRoles = userDoc.data().roles;
				const userId = userDoc.data().profileId;
				const permissionsData = doc.data().permissions ?? {};

				var permissions = { OWNER: userRoles.includes(ROLES.OWNER.id), ADMINISTRATOR: userRoles.includes(ROLES.ADMINISTRATOR.id) };

				Object.keys(permissionsData).forEach((key) => {
					permissions[key] = (permissionsData[key].excluded?.find((exc) => exc.value == userId)) 
						? false
						: (permissionsData[key].included?.find((inc) => inc.value == userId)) 
							? true
							: !!(permissionsData[key].roles?.find((role) => userRoles.includes(role)));
				});

				dispatch({ type: types.USER_PERMISSIONS + '_FULFILLED', data: permissions, unsubscribe });
			});

		} else if (roleId) {
			
			var settingsDoc = await firestore.collection(handle).doc('settings').get();
			const permissionsData = settingsDoc.data().permissions ?? {};

			var permissions = { OWNER: roleId == ROLES.OWNER.id, ADMINISTRATOR: roleId == ROLES.ADMINISTRATOR.id };

			Object.keys(permissionsData).forEach((key) => {
				permissions[key] = !!(permissionsData[key].roles?.find((role) => role == roleId));
			});

			dispatch({ type: types.USER_PERMISSIONS + '_FULFILLED', data: permissions, unsubscribe });
		}
	};
}
export function updateUser(handle, email, profileId, user, callback) {

	return async dispatch => {

		dispatch({ type: types.USER + '_SAVE_PENDING' });

		var batch = firestore.batch();

		const userRef = firestore.collection(handle + '/users/users');
		const profileRef = firestore.collection(handle + '/profiles/profiles');

		batch.set(userRef.doc(email), { ...user }, { merge: true });
		if (profileId) batch.set(profileRef.doc(profileId), { modified: new Date() }, { merge: true });

		batch.commit().then(() => {
			window.toastr.success('The User record has been successfully saved/updated', 'User Saved!');
			dispatch({ type: types.USER + '_SAVE_FULFILLED' });
			if (typeof callback === 'function') callback(email);
		}).catch((error) => {
			toolbox.process_error(error, 'User NOT Saved!');
		});
	};
}
export function deleteUser(handle, email, callback) {

	return async dispatch => {

		dispatch({ type: types.USER + '_SAVE_PENDING' });

		firestore.collection(handle + '/users/users').doc(email).delete().then(() => {
			window.toastr.success('The User record has been permanently deleted', 'User Saved!');
			dispatch({ type: types.USER + '_SAVE_FULFILLED' });
			if (typeof callback === 'function') callback(email);
		}).catch((error) => {
			toolbox.process_error(error, 'User NOT Saved!');
		});
	};
}
export function subUserLocationsByDate(handle, email, date, callback) {

	return dispatch => {

		dispatch({ type: types.USER_LOCATIONS + '_PENDING' });

		var beginningOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate());
		var endOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);

		var unsubscribe = firestore.collection(handle + '/users/users/' + email + '/locations').where('timestamp', '>=', beginningOfDay).where('timestamp', '<', endOfDay).onSnapshot((querySnapshot) => {

			var locations = [];
			querySnapshot.forEach((doc) => {
				locations.push({ ...doc.data(), id: doc.id });
			});

			dispatch({ type: types['USER_LOCATIONS'] + '_FULFILLED', data: locations, unsubscribe });
			if (typeof callback === 'function') callback();
		});
	};
}

/*-----------------------------------------------*/
/*  ACTIVITY LOG
/*-----------------------------------------------*/

export function subActivityLog(handle, userId, limit, mostRecent, source, filterId, lowTypeCode, highTypeCode) {

	return async dispatch => {

		dispatch({ type: types.ACTIVITY_LOG + '_PENDING' });

		const thirtyDaysAgo = new Date();
		thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

		const logRef = (mostRecent)
			? firestore.collection(handle + '/activity-log/activity-log').where('mostRecent', '==', true).where('date', '>=', thirtyDaysAgo).orderBy('date', 'desc')
			: (source)
				? (source == 'employees')
					? firestore.collection(handle + '/activity-log/activity-log').where('userId', '==', userId).orderBy('date', 'desc').limit(limit)
					: firestore.collection(handle + '/activity-log/activity-log').where('id', '==', filterId).where('type', '>=', lowTypeCode).where('type', '<=', highTypeCode).orderBy('type', 'desc').orderBy('date', 'desc').limit(limit)
				: firestore.collection(handle + '/activity-log/activity-log').orderBy('date', 'desc').limit(limit);

		var unsubscribe = logRef.onSnapshot(async (querySnapshot) => {
			var entries = [];
			querySnapshot.forEach((doc) => {
				entries.push({ ...doc.data() });
			});
			var activity_log = _.orderBy(entries, ['date'], ['desc']);

			const db = getFirestore();
			const coll = collection(db, handle + '/activity-log/activity-log');
			// const query_ = query(coll, where('id', '==', filterId));
			const query_ = (mostRecent)
				? query(coll, where('mostRecent', '==', true))
				: (source)
					? (source == 'employees')
						? query(coll, where('userId', '==', userId))
						: query(coll, where('id', '==', filterId), where('type', '>=', lowTypeCode), where('type', '<=', highTypeCode))
					: query(coll);

			const snapshot = await getCountFromServer(query_);
			const count = snapshot.data().count;

			dispatch({ type: types.ACTIVITY_LOG + '_FULFILLED', data: { data: activity_log, totalCount: count }, unsubscribe: unsubscribe });
		});
	};
}

/*-----------------------------------------------*/
/*  SERVICE ITEMS
/*-----------------------------------------------*/

export function addServiceItemToRecent(handle, serviceItem, userId) {

	return async dispatch => {

		dispatch({ type: types.USER + '_SAVE_PENDING' });

		firestore.collection(handle + '/users/users/' + userId + '/service-items').doc(serviceItem.serviceItemTypeId.toString()).set({ recent: { [serviceItem.id]: new Date() } }, { merge: true }).then(() => {
			dispatch({ type: types.USER + '_SAVE_FULFILLED' });
		}).catch((error) => {
			toolbox.process_error(error, 'Unable to add item to recents!');
		});
	};
}
export function trimRecent(handle, recentArray, typeId, userId) {

	return async dispatch => {

		dispatch({ type: types.USER + '_SAVE_PENDING' });

		var batch = firestore.batch();
		var deleteValues = recentArray.slice(100);
		deleteValues.forEach((value) => {
			batch.set(firestore.collection(handle + '/users/users/' + userId + '/service-items').doc(typeId.toString()), { recent: { [value.id]: deleteField() } }, { merge: true });
		});

		batch.commit().then(() => {
			dispatch({ type: types.USER + '_SAVE_FULFILLED' });
		}).catch((error) => {
			toolbox.process_error(error, 'Unable to add item to recents!');
		});
	};
}
export function subUserRecentItems(handle, email) {

	return dispatch => {

		dispatch({ type: types.USER_RECENT_ITEMS + '_PENDING' });

		var unsubscribe = firestore.collection(handle + '/users/users/' + email + '/service-items').onSnapshot((querySnapshot) => {

			var recentItems = {};
			querySnapshot.forEach((doc) => {
				recentItems[doc.id] = doc.data();
			});

			dispatch({ type: types.USER_RECENT_ITEMS + '_FULFILLED', data: recentItems, unsubscribe });
		});
	};
}