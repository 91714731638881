import { INVOICES, PAYMENTS, WORKORDERS } from 'components/common/constants';
import * as toolbox from '../components/common/toolbox';
import * as types from './action-types';
import firebase from 'firebase/compat/app';

const firestore = firebase.firestore();
const _ = require('lodash');
var moment = require('moment');

/*-----------------------------------------------*/
/*  INVOICES
/*-----------------------------------------------*/

export function subInvoice(handle, id, service_items) {

	return dispatch => {

		dispatch({ type: types['INVOICE'] + '_PENDING' });

		var unsubscribe = firestore.collection(handle + '/invoices/invoices').doc(id).onSnapshot((doc) => {

			var invoice = { ...doc.data(), id: doc.id };

			if (invoice.serviceItems?.length) {
				for (var serviceItem of invoice.serviceItems) {
					let service_item = _.find(service_items, { id: serviceItem.id });

					if (!service_item) {
						firestore.collection(handle + '/service-items/service-items').doc(serviceItem.serviceItemId).get().then((doc) => {
							dispatch({ type: types['SERVICE_ITEMS'] + '_ADD', data: { ...doc.data(), id: doc.id } });
						});
					}
				}
			}

			dispatch({ type: types['INVOICE'] + '_FULFILLED', data: invoice, unsubscribe });
		});
	};
}

export function subInvoicesByField(handle, field, value) {

	return dispatch => {

		dispatch({ type: types['INVOICES'] + '_PENDING' });

		var unsubscribe = firestore.collection(handle + '/invoices/invoices').where(field, '==', value).onSnapshot((querySnapshot) => {

			var invoices = [];
			querySnapshot.forEach((doc) => {
				invoices.push({ ...doc.data(), id: doc.id });
			});
			invoices = _.orderBy(invoices, ['modified'], ['desc']);

			dispatch({ type: types['INVOICES'] + '_FULFILLED', data: invoices, unsubscribe });
		});
	};
}

export function createInvoicePdf(options, callback) {
	return async dispatch => {
		firebase.functions().httpsCallable('dispatchCreateInvoice')(options).then((result) => {
			if (typeof callback === 'function') callback(result);
		}).catch((error) => {
			console.log(error);
		});
	};
}
export function createPaymentReceiptPdf(options, callback) {
	return async dispatch => {
		firebase.functions().httpsCallable('dispatchCreateReceipt')(options).then((result) => {
			if (typeof callback === 'function') callback(result);
		}).catch((error) => {
			console.log(error);
		});
	};
}

export function createInvoice(handle, service_request_id, workOrders, callback) {

	return async dispatch => {

		dispatch({ type: types['INVOICE'] + '_SAVE_PENDING' });

		const user = firebase.auth().currentUser;
		const batch = firestore.batch();
		const invoiceId = await nextInvoiceId(handle, batch);

		const serviceRequestDoc = await firestore.collection(handle + '/service-requests/service-requests').doc(service_request_id).get();
		const serviceRequest = { ...serviceRequestDoc.data(), id: serviceRequestDoc.id };

		var serviceItems = [];
		var memoArray = [];
		workOrders.forEach((wo) => {
			memoArray.push('Work Order #' + wo['customId']);
		});	

		for (let workOrder of workOrders) {
			batch.update(firestore.collection(handle + '/work-orders/work-orders').doc(workOrder.id), { statusId: WORKORDERS.INVOICED.id });
			let doc = await firestore.collection(handle + '/work-orders/work-orders/' + workOrder.id + '/serviceItems').doc(workOrder.id).get();
			if (doc.exists) serviceItems = [...serviceItems, ...doc.data()['serviceItems']];
		}	

		var invoice = {
			billTo: '...',
			serviceTo: '...',
			addressId: serviceRequest.addressId,
			customId: serviceRequest.customId,
			requestId: serviceRequest.id,
			profileId: serviceRequest.profileId,
			sendToContactId: null,
			deleted: false,
			memo: memoArray.join(', '),
			serviceItems,
			payments: [],
			invoiceDate: new Date(),
			created: new Date(),
			modified: new Date(),
			statusId: INVOICES.DRAFT.id,
			workOrders: workOrders.map((wo) => ({ id: wo.id, customId: wo.customId })),
			editedBy: user.email,
		};	

		batch.set(firestore.collection(handle + '/invoices/invoices').doc(invoiceId), { ...invoice });

		batch.commit().then(() => {
			dispatch({ type: types['INVOICE'] + '_SAVE_FULFILLED' });
			window.toastr.success('The Invoice has been successfully saved/updated', 'Invoice Saved!');
			if (typeof callback === 'function') callback(invoiceId);

		}).catch((error) => {
			toolbox.process_error(error, 'Record NOT Saved!');
		});
	};

	async function nextInvoiceId(handle, batch) {
		const table = 'invoices';
		const field = 'nextInvoiceId';
		const startingId = 100000;

		return toolbox.nextId(handle, batch, table, field, startingId);
	}
}
export function updateInvoice(handle, update, invoiceId, callback) {

	return async dispatch => {
		const user = firebase.auth().currentUser;

		const batch = firestore.batch();
		batch.update(firestore.collection(handle + '/invoices/invoices').doc(invoiceId), { ...update, editedBy: user.email, modified: new Date() });

		batch.commit().then(() => {
			dispatch({ type: types['INVOICE'] + '_SAVE_FULFILLED' });
			window.toastr.success('The Invoice has been successfully saved/updated', 'Invoice Saved!');
			if (typeof callback === 'function') callback();

		}).catch((error) => {
			toolbox.process_error(error, 'Record NOT Saved!');
		});
	};
}
export function deleteInvoice(handle, invoice, callback) {

	return async dispatch => {

		dispatch({ type: types['INVOICE'] + '_SAVE_PENDING' });

		const user = firebase.auth().currentUser;
		const batch = firestore.batch();

		await firestore.collection(handle + '/invoices/invoices').doc(invoice.id).update({ editedBy: user.email });

		for (let workOrder of invoice.workOrders) {
			batch.update(firestore.collection(handle + '/work-orders/work-orders').doc(workOrder.id), { statusId: WORKORDERS.COMPLETE.id });
		}
		batch.delete(firestore.collection(handle + '/invoices/invoices').doc(invoice.id));

		batch.commit().then(() => {
			dispatch({ type: types['INVOICE'] + '_SAVE_FULFILLED' });
			window.toastr.success('The Invoice has been successfully deleted', 'Invoice Deleted!');
			if (typeof callback === 'function') callback();
		}).catch((error) => {
			toolbox.process_error(error, 'Record NOT Saved!');
		});
	};
}

/*-----------------------------------------------*/
/*  PAYMENTS
/*-----------------------------------------------*/

export function savePayment(handle, payment, paymentId, taxRates, creditsUsed, callback) {

	return async dispatch => {

		// dispatch({ type: types.PAYMENTS + '_SAVE_PENDING' });

		const batch = firestore.batch();
		const selectedInvoices = [...payment.invoices];
		const creditAmount = creditsUsed.reduce((a, b) => a + parseFloat(b.payment), 0);
		const unappliedAmount = parseFloat(payment.amount) + parseFloat(creditAmount) - selectedInvoices.reduce((a, b) => a + parseFloat(b.payment), 0);

		if (!paymentId) {
			paymentId = await nextPaymentId(handle, batch);
			payment.date = new Date();
			payment.deleted = false;
		}

		// Apply Credits first
		for (let creditPayment of creditsUsed) {

			var paymentCredit = parseFloat(creditPayment.payment);

			// Payment Object
			var paymentObject = {
				invoices: creditPayment.invoices,
				unapplied: creditPayment.unapplied
			};

			// Go through each invoice and apply the credit
			for (let inv of selectedInvoices) {
				// Break once credit is completely used
				if (paymentCredit == 0) break;

				var invoicePayment = parseFloat(inv.payment);
				const existingPayment = creditPayment.invoices.findIndex((i) => i.id == inv.id);

				// If the invoice payment is greater than the payment credit, apply the full payment credit to the invoice
				if (invoicePayment > paymentCredit) {
					inv.applied = (inv?.applied ?? 0) + paymentCredit;
					inv.payment = invoicePayment - paymentCredit;

					if (existingPayment != -1) {
						paymentObject.invoices[existingPayment].amount = (parseFloat(paymentObject.invoices[existingPayment].amount) + parseFloat(paymentCredit)).toFixed(2);
					} else {
						inv.payments.push(creditPayment.id);
						paymentObject.invoices.push({ id: inv.id, amount: parseFloat(paymentCredit).toFixed(2) });
					}
					paymentObject.unapplied -= paymentCredit;
					paymentCredit = 0;
				}
				// If the payment credit is greater than the invoice payment, apply the full invoice payment to the payment
				else if (paymentCredit > invoicePayment) {
					inv.applied = (inv?.applied ?? 0) + invoicePayment;
					inv.payment = 0;

					if (existingPayment != -1) {
						paymentObject.invoices[existingPayment].amount = (parseFloat(paymentObject.invoices[existingPayment].amount) + parseFloat(invoicePayment)).toFixed(2);
					} else {
						inv.payments.push(creditPayment.id);
						paymentObject.invoices.push({ id: inv.id, amount: parseFloat(invoicePayment).toFixed(2) });
					}
					paymentObject.unapplied -= invoicePayment;
					paymentCredit -= invoicePayment;
				}
				// If the payment credit is equal to the invoice payment, apply the full invoice payment to the payment
				else if (paymentCredit == invoicePayment) {
					inv.applied = (inv?.applied ?? 0) + invoicePayment;
					inv.payment = 0;
					if (existingPayment != -1) {
						paymentObject.invoices[existingPayment].amount = (parseFloat(paymentObject.invoices[existingPayment].amount) + parseFloat(invoicePayment)).toFixed(2);
					} else {
						inv.payments.push(creditPayment.id);
						paymentObject.invoices.push({ id: inv.id, amount: parseFloat(invoicePayment).toFixed(2) });
					}
					paymentObject.unapplied -= invoicePayment;
					paymentCredit = 0;
				}

				const index = selectedInvoices.findIndex((i) => i.id == inv.id);
				selectedInvoices[index] = inv;
			}
			batch.set(firestore.collection(handle + '/payments/payments').doc(creditPayment.id), { ...paymentObject, modified: new Date() }, { merge: true });
		}


		// Update selected invoices
		for (let inv of selectedInvoices) {

			const invoiceObject = {
				applied: parseFloat(inv.payment) + (inv?.applied ?? 0),
				payments: inv?.payments ?? [],
			};

			if (inv.payment > 0) invoiceObject.payments.push(paymentId);

			const totalAmount = inv.serviceItems.reduce((a, b) => {
				return a + ((b.taxable && inv.taxRateId) ? (((taxRates?.find((rate) => rate.rateId == inv.taxRateId).rate * parseFloat(b.calculatedPrice)) + parseFloat(b.calculatedPrice))) : parseFloat(b.calculatedPrice));
			}, 0);

			invoiceObject.statusId = (invoiceObject.applied >= totalAmount) ? INVOICES.PAID.id : INVOICES.PENDING.id;

			batch.set(firestore.collection(handle + '/invoices/invoices').doc(inv.id), { ...invoiceObject }, { merge: true });
		}

		// Save Payment Object
		var paymentObject = {
			...payment,
			invoices: selectedInvoices.filter((inv) => inv.payment).map((inv) => ({ id: inv.id, amount: inv.payment })),
			unapplied: unappliedAmount
		};
		if (payment.amount > 0) batch.set(firestore.collection(handle + '/payments/payments').doc(paymentId), { ...paymentObject, modified: new Date() });

		// Save Credit
		// Get all payments with unapplied credit
		const payments = await firestore.collection(handle + '/payments/payments').where('deleted', '==', false).where('unapplied', '>', 0).get();
		const currentCredit = payments?.docs?.filter((p) => p.id != paymentId)?.reduce((a, b) => a + parseFloat(b.data().unapplied), 0) ?? 0;
		const newCredit = currentCredit + unappliedAmount;
		batch.update(firestore.collection(handle + '/profiles/profiles').doc(payment.customerId), { credit: parseFloat(newCredit) });

		batch.commit().then(() => {
			dispatch({ type: types.PAYMENTS + '_SAVE_FULFILLED' });
			window.toastr.success('The Payment has been successfully received', 'Payment Received!');
			if (typeof callback === 'function') callback(paymentId);

		}).catch((error) => {
			toolbox.process_error(error, 'Payment NOT Received!');
		});
	};

	async function nextPaymentId(handle, batch) {
		const table = 'payments';
		const field = 'nextPaymentId';
		const startingId = 11000;

		return toolbox.nextId(handle, batch, table, field, startingId);
	}
}

export function deletePayment(handle, paymentId, taxRates, callback) {

	return async dispatch => {

		dispatch({ type: types.PAYMENTS + '_SAVE_PENDING' });

		const batch = firestore.batch();

		// Get payment doc
		var payment = await firestore.collection(handle + '/payments/payments').doc(paymentId).get();
		payment = { ...payment.data(), id: payment.id };

		// Get invoices paid by this payment, update their statusId and applied
		const invoicesPaid = payment.invoices;
		for (let inv of invoicesPaid) {
			// Get Invoice 
			var invoice = await firestore.collection(handle + '/invoices/invoices').doc(inv.id).get();
			invoice = { ...invoice.data(), id: invoice.id };
			var invoiceObject = {
				applied: parseFloat(invoice.applied) - parseFloat(inv.amount),
				payments: invoice.payments.filter((p) => p != paymentId),
			};
			const totalAmount = invoice.serviceItems.reduce((a, b) => {
				return a + ((b.taxable && invoice.taxRateId) ? (((taxRates?.find((rate) => rate.rateId == invoice.taxRateId).rate * parseFloat(b.calculatedPrice)) + parseFloat(b.calculatedPrice))) : parseFloat(b.calculatedPrice));
			}, 0);
			if (invoiceObject.applied < totalAmount) {
				invoiceObject.statusId = INVOICES.PENDING.id;
			};
			batch.set(firestore.collection(handle + '/invoices/invoices').doc(inv.id), invoiceObject, { merge: true });
		}

		// Delete payment
		batch.set(firestore.collection(handle + '/payments/payments').doc(paymentId), { deleted: true, modified: new Date() }, { merge: true });

		batch.commit().then(() => {
			dispatch({ type: types.PAYMENTS + '_SAVE_FULFILLED' });
			window.toastr.success('The Payment has been successfully deleted', 'Payment Deleted!');
			if (typeof callback === 'function') callback(paymentId);

		}).catch((error) => {
			toolbox.process_error(error, 'Payment NOT Deleted!');
		});
	};

	async function nextPaymentId(handle, batch) {
		const table = 'payments';
		const field = 'nextPaymentId';
		const startingId = 11000;

		return toolbox.nextId(handle, batch, table, field, startingId);
	}
}

export function subPaymentsByField(handle, field, value) {

	return dispatch => {

		dispatch({ type: types['PAYMENTS'] + '_PENDING' });

		var unsubscribe = firestore.collection(handle + '/payments/payments').where(field, (field == 'invoices') ? 'array-contains' : '==', value).where('deleted', '==', false).onSnapshot((querySnapshot) => {

			var payments = [];
			querySnapshot.forEach((doc) => {
				payments.push({ ...doc.data(), id: doc.id });
			});
			payments = _.orderBy(payments, ['modified'], ['desc']);

			dispatch({ type: types['PAYMENTS'] + '_FULFILLED', data: payments, unsubscribe });
		});
	};
}

export function clearPayments() {

	return dispatch => {

		dispatch({ type: types.PAYMENTS + '_CLEAR' });
	};
}