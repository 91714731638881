
import React, { Fragment } from 'react';
import _ from 'lodash';
const isDev = (process.env.REACT_APP_FIREBASE_ENV == 'development');

export function other_fields() {
    return (
        {
            profileId: '',
            description: '',
        }
    );
}

export function other_form_layout(handle, customer, show_customer_form, isComplete) {

    if (customer && Object.keys(customer).length) var customer_option = <option value={customer.id}>{customer.displayName}</option>;

    return (
        [{
            column_class: 'col-md-12',
            body: [{
                section:
                    <Fragment>
                        <h3 className="m-t-none m-b">Other Information</h3>
                    </Fragment>,
                layout: [
                    {
                        grid: 'col-12',
                        label: 'Call Time',
                        field: 'callTime',
                        type: 'date',
                        readOnly: true,
                        dateFormat: 'MM-dd-yyyy h:mm a',
                        showTimeSelect: true,
                        valid: ['required'],
                        selectsStart: true,
                    }, {
                        grid: 'col-12',
                        label: 'Customer',
                        field: 'profileId',
                        allow_new: true,
                        placeholder: 'Search for Customer',
                        table: handle + (isDev ? '-dev' : '') + '-profiles',
                        filter: 'customer',
                        fields: ['displayName', 'addresses', 'contacts'],
                        sort: [{ "modified": "desc" }, { "displayName": "asc" }],
                        target: 'displayName',
                        id: 'id',
                        type: (show_customer_form || customer_option) ? 'skip' : 'typeahead',
                        valid: ['required'],
                    }, {
                        grid: 'col-12',
                        label: 'Customer',
                        field: '_customerName',
                        type: (!customer_option) ? 'skip' : 'text',
                        clearButton: (isComplete) ? false : true,
                        valid: ['required'],
                        disabled: true,
                    }, {
                        grid: 'col-12',
                        label: 'Description',
                        field: 'description',
                        type: 'textarea',
                        disabled: (isComplete) ? true : false,
                    }],
            },]
        }]
    );
}