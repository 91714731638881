import * as types from 'actions/action-types';

var initialState = {
	attachment: [],
	attachment_pending: false,
	attachment_save_pending: false,
	attachment_unsubscribe: false,

	attachments: [],
	attachments_pending: false,
	attachments_save_pending: false,
	attachments_unsubscribe: false,

	quote_attachments: [],
	quote_attachments_pending: false,
	quote_attachments_save_pending: false,
	quote_attachments_unsubscribe: false,

	work_order_attachments: [],
	work_order_attachments_pending: false,
	work_order_attachments_save_pending: false,
	work_order_attachments_unsubscribe: false,
};

const attachmentsReducer = (state = initialState, action) => {

	switch(action.type) {
		case types.ATTACHMENT + '_PENDING': pending('attachment'); break;
		case types.ATTACHMENT + '_SAVE_PENDING': save_pending('attachment'); break;
		case types.ATTACHMENT + '_SAVE_FULFILLED': save_fulfilled('attachment'); break;
		case types.ATTACHMENT + '_FULFILLED': fulfilled('attachment'); break;
		case types.ATTACHMENT + '_CLEAR': state = { ...state, attachment: [] }; break;

		case types.ATTACHMENTS + '_PENDING': pending('attachments'); break;
		case types.ATTACHMENTS + '_SAVE_PENDING': save_pending('attachments'); break;
		case types.ATTACHMENTS + '_FULFILLED': fulfilled('attachments'); break;
		case types.ATTACHMENTS + '_CLEAR': state = { ...state, attachments: [] }; break;

		case types.QUOTE_ATTACHMENTS + '_PENDING': pending('quote_attachments'); break;
		case types.QUOTE_ATTACHMENTS + '_FULFILLED': fulfilled('quote_attachments'); break;
		case types.QUOTE_ATTACHMENTS + '_CLEAR': state = { ...state, quote_attachments: [] }; break;

		case types.WORK_ORDER_ATTACHMENTS + '_PENDING': pending('work_order_attachments'); break;
		case types.WORK_ORDER_ATTACHMENTS + '_FULFILLED': fulfilled('work_order_attachments'); break;
		case types.WORK_ORDER_ATTACHMENTS + '_CLEAR': state = { ...state, work_order_attachments: [] }; break;

		default : break;
	}

	return state;

	function pending(table) {
		state = { ...state,
			[table + '_pending']: true,
		};
	}
	function fulfilled(table) {
		state = { ...state,
			[table]: action.data,
			[table + '_pending']: false,
			[table + '_save_pending']: false,
			[table + '_unsubscribe']: (action.unsubscribe) ? action.unsubscribe : state[table + '_unsubscribe'],
		};
	}
	function save_pending(table) {
		state = { ...state,
			[table + '_save_pending']: true,
		};
	}
	function save_fulfilled(table) {
		state = { ...state,
			[table + '_save_pending']: false,
		};
	}
}
export default attachmentsReducer;


