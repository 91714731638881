import * as actions_authentication from './authentication-actions';
import React, { useEffect, useState } from 'react'
import { Avatar } from 'enspire-manager-framework';
import { Base64 } from 'js-base64';
import { Spinner } from 'enspire-manager-framework';

import { getAuth, signInWithRedirect } from "firebase/auth";
import { GoogleAuthProvider, OAuthProvider } from "firebase/auth";

const googleProvider = new GoogleAuthProvider();
const appleProvider = new OAuthProvider('apple.com');


export const Login = (props) => {

    const firebase = props.firebase;
	const params = props.params;
	const history = props.history;
    const dispatch = props.dispatch;
    const [state, setState] = useState({
        email: '',
        password: '',
        loading: false,
        authorizing: false,
        company: {},
    });

    useEffect(() => {
        setState(prevState => ({ ...prevState, loading: true }));
        
        firebase.firestore().collection('_companies').doc(params.handle).get().then((companyDoc) => {
            setState(prevState => ({ ...prevState, loading: false }));
            if (companyDoc.exists) {
                setState(prevState => ({ ...prevState, company: { ...companyDoc.data(), id: companyDoc.id }}));
                if (params.base64) {
                    let auth64 = Base64.decode(params.base64);
                    let auth = auth64.split('|');
                    setState(prevState => ({ ...prevState, email: auth[0], password: auth[1] }));
                    login(auth[0], auth[1]);

                } else {
    
                    var user = firebase.auth().currentUser;
                    if (user) {
                        history.push(props.landing);
                    }
                }
            } else {
                window.toastr.error('This Company ID cannot be found.  Please check for errors and try again.', 'Not Found');
                history.push('/');
            }
        }).catch((error) => {
            console.error(error);
            history.push('/');
        });
    }, [params.handle]);

	const handleChange = (event) => {
        if (event.target.name == 'email') event.target.value = event.target.value.toLowerCase();
		setState(prevState =>({ ...prevState, [event.target.name]: event.target.value }));
	}
	const submitForm = (event) => {
		if (event) event.preventDefault();
        login(state.email, state.password);
	}
    const login = (email, password) => {
        setState(prevState => ({ ...prevState, authorizing: true }));
        dispatch(actions_authentication.login(email, password, firebase, () => {
            setTimeout(() => {
                setState(prevState => ({ ...prevState, authorizing: false }));
            }, 1500);
        }));
    }
    const googleLogin = () => {
        if (window.flutter) {
            window.flutter.callHandler('googleLogin', true); 
        } else {
            const auth = getAuth();
            googleProvider.setCustomParameters({ prompt: 'select_account' });
            signInWithRedirect(auth, googleProvider);
        }
    }
    const appleLogin = () => {
        if (window.flutter) {
            window.flutter.callHandler('appleLogin', true); 
        } else {
            const auth = getAuth();
            signInWithRedirect(auth, appleProvider);
        }
    }

    return (

        <div id="wrapper" className="gray-bg" style={ { paddingBottom: '300px' } }>

            <div className="middle-box text-center loginscreen animated fadeInDown">
                <div>
                    { state.loading
                        ?	<div style={ { margin: '60px 0' } }>
                                <Spinner />
                            </div>
                        : 	<>
                                { state.company
                                    ? <div style={ { margin: '20px 0' } }>
                                        { state.company.logoUrl
                                            ? <img src={ state.company.logoUrl } width="100%" alt={ state.company.companyName + ' Logo' } />
                                            : <Avatar
                                                className="mx-auto"
                                                color="white"
                                                bgColor="saddleBrown"
                                                size={250}
                                                name={state.company.handle}
                                                initials={false}
                                                border="8px solid white"
                                            />
                                        }
                                        <h2>{ state.company.companyName }</h2>
                                    </div>
                                    : <div style={ { margin: '20px 0' } }>
                                        <img src={ 'images/logo.png' } width="100%" alt="Mobile Track Logo" />
                                    </div>
                                }
                            </>
                    }

                    <form className="login-form" style={ { marginTop: '40px' } } onSubmit={ submitForm }>
                        <div className="form-group">
                            <input type="email" name="email" className="form-control" placeholder="Email Address" inputMode="email" value={ state.email } onChange={ handleChange } />
                        </div>
                        <div className="form-group">
                            <input type="password" name="password" className="form-control" placeholder="Password" value={ state.password } onChange={ handleChange } autoComplete="off" />
                        </div>
                        { !state.authorizing
                            ? <button type="submit" className="btn btn-success block full-width m-b mt-4" disabled={ state.handle_pending }>LOGIN</button>
                            : <div style={ { margin: '15px 0' } }><Spinner /></div>
                        }

                        <div className="line-through mb-3">SIGN IN WITH</div>
                        <div className="row no-gutters">
                            <div className="col">
                                <a className="btn btn-outline-dark block mr-1" onClick={googleLogin}>
                                    <img width="20px" style={{ marginBottom: '3px', marginRight: '10px' }}  src="/images/google-logo-60.png" />
                                    Google
                                </a>
                            </div>
                            <div className="col">
                                <a className="btn btn-outline-dark block ml-1" onClick={appleLogin}>
                                    <img width="20px" style={{ marginBottom: '3px', marginRight: '10px' }}  src="/images/apple-logo-60.png" />
                                    Apple
                                </a>
                            </div>
                        </div><br/>
              
                        <a onClick={ () => { history.push('/' + params.handle + '/register_user'); }}>Register a New User</a> &nbsp; | &nbsp;
                        <a onClick={ () => { history.push('/' + params.handle + '/password_reset'); }}>Forgot password?</a>
                        <br/><br/>
                        <a onClick={ () => { history.push('/'); }} disabled={ state.handle_pending }>Login to a different Company Id</a>

                        <a href={'/#/' + params.handle + '/account_delete'} className="btn btn-danger btn-outline block full-width m-b mt-5" disabled={ state.handle_pending }>DELETE ACCOUNT</a>

                    </form>
                </div>
            </div>

        </div>
    );
};
