import React, { useEffect, useState } from 'react';
import { Ibox, Table, ModalAlert } from 'enspire-manager-framework';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import * as actions_call_log from 'actions/call-log-actions';
import * as actions_profiles from 'actions/profiles-actions';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { customer_fields } from 'components/customers/customer/customer-form/customer-form-layout';
import { service_request_fields } from 'components/service-requests/service-request/service-request-form/service-request-form-layout';
import { transfer_fields } from 'components/call-center/transfer-form-layout';
import { other_fields } from 'components/call-center/other-form-layout';
import { toProperCase } from 'components/common/toolbox';
import _ from 'lodash';
import { CALL_LOG } from 'components/common/constants';

const ActiveCalls = (props) => {

    /* Hooks --------------------------*/

    const params = useParams();
    const dispatch = useDispatch();
    const users = useSelector((store) => store.users);
    const employees = useSelector((store) => store.employees.employees);
    const setState = props?.setState;
    const state = props?.state;
    var today = new Date();
    today.setHours(0, 0, 0, 0);
    var weekAgo = new Date();
    weekAgo.setDate(weekAgo.getDate() - 7);
    weekAgo.setHours(0, 0, 0, 0);
    const callLog = useSelector((store) => store.callLog.call_log);
    const [data, setData] = useState();
    /* Effects --------------------------*/

    useEffect(() => {
        setData(_.cloneDeep(callLog)?.filter((o) => o.statusId == CALL_LOG.INCOMPLETE.id || (o.callTime.toDate() > today && o.statusId != CALL_LOG.CANCELLED.id))?.map((o) => ({
            ...o,
            callTimeDisplay: (o.callTime.toDate() > weekAgo)
                ? moment.duration(moment(o.callTime?.toDate()).diff(moment())).humanize(true)
                : moment(o.callTime?.toDate()).format('MM-DD-YYYY, h:mm:ss a'),
            type: (o.type == 'transfer')
                ? `Transferred to: <br/><strong>${employees?.find((u) => u.id == o.technicianId)?.firstName + ' ' + employees?.find((u) => u.id == o.technicianId)?.lastName}</strong>`
                : (o.statusId == CALL_LOG.TRANSFERRED.id)
                    ? `Transferred from: <br/><strong>${employees?.find((u) => u.id == o.technicianId)?.firstName + ' ' + employees?.find((u) => u.id == o.technicianId)?.lastName}</strong>`
                    : toProperCase(o.type.replace('_', ' ')),
            customer: (!o.profileId && o.customer)
                ? 'Creation In Progress'
                : (o?._customerName)
                    ? o._customerName
                    : '',
            category: (o.statusId == CALL_LOG.COMPLETE.id)
                ? `Today's Complete Calls`
                : `All Incomplete Calls`,
            _no_delete: (o.statusId == CALL_LOG.COMPLETE.id),
            callDuration: (o.statusId == CALL_LOG.COMPLETE.id) ? getDurationString(o.callTime, o.endTime) : 'In Progress'
        })));
    }, [callLog, employees]);

    /* Handlers --------------------------*/

    const handleButton = () => {
        if (state.edited) {
            var call = {
                ...state?.[state.tab], userId: users.user.id, statusId: CALL_LOG.INCOMPLETE.id, type: state.tab
            };
            if (state.show_customer_form) call.customer = { ...state.customer };
            else call.customer = null;
            call.id = call.callId;
            delete call.callId;

            if (state.tab == "transfer") {
                if (!call.profileId || !call.technicianId) {
                    resetState();
                    return;
                } else call.statusId = CALL_LOG.COMPLETE.id;
            }

            dispatch(actions_profiles.clearCustomerFromSearch());
            dispatch(actions_call_log.saveCallLog(params.handle, call, () => {
                resetState();
            }));
        } else {
            dispatch(actions_profiles.clearCustomerFromSearch());
            resetState();
        }
    };

    const handleClick = (row) => {
        if (state.edited) {
            var call = {
                ...state?.[state.tab], userId: users.user.id, statusId: CALL_LOG.INCOMPLETE.id, type: state.tab
            };
            if (state.show_customer_form) call.customer = { ...state.customer };
            else call.customer = null;
            call.id = call.callId;
            delete call.callId;

            if (state.tab == "transfer") {
                if (!call.profileId || !call.technicianId) {
                    loadCall(row);
                    return;
                } else call.statusId = CALL_LOG.COMPLETE.id;
            }

            dispatch(actions_profiles.clearCustomerFromSearch());
            dispatch(actions_call_log.saveCallLog(params.handle, call, () => {
                loadCall(row);
            }));
        } else loadCall(row);
    };

    const handleDelete = (item) => {
        item.id = item.callId;
        item.statusId = CALL_LOG.CANCELLED.id;
        delete item.callId;
        delete item.callTimeDisplay;
        delete item._no_delete;
        delete item.category;

        dispatch(actions_profiles.clearCustomerFromSearch());
        dispatch(actions_call_log.saveCallLog(params.handle, item));
    };

    /* Actions --------------------------*/

    const getDurationString = (startTimestamp, endTimestamp) => {
        const startTime = moment(startTimestamp?.toDate());
        const endTime = moment(endTimestamp?.toDate());
        const duration = moment.duration(endTime.diff(startTime));
        const minutes = duration.minutes().toString().padStart(2, '0');
        const seconds = duration.seconds().toString().padStart(2, '0');
        return `${minutes}m:${seconds}s`;
    };

    const confirmDelete = (item) => {
        ModalAlert({
            title: 'Are you sure?',
            text: "This call will be cancelled.",
            icon: 'warning',
            show_cancel: true,
            confirm_color: '#8FBC8B',
            confirm_text: 'Yes, archive it!',
            callback_success: () => handleDelete(item),
        });
    };

    const resetState = () => {
        setState(prev => ({
            tab: prev.tab,
            edited: false,
            populated: false,
            isAddressIdSet: false,
            form_error: null,
            show_customer_form: false,
            customer: customer_fields(),
            transfer: { ...transfer_fields(), callTime: new Date() },
            other: { ...other_fields(), callTime: new Date() },
            service_request: { ...service_request_fields(), callTime: new Date() },
            expand: false,
            isLoading: false,
            search: '',
            options: [],
            isEndDateSet: false,
        }));
    };
    const loadCall = (row) => {
        const rawData = callLog.find((o) => o.callId == row.callId);

        const emptyState = {
            transfer: { ...transfer_fields() },
            other: { ...other_fields() },
            service_request: { ...service_request_fields() },
            callId: row.callId
        };

        if (rawData.type == 'service_request') var newState = { ...emptyState, show_customer_form: false, service_request: { ...rawData, callTime: rawData.callTime?.toDate(), modified: rawData.modified?.toDate(), created: rawData.created?.toDate(), endDate: rawData.endDate?.toDate(), startDate: rawData.startDate?.toDate() } };
        else if (rawData.type == 'transfer') var newState = { ...emptyState, show_customer_form: false, transfer: { ...rawData, callTime: rawData.callTime.toDate() } };
        else if (rawData.type == 'other') var newState = { ...emptyState, show_customer_form: false, other: { ...rawData, callTime: rawData.callTime.toDate() } };

        if (!rawData.profileId && rawData.customer) newState = { ...newState, customer: { ...rawData.customer }, show_customer_form: true };
        dispatch(actions_profiles.clearCustomerFromSearch());
        setState(prev => ({ ...prev, ...newState, edited: false, tab: rawData.type }));
        if (row?.profileId) dispatch(actions_profiles.getCustomerFromSearch(params.handle, row?.profileId));
    };

    /* Constants --------------------------*/

    const columns = [
        { name: 'Call Time', field: 'callTimeDisplay', width: 100 / 4 },
        { name: 'Type', field: 'type', width: 100 / 4 },
        { name: 'Customer', field: 'customer', width: 100 / 4 },
        { name: 'Duration', field: 'callDuration', width: 100 / 4 },
    ];

    return (
        <Ibox
            title={`${users.user.firstName}'s Calls`}
            styleContent={{ paddingLeft: 0, paddingRight: 0 }}
            className={'m-0'}
        >
            <Table
                click_callback={handleClick}
                data={data}
                columns={columns}
                active_field={'callId'}
                active_id={parseInt(state?.[state.tab]?.callId)}
                button="+ Call"
                button_in_ibox={true}
                button_callback={handleButton}
                delete={true}
                on_delete={confirmDelete}
                group_by={{ fields: ['category'], direction: ['asc'] }}
                order_by={{ fields: ['callTime'], direction: ['desc'] }}
                container_id={'container10'}
                container_width_id={'width1'}
                container_margin={140}
            />
        </Ibox>
    );
};

export default ActiveCalls;