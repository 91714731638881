import DatePicker from "react-datepicker";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import React, { useEffect, useState } from 'react';
import { Portal } from "react-overlays";
import { Select } from 'enspire-manager-framework';

var _ = require('lodash');
var moment = require('moment');

export default function DateRange(props) {

    /* Hooks --------------------------*/

    const [state, setState] = useState({
        showOverlay: false,
        startDate: moment().subtract(60, 'days').startOf('day').toDate(),
        endDate: moment().endOf('day').toDate(),
        rangeName: 'null',
        dropdown: 'invoices'
    });

    /* Effects --------------------------*/

    useEffect(() => {
        handlePreset((props.range) ? props.range : 'last30');
    }, []);

    /* Handlers --------------------------*/

    const handleDate = (name, date) => {
        setState(prev => ({ ...prev, [name]: date, fetched: false, rangeName: props.buttonTitle }));
    };
    const handleToggle = (showOverlay) => {
        setState(prev => ({ ...prev, showOverlay: showOverlay }));
    };
    const handleSubmit = () => {
        if (props.callback) props.callback(state.startDate, state.endDate);
        setState(prev => ({ ...prev, showOverlay: false }));
    };
    const handlePreset = (value) => {
        var startDate;
        var endDate;
        var rangeName;

        switch (value) {
            case 'thisWeek':
                startDate = moment().startOf('week').toDate();
                endDate = moment().endOf('week').toDate();
                rangeName = 'This Week';
                break;
            case 'lastWeek':
                startDate = moment().subtract(1, 'week').startOf('week').toDate();
                endDate = moment().subtract(1, 'week').endOf('week').toDate();
                rangeName = 'Last Week';
                break;
            case 'thisMonth':
                startDate = moment().startOf('month').toDate();
                endDate = moment().endOf('month').toDate();
                rangeName = 'This Month';
                break;
            case 'lastMonth':
                startDate = moment().subtract(1, 'month').startOf('month').toDate();
                endDate = moment().subtract(1, 'month').endOf('month').toDate();
                rangeName = 'Last Month';
                break;
            case 'last30':
                startDate = moment().subtract(30, 'days').startOf('day').toDate();
                endDate = moment().endOf('day').toDate();
                rangeName = 'Last 30 Days';
                break;
            case 'last60':
                startDate = moment().subtract(60, 'days').startOf('day').toDate();
                endDate = moment().endOf('day').toDate();
                rangeName = 'Last 60 Days';
                break;
            case 'last90':
                startDate = moment().subtract(90, 'days').startOf('day').toDate();
                endDate = moment().endOf('day').toDate();
                rangeName = 'Last 90 Days';
                break;
            case 'last120':
                startDate = moment().subtract(120, 'days').startOf('day').toDate();
                endDate = moment().endOf('day').toDate();
                rangeName = 'Last 120 Days';
                break;
            case 'last6Months':
                startDate = moment().subtract(6, 'months').startOf('day').toDate();
                endDate = moment().endOf('day').toDate();
                rangeName = 'Last 6 Months';
                break;
            case 'last12Months':
                startDate = moment().subtract(12, 'months').startOf('day').toDate();
                endDate = moment().endOf('day').toDate();
                rangeName = 'Last 12 Months';
                break;
            case 'thisYear':
                startDate = moment().startOf('year').toDate();
                endDate = moment().endOf('year').toDate();
                rangeName = 'This Year';
                break;
            case 'yeartodate':
                startDate = moment().startOf('year').toDate();
                endDate = moment().endOf('day').toDate();
                rangeName = 'This Year to Date';
                break;
        }
        setState(prev => ({ ...prev, startDate, endDate, rangeName, showOverlay: false }));
    };

    /* Constants --------------------------*/

    const CalendarContainer = ({ children }) => {
        return <Portal container={props.el}>{children}</Portal>;
    };

    var rangeWidth = 12;
    if (props.showPresets) rangeWidth -= 4;

    const renderTooltip = (
        <div className="dropdown-menu">
            <a className="dropdown-item" onClick={handlePreset.bind(this, 'thisWeek', 'This Week')}>This Week</a>
            <a className="dropdown-item" onClick={handlePreset.bind(this, 'lastWeek', 'Last Week')}>Last Week</a>
            <a className="dropdown-item" onClick={handlePreset.bind(this, 'thisMonth', 'This Month')}>This Month</a>
            <a className="dropdown-item" onClick={handlePreset.bind(this, 'lastMonth', 'Last Month')}>Last Month</a>
            <a className="dropdown-item" onClick={handlePreset.bind(this, 'last60', 'Last 60 Days')}>Last 60 Days</a>
            <a className="dropdown-item" onClick={handlePreset.bind(this, 'last90', 'Last 90 Days')}>Last 90 Days</a>
            <a className="dropdown-item" onClick={handlePreset.bind(this, 'last120', 'Last 120 Days')}>Last 120 Days</a>
            <a className="dropdown-item" onClick={handlePreset.bind(this, 'last6Months', 'Last 6 Months')}>Last 6 Months</a>
            <a className="dropdown-item" onClick={handlePreset.bind(this, 'last12Months', 'Last 12 Months')}>Last 12 Months</a>
            <a className="dropdown-item" onClick={handlePreset.bind(this, 'thisYear', 'This Year')}>This Year</a>
            <a className="dropdown-item" onClick={handlePreset.bind(this, 'yeartodate', 'Year to Date')}>Year to Date</a>
        </div>
    );

    return (

        <div>
            <div className={'row animated fadeInRight animation-delay-4 ' + props.className}>
                <div className={'col-' + rangeWidth + ' input-group input-group-sm date'}>
                    <div className="input-group-prepend" style={{ height: '31px' }}>
                        <span className="input-group-text input-group-addon"><i className="far fa-calendar-alt"></i></span>
                    </div>

                    <DatePicker
                        className="form-control form-control-sm"
                        dateFormat="MM-dd-yyyy"
                        selected={state.startDate}
                        minDate={new Date(Date.now() - (365 * 24 * 60 * 60 * 1000))}
                        startDate={state.startDate}
                        endDate={state.endDate}
                        onChange={(date) => handleDate('startDate', date)}
                        // placeholderText="Select a Week to Render"
                        popperContainer={CalendarContainer}
                    />
                    <DatePicker
                        className="form-control form-control-sm"
                        dateFormat="MM-dd-yyyy"
                        selectsEnd
                        selected={state.endDate}
                        startDate={state.startDate}
                        minDate={state.startDate}
                        maxDate={new Date()}
                        endDate={state.endDate}
                        onChange={(date) => handleDate('endDate', date)}
                        // placeholderText="Select a Week to Render"
                        popperContainer={CalendarContainer}
                    />
                </div>
                <div className={'col d-flex justify-content-between'}>
                    {props.showPresets

                        ? <div className="btn-group">
                            <button type="button" className="btn btn-primary" onClick={handleSubmit.bind(this)}>
                                {state.rangeName}
                                {props.rendering &&
                                    <span className="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true"></span>
                                }
                            </button>
                            <OverlayTrigger
                                trigger={'click'}
                                placement="bottom"
                                overlay={renderTooltip}
                                show={state.showOverlay}
                                onToggle={handleToggle.bind(this)}
                            >
                                <button type="button" className="btn btn-primary dropdown-toggle dropdown-toggle-split">
                                    <span className="sr-only">Toggle Dropdown</span>
                                </button>
                            </OverlayTrigger>
                        </div>
                        : <button className={'btn btn-primary btn-xs'} onClick={handleSubmit.bind(this)}>
                            {props.buttonTitle}
                            {props.rendering &&
                                <span className="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true"></span>
                            }
                        </button>
                    }
                    <Select
                        className={"py-0 my-0 ml-3 align-self-end"}
                        noLabel={true}
                        name={'status'}
                        onChange={(e) => setState(prev => ({ ...prev, dropdown: e.target.value }))}
                        value={state.dropdown}
                    >
                        <option value="invoices">Invoices</option>
                        <option value="quotes">Quotes</option>
                    </Select>
                </div>
            </div>

            {props.children &&
                <div>
                    {(!Array.isArray(props.children)) ? React.cloneElement(props.children, { startDate: state.startDate, endDate: state.endDate, type: state.dropdown }) : <></>}
                    {(props.children?.[0]) ? React.cloneElement(props.children?.[0], { startDate: state.startDate, endDate: state.endDate, type: state.dropdown }) : <></>}
                    {(props.children?.[1]) ? React.cloneElement(props.children?.[1], { startDate: state.startDate, endDate: state.endDate, type: state.dropdown }) : <></>}
                    {(props.children?.[2]) ? React.cloneElement(props.children?.[2], { startDate: state.startDate, endDate: state.endDate }) : <></>}
                    {(props.children?.[3]) ? React.cloneElement(props.children?.[3], { startDate: state.startDate, endDate: state.endDate }) : <></>}
                </div>
            }
        </div>
    );
};
