import * as toolbox from 'components/common/toolbox';
import * as types from './action-types';
import firebase from 'firebase/compat/app';
import { QUOTES, REQUESTS, SCHEDULE_EVENT_TYPE, SERVICE_REQUEST_TERMS, WORKORDERS } from 'components/common/constants';

const firestore = firebase.firestore();
const _ = require('lodash');
var moment = require('moment');
const axios = require('axios');

/*-----------------------------------------------*/
/*  SERVICE REQUESTS
/*-----------------------------------------------*/

export function subServiceRequestsByProfileId(handle, id) {

	return dispatch => {

		dispatch({ type: types.SERVICE_REQUESTS + '_PENDING' });

		var unsubscribe = firestore.collection(handle + '/service-requests/service-requests').where('profileId', '==', id).where('deleted', '==', false).onSnapshot((querySnapshot) => {

			var requests = [];
			querySnapshot.forEach((doc) => {
				requests.push({ ...doc.data(), id: doc.id });
			});
			requests = _.orderBy(requests, ['created'], ['desc']);

			dispatch({ type: types.SERVICE_REQUESTS + '_FULFILLED', data: requests, unsubscribe });
		});
	};
}
export function subProjects(handle) {
	return dispatch => {

		dispatch({ type: types.PROJECTS + '_PENDING' });

		var unsubscribe = firestore.collection(handle + '/service-requests/service-requests').where('serviceRequestTermsId', '==', SERVICE_REQUEST_TERMS.PROJECT_QUOTE.id).where('deleted', '==', false).onSnapshot((querySnapshot) => {

			var requests = [];
			querySnapshot.forEach((doc) => {
				requests.push({ ...doc.data(), id: doc.id });
			});
			requests = _.orderBy(requests, ['created'], ['desc']);

			dispatch({ type: types.PROJECTS + '_FULFILLED', data: requests, unsubscribe });
		});
	};
}
export function subServiceRequest(handle, id) {

	return dispatch => {

		dispatch({ type: types.SERVICE_REQUEST + '_PENDING' });

		var unsubscribe = firestore.collection(handle + '/service-requests/service-requests').doc(id).onSnapshot((doc) => {
			var request = { ...doc.data(), id: doc.id };

			dispatch({ type: types.SERVICE_REQUEST + '_FULFILLED', data: request, unsubscribe });
		});
	};
}
export function clearServiceRequest() {

	return dispatch => {

		dispatch({ type: types.SERVICE_REQUEST + '_CLEAR' });
	};
}
export function updateServiceRequest(handle, serviceRequest, callback) {

	return async dispatch => {

		dispatch({ type: types.SERVICE_REQUEST + '_SAVE_PENDING' });

		const batch = firestore.batch();
		var serviceRequestId = serviceRequest.id;
		delete serviceRequest.id;
		serviceRequest.modified = new Date();

		batch.update(firestore.collection(handle + '/service-requests/service-requests').doc(serviceRequestId), { ...serviceRequest });

		batch.commit().then(() => {
			dispatch({ type: types.SERVICE_REQUEST + '_SAVE_FULFILLED' });
			window.toastr.success('The Service Request has been successfully saved/updated', 'Quote Saved!');
			if (typeof callback === 'function') callback();

		}).catch((error) => {
			toolbox.process_error(error, 'Record NOT Saved!');
		});
	};
}
export function convertToServiceCall(handle, serviceRequest, workOrder, quote, callback) {

	return async dispatch => {

		dispatch({ type: types.SERVICE_REQUEST + '_SAVE_PENDING' });

		const batch = firestore.batch();
		var serviceRequestId = serviceRequest.id;
		delete serviceRequest.id;
		serviceRequest.modified = new Date();

		// Copy line items to work Order
		batch.set(firestore.collection(handle + '/work-orders/work-orders/' + workOrder.id + '/serviceItems').doc(workOrder.id), { serviceItems: quote?.serviceItems });
		// Delete quote 
		batch.delete(firestore.collection(handle + '/quotes/quotes').doc(quote?.id));

		// Switch to Service Call
		batch.update(firestore.collection(handle + '/service-requests/service-requests').doc(serviceRequestId), { ...serviceRequest, serviceRequestTermsId: SERVICE_REQUEST_TERMS.SERVICE_CALL.id });

		batch.commit().then(() => {
			dispatch({ type: types.SERVICE_REQUEST + '_SAVE_FULFILLED' });
			window.toastr.success('The Service Request has been successfully saved/updated', 'Service Request Saved!');
			if (typeof callback === 'function') callback();

		}).catch((error) => {
			toolbox.process_error(error, 'Record NOT Saved!');
		});
	};
}
export function convertToEstimate(handle, serviceRequest, callback) {

	return async dispatch => {

		dispatch({ type: types.SERVICE_REQUEST + '_SAVE_PENDING' });

		const batch = firestore.batch();
		var serviceRequestId = serviceRequest.id;
		delete serviceRequest.id;
		serviceRequest.modified = new Date();

		// Switch to Estimate
		batch.update(firestore.collection(handle + '/service-requests/service-requests').doc(serviceRequestId), { ...serviceRequest, serviceRequestTermsId: SERVICE_REQUEST_TERMS.ESTIMATE.id });

		batch.commit().then(() => {
			dispatch({ type: types.SERVICE_REQUEST + '_SAVE_FULFILLED' });
			window.toastr.success('The Service Request has been successfully saved/updated', 'Service Request Saved!');
			if (typeof callback === 'function') callback();

		}).catch((error) => {
			toolbox.process_error(error, 'Record NOT Saved!');
		});
	};
}

export function saveServiceRequest(handle, request, callback) {

	return async dispatch => {

		dispatch({ type: types.SERVICE_REQUEST + '_SAVE_PENDING' });

		const user = firebase.auth().currentUser;
		const batch = firestore.batch();
		var serviceRequestId = request.id;
		var technicianId = request.technicianId;

		if (!parseInt(serviceRequestId)) {
			serviceRequestId = await nextServiceRequestId(handle, batch);
			var customId = await nextCustomId(handle, batch);
			var doc = await firestore.collection(handle + '/profiles/profiles').doc(request.profileId).get();
			request._displayName = doc.data()['displayName'];
			request.addressId = request.addressId;
			request.customId = customId;
			request.isTimeSet = true;
			request.statusId = (technicianId) ? REQUESTS.ASSIGNED.id : REQUESTS.UNASSIGNED.id;
			request.serviceRequestTermsId = (request.isServiceCall) ? SERVICE_REQUEST_TERMS.SERVICE_CALL.id : SERVICE_REQUEST_TERMS.ESTIMATE.id;
			request.deleted = false;
			request.created = new Date();

			var quote = {};
			if (request.serviceRequestTermsId == SERVICE_REQUEST_TERMS.ESTIMATE.id) {
				var quoteId = await nextQuoteId(handle, batch);
				quote._displayName = request._displayName;
				quote.customId = request.customId;
				quote.serviceRequestId = serviceRequestId;
				quote.profileId = request.profileId;
				quote.addressId = request.addressId;
				quote.summary = request.summary;
				quote.startDate = request.startDate;
				quote.endDate = request.endDate;
				quote.description = request.description;
				quote.serviceItems = [];
				quote.statusId = QUOTES.DRAFT.id;
				quote.options = ['1'];
				quote.approvedOption = '1';
				quote.estimatorId = '';
				quote.expiration = moment().add(30, 'days').toDate();
				quote.deleted = false;
				quote.editedBy = user.email;
				quote.created = new Date();
				quote.modified = new Date();

				batch.set(firestore.collection(handle + '/quotes/quotes').doc(quoteId), { ...quote });
			}

			var workOrder = {};
			var workOrderId = await nextWorkOrderId(handle, batch);
			workOrder.profileId = request.profileId;
			workOrder._displayName = request._displayName;
			workOrder.serviceRequestId = serviceRequestId;
			workOrder._serviceRequestTermsId = (request.isServiceCall) ? SERVICE_REQUEST_TERMS.SERVICE_CALL.id : SERVICE_REQUEST_TERMS.ESTIMATE.id;
			workOrder.customId = request.customId;
			workOrder.description = request.description;
			workOrder.startDate = request.startDate;
			workOrder.endDate = request.endDate;
			workOrder.geopoint = request.geopoint;
			workOrder.invoicingMemo = null;
			workOrder.technicianId = (technicianId) ? technicianId : 'unassigned';
			workOrder.statusId = (technicianId) ? WORKORDERS.ASSIGNED.id : WORKORDERS.UNASSIGNED.id;
			workOrder.userId = user.email;
			workOrder.deleted = false;
			workOrder.editedBy = user.email;
			workOrder.created = new Date();
			workOrder.modified = new Date();
			workOrder.type = SCHEDULE_EVENT_TYPE.WORKORDER.id;
			workOrder.dates = scheduleDateArray(moment(request.startDate), moment(request.endDate));

			batch.set(firestore.collection(handle + '/work-orders/work-orders').doc(workOrderId), { ...workOrder });
		}

		delete request.technicianId;
		delete request.id;
		delete request.servicecall;
		delete request.estimate;
		request.editedBy = user.email;
		request.modified = new Date();

		batch.set(firestore.collection(handle + '/service-requests/service-requests').doc(serviceRequestId), { ...request });

		batch.commit().then(() => {
			dispatch({ type: types.SERVICE_REQUEST + '_SAVE_FULFILLED' });
			window.toastr.success('The Service Request has been successfully saved/updated', 'Service Request Saved!');
			if (typeof callback === 'function') callback(serviceRequestId, workOrderId);

		}).catch((error) => {
			toolbox.process_error(error, 'Record NOT Saved!');
		});
	};
}
export function deleteServiceRequest(handle, serviceRequest, callback) {

	return async dispatch => {

		dispatch({ type: types.SERVICE_REQUEST + '_SAVE_PENDING' });

		const user = firebase.auth().currentUser;
		var batch = firestore.batch();

		const serviceRequestRef = firestore.collection(handle + '/service-requests/service-requests');
		batch.set(serviceRequestRef.doc(serviceRequest.id), { deleted: true, userId: user.email, modified: new Date() }, { merge: true });

		batch.commit().then(() => {
			dispatch({ type: types.SERVICE_REQUEST + '_SAVE_FULFILLED' });
			window.toastr.success('The Service Request has been Archived', 'Service Request Archived!');
			if (typeof callback === 'function') callback();
		}).catch((error) => {
			toolbox.process_error(error, 'Service Request NOT Archived!');
		});
	};
}
export function getServiceRequestMapCoordinates(address) {

	const map_address = address.addressLine1 + " " + address.addressLine2;

	return dispatch => {

		dispatch({ type: types['SERVICE_REQUEST_MAPBOX_SEARCH'] + '_PENDING' });
		const url_endpoint = 'geocoding/v5/mapbox.places/' + map_address + '.json';
		const access_token = 'pk.eyJ1IjoiYmxha2Vjb2RleiIsImEiOiJja2twemEyZ3ozMXZtMnVudzh6ajRkdG5wIn0.ZJ6dJHj6rJbOrDiBUuY2MA';
		axios({
			url: url_endpoint,
			method: 'get',
			baseURL: 'https://api.mapbox.com/',
			params: {
				access_token: access_token
			}
		}).then(response => {
			let coordinates = response.data.features[0].center;
			dispatch({ type: types['SERVICE_REQUEST_MAPBOX_SEARCH'] + '_FULFILLED', data: coordinates });
		});
	};
}

async function nextServiceRequestId(handle, batch) {
	const table = 'service-requests';
	const field = 'nextServiceRequestId';
	const startingId = 1000;

	return toolbox.nextId(handle, batch, table, field, startingId);
}
async function nextCustomId(handle, batch) {
	const table = 'service-requests';
	const field = 'nextCustomId';
	const startingId = 1000;

	return toolbox.nextId(handle, batch, table, field, startingId);
}
async function nextQuoteId(handle, batch) {
	const table = 'quotes';
	const field = 'nextQuoteId';
	const startingId = 1000;

	return toolbox.nextId(handle, batch, table, field, startingId);
}


/*-----------------------------------------------*/
/*  WORK ORDER ITEMS
/*-----------------------------------------------*/

async function nextWorkOrderId(handle, batch) {
	const table = 'work-orders';
	const field = 'nextWorkOrderId';
	const startingId = 1000;

	return toolbox.nextId(handle, batch, table, field, startingId);
}

function scheduleDateArray(starting, ending) {

	// DATE ARRAY
	var low = moment(starting).hour(0).minutes(0).seconds(0);
	var high = moment(ending).hour(23).minutes(59).seconds(59);
	var current = low.clone().hour(12);

	var i = 0;
	var dates = [];
	while (current.isBetween(low, high) && i < 90) {
		dates.push(current.format('MMDDYY'));
		current.add(1, 'day');
		i++;
	}
	return dates;
}

