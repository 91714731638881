import * as toolbox from 'components/common/toolbox';
import * as types from './action-types';
import firebase from 'firebase/compat/app';
import { REQUESTS, SCHEDULE_EVENT_TYPE, WORKORDERS } from 'components/common/constants';
import { collection, getFirestore, query, where, orderBy, getCountFromServer } from 'firebase/firestore';

const firestore = firebase.firestore();
const _ = require('lodash');
var moment = require('moment');

/*-----------------------------------------------*/
/*  WORK ORDERS
/*-----------------------------------------------*/


export function getWorkOrderByCustomId(handle, customId, callback) {
	return dispatch => {
		firestore.collection(handle + '/work-orders/work-orders').where('customId', '==', customId).get().then(snap => {
			var order = { ...snap.docs[0].data(), id: snap.docs[0].id };
			if (typeof callback === 'function') callback(order);
		}).catch(error => {
			// Handle error if necessary
			console.error("Error updating document: ", error);
		});
	};
}
export function subWorkOrderById(handle, id) {

	return dispatch => {

		dispatch({ type: types['WORK_ORDER'] + '_PENDING' });

		var unsubscribe = firestore.collection(handle + '/work-orders/work-orders').doc(id).onSnapshot((doc) => {
			var order = { ...doc.data(), id: doc.id };

			dispatch({ type: types['WORK_ORDER'] + '_FULFILLED', data: order, unsubscribe });
		});
	};
}

export function subWorkOrdersByRequestId(handle, requestId) {

	return dispatch => {

		dispatch({ type: types['WORK_ORDERS'] + '_CLEAR' });
		dispatch({ type: types['WORK_ORDERS'] + '_PENDING' });

		var unsubscribe = firestore.collection(handle + '/work-orders/work-orders')
			.where('serviceRequestId', '==', requestId.toString())
			.where('deleted', '==', false).onSnapshot((querySnapshot) => {

				var orders = [];
				querySnapshot.forEach((doc) => {
					orders.push({ ...doc.data(), id: doc.id });
				});
				orders = _.orderBy(orders, ['modified'], ['desc']);

				dispatch({ type: types['WORK_ORDERS'] + '_FULFILLED', data: orders, unsubscribe });
			});
	};
}

export function subWorkOrderItemsByRequestId(handle, requestId) {

	return async dispatch => {

		dispatch({ type: types['WORK_ORDER_ITEMS'] + '_CLEAR' });
		dispatch({ type: types['WORK_ORDER_ITEMS'] + '_PENDING' });

		var unsubscribe = firestore.collection(handle + '/work-orders/work-orders')
			.where('serviceRequestId', '==', requestId.toString())
			.where('deleted', '==', false).onSnapshot(async (querySnapshot) => {

				var orders = [];
				const serviceItems = [];
				querySnapshot.forEach((doc) => {
					orders.push({ ...doc.data(), id: doc.id });
				});
				for (let order of orders) {
					await firestore.collection(handle + '/work-orders/work-orders/' + order.id + '/serviceItems').doc(order.id).get().then((doc) => {
						if (doc.exists && doc.data().serviceItems) {
							doc.data().serviceItems.forEach((item) => {
								if (!serviceItems.find((si) => si.serviceItemId == item.serviceItemId && si.customPricePerUnit == item.customPricePerUnit)) serviceItems.push(item);
								else {
									const index = serviceItems.findIndex((si) => si.serviceItemId == item.serviceItemId && si.customPricePerUnit == item.customPricePerUnit);
									serviceItems[index].count += item.count;
								}
							});
						}
					});
				}

				dispatch({ type: types['WORK_ORDER_ITEMS'] + '_FULFILLED', data: serviceItems, unsubscribe });
			});
	};
}

export function subWorkOrdersByDate(handle, date) {

	return dispatch => {

		dispatch({ type: types['WORK_ORDERS'] + '_CLEAR' });
		dispatch({ type: types['WORK_ORDERS'] + '_PENDING' });

		var unsubscribe = firestore.collection(handle + '/work-orders/work-orders').where('dates', 'array-contains', date.format('MMDDYY')).onSnapshot((querySnapshot) => {
			var orders = [];
			var i = 0;
			querySnapshot.forEach((doc) => {
				if (!doc.data().deleted) orders.push({ ...doc.data(), id: doc.id });
				i++;
			});

			dispatch({ type: types['WORK_ORDERS'] + '_FULFILLED', data: orders, unsubscribe });
		});
	};
}
export function subIncompleteWorkOrders(handle, date, limit, userId) {

	return async dispatch => {

		dispatch({ type: types['WORK_ORDERS_INCOMPLETE'] + '_PENDING' });

		const incomplete = [
			WORKORDERS.UNASSIGNED.id,
			WORKORDERS.ASSIGNED.id,
			WORKORDERS.ENROUTE.id,
			WORKORDERS.ONSITE.id,
			WORKORDERS.INCOMPLETE.id,
		];

		const ref = (userId)
			? firestore.collection(handle + '/work-orders/work-orders').where('technicianId', '==', userId).where('statusId', 'in', incomplete).orderBy('endDate', 'desc')
			: (limit)
				? firestore.collection(handle + '/work-orders/work-orders').where('endDate', '<', date.toDate()).where('statusId', 'in', incomplete).orderBy('endDate', 'desc').limit(limit)
				: firestore.collection(handle + '/work-orders/work-orders').where('endDate', '<', date.toDate()).where('statusId', 'in', incomplete).orderBy('endDate', 'desc');

		var unsubscribe = ref.onSnapshot(async (querySnapshot) => {

			var orders = [];
			querySnapshot.forEach((doc) => {
				if (!doc.data()['deleted'] && !userId) orders.push({ ...doc.data(), searchDescription: '<strong>' + ((doc.data().type == 4) ? 'Internal Work Order' : doc.data()['_displayName']) + '</strong><br/>' + doc.data()['description'], id: doc.id });
				else orders.push({ ...doc.data(), id: doc.id });
			});
			var sorted_orders = _.orderBy(orders, ['endDate'], ['desc']);
			var data = { data: sorted_orders };

			if (!userId) {
				const db = getFirestore();
				const coll = collection(db, handle + '/work-orders/work-orders');
				const query_ = query(coll, where('endDate', '<', date.toDate()), where('statusId', 'in', incomplete), orderBy('endDate', 'desc'));
				const snapshot = await getCountFromServer(query_);
				data.count = snapshot.data().count;
			}

			dispatch({ type: types['WORK_ORDERS_INCOMPLETE'] + '_FULFILLED', data: data, unsubscribe: unsubscribe });
		});
	};
}
export function subUnassignedWorkOrders(handle, date) {

	return async dispatch => {
		dispatch({ type: types['WORK_ORDERS_UNASSIGNED'] + '_PENDING' });

		const ref = firestore.collection(handle + '/work-orders/work-orders').where('statusId', '==', WORKORDERS.UNASSIGNED.id).where('dates', 'array-contains', moment(date, 'YYYY-MM-DD').format('MMDDYY'));

		var unsubscribe = ref.onSnapshot(async (querySnapshot) => {

			var orders = [];
			querySnapshot.forEach((doc) => {
				orders.push({ ...doc.data(), id: doc.id });
			});

			dispatch({ type: types['WORK_ORDERS_UNASSIGNED'] + '_FULFILLED', data: orders, unsubscribe: unsubscribe });
		});
	};
}
export function getTopLevelWorkOrder(handle, request_id, callback) {

	return async dispatch => {

		if (request_id) {
			const workOrdersSnapshot = await firestore.collection(handle + '/work-orders/work-orders').where('serviceRequestId', '==', request_id).get();
			var workOrder = null;
			workOrdersSnapshot.forEach((doc) => {
				// check for absence of "-"
				if (doc.data()['customId'].indexOf("-") === -1) workOrder = { ...doc.data(), id: doc.id };
			});

			if (typeof callback === 'function') callback(workOrder);
		}
	};
}
export function saveNewWorkOrder(handle, profileId, serviceRequestId, workOrder, topWorkOrder, lineItems, topLineItems, callback) {

	return async dispatch => {

		dispatch({ type: types['WORK_ORDER'] + '_SAVE_PENDING' });

		const user = firebase.auth().currentUser;
		const batch = firestore.batch();
		const workOrderId = await nextWorkOrderId(handle, batch);
		const customerDoc = await firestore.collection(handle + '/profiles/profiles/').doc(profileId).get();
		workOrder._displayName = customerDoc.data()['displayName'];
		workOrder.profileId = customerDoc.id;
		const requestDoc = await firestore.collection(handle + '/service-requests/service-requests/').doc(serviceRequestId).get();
		const request = { ...requestDoc.data(), id: requestDoc.id };

		const workOrdersSnapshot = await firestore.collection(handle + '/work-orders/work-orders').where('serviceRequestId', '==', serviceRequestId).get();
		var workOrderIndex = 0;
		workOrdersSnapshot.forEach((doc) => {
			workOrderIndex++;
		});

		workOrder.serviceRequestId = serviceRequestId;
		workOrder._serviceRequestTermsId = request.serviceRequestTermsId;
		workOrder.customId = (workOrderIndex) ? request.customId + '-' + workOrderIndex : request.customId;
		workOrder.description = workOrder.description;
		workOrder.startDate = workOrder.startDate;
		workOrder.endDate = workOrder.endDate;
		workOrder.invoicingMemo = null;
		workOrder.technicianId = workOrder?.technicianId;
		workOrder.statusId = (!workOrder.technicianId) ? WORKORDERS.UNASSIGNED.id : WORKORDERS.ASSIGNED.id;
		workOrder.editedBy = user.email;
		workOrder.deleted = false;
		workOrder.created = new Date();
		workOrder.modified = new Date();
		workOrder.type = SCHEDULE_EVENT_TYPE.WORKORDER.id;
		workOrder.dates = scheduleDateArray(moment(workOrder.startDate), moment(workOrder.endDate));
		workOrder.monthYear = scheduleMonthYearArray(moment(workOrder.startDate), moment(workOrder.endDate));
		workOrder.geopoint = request.geopoint;

		delete workOrder.id;

		batch.set(firestore.collection(handle + '/work-orders/work-orders').doc(workOrderId), { ...workOrder });

		// transfer work orders
		if (lineItems?.length) {
			batch.set(firestore.collection(handle + '/work-orders/work-orders/' + workOrderId + '/serviceItems').doc(workOrderId), { serviceItems: lineItems });
			batch.update(firestore.collection(handle + '/work-orders/work-orders/' + topWorkOrder.id + '/serviceItems').doc(topWorkOrder.id), { serviceItems: topLineItems });
		}

		batch.commit().then(() => {
			dispatch({ type: types['WORK_ORDER'] + '_SAVE_FULFILLED' });
			window.toastr.success('The Work Order has been successfully saved/updated', 'Work Order Saved!');
			if (typeof callback === 'function') callback(workOrderId, workOrder.customId);
		}).catch((error) => {
			toolbox.process_error(error, 'Record NOT Saved!');
		});
	};
}
export function saveNewInternalWorkOrder(handle, workOrder, callback) {

	return async dispatch => {

		dispatch({ type: types['WORK_ORDER'] + '_SAVE_PENDING' });

		const user = firebase.auth().currentUser;
		const batch = firestore.batch();
		const workOrderId = await nextWorkOrderId(handle, batch);

		workOrder.technicianId = workOrder.technicianId;
		workOrder.userId = user.email;
		workOrder.deleted = false;
		workOrder.created = new Date();
		workOrder.modified = new Date();
		workOrder.type = SCHEDULE_EVENT_TYPE.INTERNAL_WORKORDER.id;
		workOrder.dates = scheduleDateArray(moment(workOrder.startDate), moment(workOrder.endDate));
		workOrder.monthYear = scheduleMonthYearArray(moment(workOrder.startDate), moment(workOrder.endDate));

		batch.set(firestore.collection(handle + '/work-orders/work-orders').doc(workOrderId), { ...workOrder });

		batch.commit().then(() => {
			dispatch({ type: types['WORK_ORDER'] + '_SAVE_FULFILLED' });
			window.toastr.success('The Work Order has been successfully saved/updated', 'Work Order Saved!');
			if (typeof callback === 'function') callback(workOrderId);
		}).catch((error) => {
			toolbox.process_error(error, 'Record NOT Saved!');
		});
	};
}
export function updateWorkOrder(handle, workOrderId, workOrder, callback) {

	return async dispatch => {

		const user = firebase.auth().currentUser;

		dispatch({ type: types['WORK_ORDER'] + '_SAVE_PENDING' });

		// Bump from Unassigned to Assigned
		if (workOrder.technicianId && workOrder.statusId == WORKORDERS.UNASSIGNED.id) {
			workOrder.statusId = WORKORDERS.ASSIGNED.id;
		}

		if (workOrder.startDate && workOrder.endDate) {
			workOrder.dates = scheduleDateArray(moment(workOrder.startDate), moment(workOrder.endDate));
			workOrder.monthYear = scheduleMonthYearArray(moment(workOrder.startDate), moment(workOrder.endDate));
		}

		firestore.collection(handle + '/work-orders/work-orders').doc(workOrderId).update({
			...workOrder,
			modified: new Date,
			editedBy: user.email,

		}).then(async () => {
			dispatch({ type: types['WORK_ORDER'] + '_SAVE_FULFILLED' });
			window.toastr.success('The Work Order has been successfully saved/updated', 'Work Order Saved!');
			if (typeof callback === 'function') callback();
		}).catch((error) => {
			// The document probably doesn't exist.
			console.error("Error updating document: ", error);
		});
	};
}
export function updateWorkOrderServiceItems(handle, id, serviceItems, customId, callback) {

	return dispatch => {

		const user = firebase.auth().currentUser;

		dispatch({ type: types['SERVICE_ITEM'] + '_SAVE_PENDING' });

		var batch = firestore.batch();

		batch.set(firestore.collection(handle + '/work-orders/work-orders/' + id + '/serviceItems').doc(id), {
			serviceItems,
			editedBy: user.email,
			customId
		}, { merge: true });
		batch.set(firestore.collection(handle + '/work-orders/work-orders').doc(id), { modified: new Date() }, { merge: true });

		batch.commit().then(() => {
			dispatch({ type: types['SERVICE_ITEM'] + '_SAVE_FULFILLED' });
			if (typeof callback === 'function') callback();

		}).catch((error) => {
			// The document probably doesn't exist.
			console.error("Error updating document: ", error);
		});
	};
}
// export function subWorkOrderAttachmentsByWorkOrderID(handle, docId) {

// 	return dispatch => {

// 		dispatch({ type: types['WORK_ORDER_ATTACHMENTS'] + '_CLEAR' });
// 		dispatch({ type: types['WORK_ORDER_ATTACHMENTS'] + '_PENDING' });

// 		let unsubscribe = firestore.collection(handle + '/work-orders/work-orders/' + docId + '/attachments').doc(`${docId}`).onSnapshot(querySnapshot => {

// 			let attachments = (querySnapshot.exists) ? querySnapshot.data().attachments : [];

// 			dispatch({ type: types['WORK_ORDER_ATTACHMENTS'] + '_FULFILLED', data: attachments, unsubscribe });
// 		});
// 	};
// }
export function workOrderURLfromID(handle, workOrderId, callback) {

	firestore.collection(handle + '/work-orders/work-orders').doc(workOrderId).get().then((workOrderDoc) => {
		if (workOrderDoc.exists) {
			firestore.collection(handle + '/service-requests/service-requests').doc(workOrderDoc.data()['serviceRequestId']).get().then((serviceRequestDoc) => {
				if (serviceRequestDoc.exists) {

					callback([
						'/' + handle + '/customers/' + serviceRequestDoc.data()['profileId'] + '/form/0/0/service_requests',
						'/' + handle + '/customers/' + serviceRequestDoc.data()['profileId'] + '/form/0/0/service_requests/' + workOrderDoc.data()['serviceRequestId'],
						'/' + handle + '/customers/' + serviceRequestDoc.data()['profileId'] + '/form/0/0/service_requests/' + workOrderDoc.data()['serviceRequestId'] + '/work_orders/' + workOrderId + '/items'
					]);

				} else {
					console.log("No such document!");
				}
			});
		} else {
			console.log("No such document!");
		}
	});
}
export function deleteWorkOrder(handle, workOrder, callback) {

	return async dispatch => {

		dispatch({ type: types['WORK_ORDER'] + '_SAVE_PENDING' });

		const user = firebase.auth().currentUser;
		var batch = firestore.batch();

		const workOrderRef = firestore.collection(handle + '/work-orders/work-orders');
		batch.set(workOrderRef.doc(workOrder.id), { deleted: true, userId: user.email, modified: new Date() }, { merge: true });

		batch.commit().then(() => {
			dispatch({ type: types['WORK_ORDER'] + '_SAVE_FULFILLED' });
			window.toastr.success('The Work Order record has been Archived', 'Work Order Archived!');
			if (typeof callback === 'function') callback();
		}).catch((error) => {
			toolbox.process_error(error, 'Work Order NOT Archived!');
		});
	};
}

/*-----------------------------------------------*/
/*  WORK ORDER ITEMS
/*-----------------------------------------------*/

async function nextWorkOrderId(handle, batch) {
	const table = 'work-orders';
	const field = 'nextWorkOrderId';
	const startingId = 1000;

	return toolbox.nextId(handle, batch, table, field, startingId);
}
function scheduleDateArray(starting, ending) {

	// DATE ARRAY
	var low = moment(starting).hour(0).minutes(0).seconds(0);
	var high = moment(ending).hour(23).minutes(59).seconds(59);
	var current = low.clone().hour(12);

	var i = 0;
	var dates = [];
	while (current.isBetween(low, high) && i < 90) {
		dates.push(current.format('MMDDYY'));
		current.add(1, 'day');
		i++;
	}
	return dates;
}
function scheduleMonthYearArray(starting, ending) {

	// DATE ARRAY
	var low = moment(starting).startOf('week');
	var high = moment(ending).endOf('week');
	var current = low.clone().hour(12);

	var i = 0;
	var dates = [];
	while (current.isBetween(low, high) && i < 90) {
		let entry = current.format('MMYY');
		if (!dates.includes(entry)) dates.push(current.format('MMYY'));
		current.add(1, 'day');
		i++;
	}
	return dates;
}
