import ServiceItemsOption from 'components/service-items/line-items/service-items-option';
import * as quotes_actions from 'actions/quotes-actions';
import React, { useEffect, useState } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { Checkbox } from 'enspire-manager-framework';
import { useDispatch, useSelector } from 'react-redux';
import { QUOTES } from 'components/common/constants';

const LineItemsAccordion = (props) => {

    /* Hooks ------------------------------------------------------------------------------------------------------------------------------------*/

    const dispatch = useDispatch();

    const quotes = useSelector((store) => store.quotes);
    const quote = quotes?.quote;
    const selectedOptions = quotes?.options_selected;

    var options = props.options;
    const source = props?.source;

    const [open, setOpen] = useState((source == 'quote-options') ? (options?.map((option, index) => (index + 1).toString()) ?? []) : ['1']);

    /* Effects ------------------------------------------------------------------------------------------------------------------------------------*/

    // Clear out old selected options when loading new quote
    useEffect(() => {
        dispatch(quotes_actions.updateSelectedOptions([]));
    }, []);

    /* Handlers ------------------------------------------------------------------------------------------------------------------------------------*/

    const handleClick = (tab) => {
        tab = tab.toString();
        if (source == 'quote-options') {
            if (open.includes(tab)) setOpen(open.filter((t) => t != tab));
            else setOpen([...open, tab.toString()]);
        } else {
            if (open.includes(tab)) setOpen(open.filter((t) => t != tab));
            else setOpen([tab]);
        }
    };

    const handleSelectOption = (e, option) => {
        e.stopPropagation();
        if (selectedOptions?.find((opt) => opt == option)) {
            dispatch(quotes_actions.updateSelectedOptions(selectedOptions?.filter((opt) => opt != option)));
        } else {
            dispatch(quotes_actions.updateSelectedOptions([...selectedOptions, option]));
        }
    };

    if (quote?.statusId == QUOTES.ACCEPTED.id || quote?.statusId == QUOTES.CHANGES_REQUESTED.id) {
        options = options?.filter((option) => option != quote.approvedOption);
        options?.unshift(quote.approvedOption);
    }

    /* Render ------------------------------------------------------------------------------------------------------------------------------------*/

    return ((source == 'quote-options')
        // Allows for multiple options to be open at once
        ? <>
            {options?.map((option) => (
                <Accordion defaultActiveKey={option} key={`accordion-${option}`}>
                    <Card key={option} className="card-table">
                        <Accordion.Toggle className="py-3" as={Card.Header} eventKey={option} style={{ cursor: 'pointer' }} onClick={() => handleClick(option)}>
                            <h4 className='d-inline mr-3 align-middle'>Option #{option} { }</h4>
                            <span className='float-right'>
                                <i className={`fa fa-chevron-${(open.includes(option.toString())) ? 'up fa-flip' : 'down'}`}></i>
                            </span>
                        </Accordion.Toggle>

                        <Accordion.Collapse eventKey={option}>
                            <Card.Body>
                                <ServiceItemsOption option={option} source={source} />
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>)
            )}
        </>
        : quote && <Accordion defaultActiveKey={(quote?.statusId == QUOTES.ACCEPTED.id) ? quote.approvedOption?.toString() : '1'}>
            {options?.map((option) =>
                <Card key={option} className="card-table">

                    <Accordion.Toggle className="py-3" as={Card.Header} eventKey={option.toString()} style={{ cursor: 'pointer', borderLeft: (quote.statusId == QUOTES.ACCEPTED.id || quote.statusId == QUOTES.CHANGES_REQUESTED.id) && `3px solid ${(quote.approvedOption == option) ? QUOTES.ACCEPTED.color : QUOTES.REJECTED.color}` }} onClick={() => handleClick(option)}>
                        <h4 className='d-inline mr-2 align-middle' >
                            Option #{option} &nbsp;
                        </h4>
                        {options.length > 1 && <div className='d-inline '>
                            <p className='d-inline'>Print</p> &nbsp;
                            <Checkbox className="print_checkbox" checked={selectedOptions?.find((o) => o == option)} onClick={(e) => handleSelectOption(e, option)} name={'Print'} />
                        </div>}
                        <span className='float-right'>
                            <i className={`fa fa-chevron-${(open.includes(option)) ? 'up fa-flip' : 'down'}`}></i>
                        </span>
                    </Accordion.Toggle>

                    <Accordion.Collapse eventKey={option.toString()}>
                        <Card.Body>
                            <ServiceItemsOption option={option} source={source} />
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            )}
        </Accordion>
    );
};

export default LineItemsAccordion;