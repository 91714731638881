import * as types from 'actions/action-types';

var initialState = {
	call_log: null,
	call_log_pending: false,
	call_log_unsubscribe: null,

	call_log_form: null,
	call_log_form_pending: null,
	call_log_form_save_pending: null,

};

const callCenterReducer = (state = initialState, action) => {

	switch (action.type) {
		case types.CALL_LOG + '_PENDING': pending('call_log'); break;
		case types.CALL_LOG + '_SAVE_PENDING': save_pending('call_log'); break;
		case types.CALL_LOG + '_SAVE_FULFILLED': save_fulfilled('call_log'); break;
		case types.CALL_LOG + '_FULFILLED': fulfilled('call_log'); break;

		case types.CALL_LOG_FORM + '_FULFILLED': fulfilled('call_log_form'); break;
		case types.CALL_LOG_FORM + '_CLEAR': state = { ...state, call_log_form: null }; break;

		default: break;
	}

	return state;

	function pending(table) {
		state = {
			...state,
			[table + '_pending']: true,
		};
	}
	function save_pending(table) {
		state = {
			...state,
			[table + '_save_pending']: true,
		};
	}
	function save_fulfilled(table) {
		state = {
			...state,
			[table + '_save_pending']: false,
		};
	}
	function fulfilled(table) {
		state = {
			...state,
			[table]: action.data,
			[table + '_pending']: false,
			[table + '_save_pending']: false,
			[table + '_unsubscribe']: (action.unsubscribe) ? action.unsubscribe : state[table + '_unsubscribe'],
		};
	}
};
export default callCenterReducer;


