import * as toolbox from 'components/common/toolbox';
import * as types from './action-types';
import firebase from 'firebase/compat/app';
import { toastr } from 'react-redux-toastr';

const firestore = firebase.firestore();
const _ = require('lodash');
var moment = require('moment');
const axios = require('axios');

/*-----------------------------------------------*/
/*  ARCHIVE
/*-----------------------------------------------*/

export function subArchivedProfiles(handle) {

	return dispatch => {

		dispatch({ type: types['ARCHIVED_PROFILES'] + '_PENDING' });

		var unsubscribe = firestore.collection(handle + '/profiles/profiles').where('deleted', '==', true).onSnapshot((querySnapshot) => {

			var customers = [];
			querySnapshot.forEach((doc) => {
				customers.push({ ...doc.data(), id: doc.id });
			});

			dispatch({ type: types['ARCHIVED_PROFILES'] + '_FULFILLED', data: customers, unsubscribe });
		});
	};
}
export function subArchivedServiceRequest(handle) {

	return dispatch => {

		dispatch({ type: types['ARCHIVED_SERVICE_REQUESTS'] + '_PENDING' });

		var unsubscribe = firestore.collection(handle + '/service-requests/service-requests').where('deleted', '==', true).onSnapshot((querySnapshot) => {

			var service_requests = [];
			querySnapshot.forEach((doc) => {
				service_requests.push({ ...doc.data(), id: doc.id });
			});

			dispatch({ type: types['ARCHIVED_SERVICE_REQUESTS'] + '_FULFILLED', data: service_requests, unsubscribe });
		});
	};
}
export function subArchivedWorkOrders(handle) {

	return dispatch => {

		dispatch({ type: types['ARCHIVED_WORK_ORDERS'] + '_PENDING' });

		var unsubscribe = firestore.collection(handle + '/work-orders/work-orders').where('deleted', '==', true).onSnapshot((querySnapshot) => {

			var work_orders = [];
			querySnapshot.forEach((doc) => {
				work_orders.push({ ...doc.data(), id: doc.id });
			});

			dispatch({ type: types['ARCHIVED_WORK_ORDERS'] + '_FULFILLED', data: work_orders, unsubscribe });
		});
	};
}
export function subArchivedServiceItems(handle) {

	return dispatch => {

		dispatch({ type: types['ARCHIVED_SERVICE_ITEMS'] + '_PENDING' });

		var unsubscribe = firestore.collection(handle + '/service-items/service-items').where('deleted', '==', true).onSnapshot((querySnapshot) => {

			var service_items = [];
			querySnapshot.forEach((doc) => {
				service_items.push({ ...doc.data(), id: doc.id });
			});

			dispatch({ type: types['ARCHIVED_SERVICE_ITEMS'] + '_FULFILLED', data: service_items, unsubscribe });
		});
	};
}
export function subArchivedPayments(handle) {

	return dispatch => {

		dispatch({ type: types['ARCHIVED_PAYMENTS'] + '_PENDING' });

		var unsubscribe = firestore.collection(handle + '/payments/payments').where('deleted', '==', true).onSnapshot((querySnapshot) => {

			var payments = [];
			querySnapshot.forEach((doc) => {
				payments.push({ ...doc.data(), id: doc.id });
			});

			dispatch({ type: types['ARCHIVED_PAYMENTS'] + '_FULFILLED', data: payments, unsubscribe });
		});
	};
}

/*-----------------------------------------------*/
/*  RESTORE
/*-----------------------------------------------*/

export function restoreProfile(handle, profileId, profile_type, data, callback) {

	return async dispatch => {

		dispatch({ type: types[profile_type] + '_SAVE_PENDING' });

		var batch = firestore.batch();

		const customerRef = firestore.collection(handle + '/profiles/profiles');
		batch.set(customerRef.doc(profileId), { deleted: false, modified: new Date() }, { merge: true });

		if (profile_type == 'EMPLOYEE') batch.set(firestore.collection(handle + '/users/users').doc(data.contacts[Object.keys(data.contacts)[0]].email), { deleted: false, modified: new Date() }, { merge: true });

		batch.commit().then(() => {
			dispatch({ type: types[profile_type] + '_SAVE_FULFILLED' });
			window.toastr.success(`The ${toolbox.toProperCase(profile_type)} record has been Restored`, `${toolbox.toProperCase(profile_type)} Restored!`);
			if (typeof callback === 'function') callback();
		}).catch((error) => {
			toolbox.process_error(error, `${toolbox.toProperCase(profile_type)} NOT Restored!`);
		});
	};
}
export function restoreServiceRequest(handle, serviceRequestId, callback) {

	return async dispatch => {

		dispatch({ type: types['SERVICE_REQUEST'] + '_SAVE_PENDING' });

		var batch = firestore.batch();

		const serviceRef = firestore.collection(handle + '/service-requests/service-requests');
		batch.set(serviceRef.doc(serviceRequestId), { deleted: false, modified: new Date() }, { merge: true });

		batch.commit().then(() => {
			dispatch({ type: types['SERVICE_REQUEST'] + '_SAVE_FULFILLED' });
			window.toastr.success('The Service Request record has been Restored', 'Service Request Restored!');
			if (typeof callback === 'function') callback();
		}).catch((error) => {
			toolbox.process_error(error, 'Service Request NOT Restored!');
		});
	};
}
export function restoreWorkOrder(handle, workOrderId, callback) {

	return async dispatch => {

		dispatch({ type: types['WORK_ORDERS'] + '_SAVE_PENDING' });

		var batch = firestore.batch();

		const workOrderRef = firestore.collection(handle + '/work-orders/work-orders');
		batch.set(workOrderRef.doc(workOrderId), { deleted: false, modified: new Date() }, { merge: true });

		batch.commit().then(() => {
			dispatch({ type: types['WORK_ORDERS'] + '_SAVE_FULFILLED' });
			window.toastr.success('The Work Order record has been Restored', 'Work Order Restored!');
			if (typeof callback === 'function') callback();
		}).catch((error) => {
			toolbox.process_error(error, 'Work Order NOT Restored!');
		});
	};
}
export function restoreServiceItem(handle, serviceItemId, callback) {

	return async dispatch => {

		dispatch({ type: types['SERVICE_ITEMS'] + '_SAVE_PENDING' });

		var batch = firestore.batch();

		const workOrderRef = firestore.collection(handle + '/service-items/service-items');
		batch.set(workOrderRef.doc(serviceItemId), { deleted: false, modified: new Date() }, { merge: true });

		batch.commit().then(() => {
			dispatch({ type: types['SERVICE_ITEMS'] + '_SAVE_FULFILLED' });
			window.toastr.success('The Service Item record has been Restored', 'Service Item Restored!');
			if (typeof callback === 'function') callback();
		}).catch((error) => {
			toolbox.process_error(error, 'Service Item NOT Restored!');
		});
	};
}

/*-----------------------------------------------*/
/*  PERMANENTLY DELETE
/*-----------------------------------------------*/

export function deleteProfile(handle, profileId, profile_type, data, callback) {

	return async dispatch => {

		dispatch({ type: types[profile_type] + '_DELETE' });

		var snapshot, snapshot2;
		const batch = firestore.batch();

		if (profile_type == "CUSTOMER") {
			snapshot = await firestore.collection(handle + '/addresses/addresses').where('profileId', '==', profileId).get();
			snapshot.docs?.forEach((doc) => {
				batch.delete(doc.ref);
			});
			snapshot = await firestore.collection(handle + '/invoices/invoices').where('profileId', '==', profileId).get();
			snapshot.docs?.forEach((doc) => {
				batch.delete(doc.ref);
			});

			snapshot = await firestore.collection(handle + '/quotes/quotes').where('profileId', '==', profileId).get();
			snapshot.docs?.forEach((doc) => {
				batch.delete(firestore.collection(handle + '/quotes/quotes/' + doc.id + '/attachments').doc(doc.id));
				batch.delete(firestore.collection(handle + '/quotes/quotes/' + doc.id + '/serviceItems').doc(doc.id));
				batch.delete(doc.ref);
			});

			snapshot = await firestore.collection(handle + '/service-requests/service-requests').where('profileId', '==', profileId).get();
			for (var doc of snapshot.docs) {

				snapshot2 = await firestore.collection(handle + '/work-orders/work-orders').where('serviceRequestId', '==', doc.id).get();
				snapshot2.docs?.forEach((doc2) => {
					batch.delete(firestore.collection(handle + '/work-orders/work-orders/' + doc2.id + '/attachments').doc(doc2.id));
					batch.delete(firestore.collection(handle + '/work-orders/work-orders/' + doc2.id + '/serviceItems').doc(doc2.id));
					batch.delete(doc2.ref);
				});

				batch.delete(doc.ref);
			}
		}
		if (profile_type == "EMPLOYEE") batch.delete(firestore.collection(handle + '/users/users').doc(data.contacts[Object.keys(data.contacts)[0]].email));

		batch.delete(firestore.collection(handle + '/profiles/profiles/' + profileId + '/attachments').doc(profileId));
		batch.delete(firestore.collection(handle + '/profiles/profiles').doc(profileId));

		await batch.commit().then(() => {
			dispatch({ type: types[profile_type] + '_FULFILLED' });
			window.toastr.success(`The ${toolbox.toProperCase(profile_type)} record has been PERMANENTLY DELETED`, `${toolbox.toProperCase(profile_type)} Deleted!`);
			if (typeof callback === 'function') callback();
		}).catch((error) => {
			toolbox.process_error(error, `${toolbox.toProperCase(profile_type)} NOT Deleted!`);
		});
	};
}

export function deleteServiceItem(handle, serviceItemId, callback) {

	return async dispatch => {

		dispatch({ type: types['SERVICE_ITEMS'] + '_SAVE_PENDING' });

		var batch = firestore.batch();

		const workOrderRef = firestore.collection(handle + '/service-items/service-items');
		batch.delete(workOrderRef.doc(serviceItemId));

		batch.commit().then(() => {
			dispatch({ type: types['SERVICE_ITEMS'] + '_SAVE_FULFILLED' });
			window.toastr.success('The Service Item record has been PERMANENTLY DELETED', 'Service Item Deleted!');
			if (typeof callback === 'function') callback();
		}).catch((error) => {
			toolbox.process_error(error, 'Service Item NOT Deleted!');
		});
	};
}
export function deleteWorkOrder(handle, workOrderId, callback) {

	return async dispatch => {

		dispatch({ type: types['WORK_ORDERS'] + '_SAVE_PENDING' });

		var batch = firestore.batch();

		const workOrderRef = firestore.collection(handle + '/work-orders/work-orders');
		batch.delete(workOrderRef.doc(workOrderId));

		batch.commit().then(() => {
			dispatch({ type: types['WORK_ORDERS'] + '_SAVE_FULFILLED' });
			window.toastr.success('The Work Order record has been PERMANENTLY DELETED', 'Work Order Deleted!');
			if (typeof callback === 'function') callback();
		}).catch((error) => {
			toolbox.process_error(error, 'Work Order NOT Deleted!');
		});
	};
}
export function deleteServiceRequest(handle, serviceRequestId, callback) {

	return async dispatch => {

		dispatch({ type: types['SERVICE_REQUEST'] + '_SAVE_PENDING' });

		var batch = firestore.batch();

		const serviceRequestRef = firestore.collection(handle + '/service-requests/service-requests');
		batch.delete(serviceRequestRef.doc(serviceRequestId));

		var snapshot = await firestore.collection(handle + '/work-orders/work-orders').where('serviceRequestId', '==', serviceRequestId).get();
		snapshot.docs?.forEach((doc) => {
			batch.delete(firestore.collection(handle + '/work-orders/work-orders/' + doc.id + '/attachments').doc(doc.id));
			batch.delete(firestore.collection(handle + '/work-orders/work-orders/' + doc.id + '/serviceItems').doc(doc.id));
			batch.delete(doc.ref);
		});

		batch.commit().then(() => {
			dispatch({ type: types['SERVICE_REQUEST'] + '_SAVE_FULFILLED' });
			window.toastr.success('The Service Request record has been PERMANENTLY DELETED', 'Service Request Deleted!');
			if (typeof callback === 'function') callback();
		}).catch((error) => {
			toolbox.process_error(error, 'Service Request NOT Deleted!');
		});
	};
}