import React, { useEffect, useRef, useState } from 'react';
import { elasticSearch, Table, Ibox, CloseX } from 'enspire-manager-framework';
import { useHistory, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import * as toolbox from 'components/common/toolbox';
import moment from 'moment';
import { useSelector } from 'react-redux';
const isDev = (process.env.REACT_APP_FIREBASE_ENV == 'development');

const NavbarSearch = () => {

    /* Hooks --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

    const params = useParams();
    const location = useLocation();
    const history = useHistory();
    const inputRef = useRef();

    const categories = useSelector((store) => store.serviceItems?.service_item_categories);

    const [showPanel, setshowPanel] = useState(false);
    const [search, setSearch] = useState({
        loading: false,
        input: '',
        hits: [],
    });

    /* Effects --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

    // Update search on page load && on input changes
    useEffect(() => {
        (search.input) && updateSearch(search.input);
    }, [search.input]);

    /* Handlers --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

    const handleClick = (hit, index, e) => {
        var newLocation;
        switch (hit.type) {
            case 'customer':
                newLocation = toolbox.modifyPath(location.pathname, 2, 'customers/' + hit.id + '/form/0/0/service_requests', 7);
                break;
            case 'service request':
                newLocation = toolbox.modifyPath(location.pathname, 2, 'customers/' + hit.profileId + '/form/0/0/service_requests/' + hit.id + '/work_orders', 9);
                break;
            case 'work order':
                newLocation = toolbox.modifyPath(location.pathname, 2, 'customers/' + hit.profileId + '/form/0/0/service_requests/' + hit.serviceRequestId + '/work_orders/' + hit.id + "/items", 11);
                break;
            case 'service item':
                newLocation = toolbox.modifyPath(location.pathname, 2, 'service-items/' + hit.id, 3);
                break;
            default:
                break;
        }

        history.push({ pathname: newLocation });
        setshowPanel(false);
        setSearch(prev => ({ ...prev, input: '' }));
    };

    const handleOnFocus = () => {
        setSearch(prev => ({ ...prev, input: "" }));
        setshowPanel(true);
    };

    const handleSearchInput = (event) => {
        setSearch(prev => ({ ...prev, input: event.target.value }));
    };

    const handleEsc = () => {
        setshowPanel(false);
        inputRef.current.blur();
    };

    /* Actions --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

    const updateSearch = async (search) => {

        try {
            setSearch(prev => ({ ...prev, loading: true }));
            let indexHandle = params.handle + (isDev ? '-dev' : '');
            let raw_hits;
            let config;
            let hits = [];

            /* Search customers -----------------------------------------------------------------------------------------------*/
            config = {
                table: indexHandle + "-profiles",
                type: 'customer',
                fields: ["displayName", "profileName", "addresses", "contacts", 'id', 'created'],
                // sort: [{ modified: "desc" }, { displayName: "asc" }],
            };
            raw_hits = await elasticSearch(search, config);
            raw_hits?.forEach((hit) => {
                hits.push({
                    ...hit,
                    customer: `<strong>${hit.displayName}</strong>`,
                    description: `${Object.values(hit.addresses)[0]?.address}`,
                    date: 'Modified: ' + moment('1970-01-01').add(hit.modified, 'seconds').format('MM/DD/YYYY'),
                });
            });

            /* Search Service Requests -----------------------------------------------------------------------------------------------*/
            config = {
                table: indexHandle + "-service-requests",
                fields: ["id", '_displayName', 'summary',],
            };
            raw_hits = await elasticSearch(search, config);
            raw_hits?.forEach((hit) => {
                hits.push({
                    ...hit,
                    type: 'service request',
                    displayName: hit._displayName,
                    date: 'Modified: ' + moment('1970-01-01').add(hit.modified, 'seconds').format('MM/DD/YYYY'),
                });
            });

            /* Search Work Orders -----------------------------------------------------------------------------------------------*/
            config = {
                table: indexHandle + "-work-orders",
                fields: ["id", '_displayName', 'description', 'endDate'],
            };
            raw_hits = await elasticSearch(search, config);
            raw_hits?.forEach((hit) => {
                hits.push({
                    ...hit,
                    type: 'work order',
                    displayName: hit._displayName,
                    date: 'Modified: ' + moment('1970-01-01').add(hit.modified, 'seconds').format('MM/DD/YYYY'),
                });
            });

            /* Search Service Items -----------------------------------------------------------------------------------------------*/
            config = {
                table: indexHandle + '-service-items',
                fields: ['name', 'categories', 'description',],
            };
            raw_hits = await elasticSearch(search, config);
            raw_hits?.forEach((hit) => {
                hits.push({
                    ...hit,
                    type: 'service item',
                    displayName: hit.name,
                    categoryList: hit?.categoryIds?.map((id) => ({ style: 1, value: categories.find((o) => o.id == id).name })),
                    date: 'Modified: ' + moment('1970-01-01').add(hit.modified, 'seconds').format('MM/DD/YYYY'),

                });
            });

            setSearch(prev => ({ ...prev, hits, loading: false }));

            return false; // prevent <Enter> key from reloading
        } catch (error) {
            window.toastr.error(error.message);
        }

    };

    /* Constants --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

    const columns = [
        { name: 'Id', field: 'id' },
        { name: 'Result', field: 'displayName' },
        { name: 'Description', field: 'description' },
        { name: 'Date', field: 'date' },
    ];

    /* Render --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

    return (
        <form role="search" className="navbar-form-custom" action="search_results.html" >
            <div className="form-group">
                <input
                    onFocus={handleOnFocus}
                    // onBlur={() => setshowPanel(false)}
                    value={search.input}
                    ref={inputRef}
                    type="text"
                    placeholder="Search for something..."
                    className="form-control"
                    name="top-search"
                    id="top-search"
                    onInput={handleSearchInput}
                    style={{ width: '100%' }}
                    onKeyDown={(e) => { if (e.key === 'Escape') handleEsc(); }}
                    autoComplete='off'
                />
                {showPanel &&
                    <div id="global-search" className="mt-0 mb-0">
                        <div>
                            <Ibox
                                show_spinner={search.loading} title={<>
                                    <CloseX onClick={() => { setshowPanel(false); }} />

                                </>} custom_title={true}
                            >
                                <Table
                                    columns={columns}
                                    data={search.hits}
                                    click_callback={handleClick}
                                    group_by={{ fields: ['type'], direction: ['desc'] }}
                                />
                            </Ibox>
                        </div>
                    </div>
                }
            </div>
        </form>
    );
};

export default NavbarSearch;