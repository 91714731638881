import * as types from 'actions/action-types';

var initialState = {
	settings: null,
	settings_pending: false,
	settings_save_pending: false,
	settings_unsubscribe: null,

	settings_company: null,
	settings_company_pending: false,
	settings_company_save_pending: false,
	settings_company_unsubscribe: null,

	settings_version: null,
	settings_version_pending: false,
	settings_version_unsubscribe: null,

	departments: null,
	departments_pending: false,
	departments_save_pending: false,
	departments_unsubscribe: null,

	job_categories: [],
	job_categories_pending: false,
	job_categories_save_pending: false,
	job_categories_unsubscribe: null,

	department_tags: [],
	department_tags_pending: false,
	department_tags_save_pending: false,
	department_tags_unsubscribe: null,

	collapsed: false,

	schedulePanelButton: 'mostRecent',

	statusModal: false,
	statusTable: null,
	statusId: null,
	currentId: null,
	confirmCallback: null,

	// status of dual monitors for this instance
	dualMonitor1: false,
	dualMonitor2: false,
	dualMonitor1Url: '',
	dualMonitor2Url: '',
};

const settingsReducer = (state = initialState, action) => {

	switch (action.type) {
		case types.SETTINGS + '_PENDING': pending('settings'); break;
		case types.SETTINGS + '_SAVE_PENDING': save_pending('settings'); break;
		case types.SETTINGS + '_SAVE_FULFILLED': save_fulfilled('settings'); break;
		case types.SETTINGS + '_FULFILLED': fulfilled('settings'); break;

		case types.SETTINGS_COMPANY + '_PENDING': pending('settings_company'); break;
		case types.SETTINGS_COMPANY + '_SAVE_PENDING': save_pending('settings_company'); break;
		case types.SETTINGS_COMPANY + '_FULFILLED': fulfilled('settings_company'); break;

		case types.SETTINGS_VERSION + '_PENDING': pending('settings_version'); break;
		case types.SETTINGS_VERSION + '_FULFILLED': fulfilled('settings_version'); break;

		case types.UPDATE_BUTTON: updateButton(); break;

		case types.DEPARTMENTS + '_PENDING': pending('departments'); break;
		case types.DEPARTMENTS + '_SAVE_PENDING': save_pending('departments'); break;
		case types.DEPARTMENTS + '_FULFILLED': fulfilled('departments'); break;

		case types.JOB_CATEGORIES + '_PENDING': pending('job_categories'); break;
		case types.JOB_CATEGORIES + '_SAVE_PENDING': save_pending('job_categories'); break;
		case types.JOB_CATEGORIES + '_FULFILLED': fulfilled('job_categories'); break;

		case types.DEPARTMENT_TAGS + '_PENDING': pending('department_tags'); break;
		case types.DEPARTMENT_TAGS + '_SAVE_PENDING': save_pending('department_tags'); break;
		case types.DEPARTMENT_TAGS + '_FULFILLED': fulfilled('department_tags'); break;

		case types.SETTINGS_TOGGLE_COLLAPSED:
			state = { ...state, collapsed: !state.collapsed };
			break;
		case types.SETTINGS_TOGGLE_STATUS_MODAL:
			state = {
				...state,
				statusModal: !state.statusModal,
				statusTable: action.statusTable,
				statusId: action.statusId,
				currentId: action.currentId,
				confirmCallback: action.confirmCallback,
			};
			break;
		case types.SETTINGS_UPDATE_STATUS_MODAL:
			state = { ...state, statusId: action.statusId };
			break;
		case types.DUAL_MONITORS:
			state = { ...state, ['dualMonitor' + action.number]: action.active };
			break;

		default:
	}

	return state;

	function pending(table) {
		state = {
			...state,
			[table + '_pending']: true,
		};
	}
	function save_pending(table) {
		state = {
			...state,
			[table + '_save_pending']: true,
		};
	}
	function save_fulfilled(table) {
		state = {
			...state,
			[table + '_save_pending']: false,
		};
	}
	function fulfilled(table) {
		state = {
			...state,
			[table]: action.data,
			[table + '_pending']: false,
			[table + '_save_pending']: false,
			[table + '_unsubscribe']: (action.unsubscribe) ? action.unsubscribe : state[table + '_unsubscribe'],
		};
	}
	function updateButton() {
		state = {
			...state,
			[action.button]: action.update,
		};
	}
};
export default settingsReducer;


