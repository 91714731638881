import * as types from 'actions/action-types';

var initialState = {
	payments: null,
	payments_pending: false,
	payments_save_pending: false,
	payments_unsubscribe: false,

	invoice: null,
	invoice_pending: false,
	invoice_save_pending: false,
	invoice_unsubscribe: false,

};

const invoicesReducer = (state = initialState, action) => {

	switch (action.type) {

		case types.PAYMENTS + '_PENDING': pending('payments'); break;
		case types.PAYMENTS + '_SAVE_PENDING': save_pending('payments'); break;
		case types.PAYMENTS + '_SAVE_FULFILLED': save_fulfilled('payments'); break;
		case types.PAYMENTS + '_FULFILLED': fulfilled('payments'); break;
		case types.PAYMENTS + '_CLEAR': state = { ...state, payments: [] }; break;

		case types.INVOICE + '_PENDING': pending('invoice'); break;
		case types.INVOICE + '_SAVE_PENDING': save_pending('invoice'); break;
		case types.INVOICE + '_FULFILLED': fulfilled('invoice'); break;
		case types.INVOICE + '_CLEAR': state = { ...state, invoice: [] }; break;

		case types.INVOICES + '_PENDING': pending('invoices'); break;
		case types.INVOICES + '_SAVE_PENDING': save_pending('invoices'); break;
		case types.INVOICES + '_FULFILLED': fulfilled('invoices'); break;
		case types.INVOICES + '_CLEAR': state = { ...state, invoices: [] }; break;

		default: break;

	}

	return state;

	function pending(table) {
		state = {
			...state,
			[table + '_pending']: true,
		};
	}
	function fulfilled(table) {
		state = {
			...state,
			[table]: action.data,
			[table + '_pending']: false,
			[table + '_save_pending']: false,
			[table + '_unsubscribe']: (action.unsubscribe) ? action.unsubscribe : state[table + '_unsubscribe'],
		};
	}
	function save_pending(table) {
		state = {
			...state,
			[table + '_save_pending']: true,
		};
	}
	function save_fulfilled(table) {
		state = {
			...state,
			[table + '_save_pending']: false,
			[table + '_saved']: action.data
		};
	}
};
export default invoicesReducer;


