import * as toolbox from 'components/common/toolbox';
import * as types from './action-types';
import * as actions_quickbooks from './quickbooks-actions';
import * as actions_invoices from './invoices-actions';
import firebase from 'firebase/compat/app';
import { CONTACTS, INVOICES, QUOTES, SERVICE_REQUEST_TERMS } from 'components/common/constants';
import { toastr } from 'react-redux-toastr';

const firestore = firebase.firestore();
const _ = require('lodash');
var moment = require('moment');
const axios = require('axios');

/*-----------------------------------------------*/
/*  QUOTES
/*-----------------------------------------------*/

export function updateSelectedOptions(quotes) {

	return dispatch => {
		dispatch({ type: types.OPTIONS_SELECTED + '_FULFILLED', data: quotes });
	};
}

export function subQuoteById(handle, id, callback) {

	return dispatch => {

		dispatch({ type: types.QUOTE + '_PENDING' });

		var unsubscribe = firestore.collection(handle + '/quotes/quotes').doc(id).onSnapshot((doc) => {
			var quote = { ...doc.data(), id: doc.id };

			dispatch({ type: types.QUOTE + '_FULFILLED', data: quote, unsubscribe });
			if (typeof callback === 'function') callback(quote, unsubscribe);
		});
	};
}

export function subQuotesByField(handle, field, value) {

	return dispatch => {

		dispatch({ type: types.QUOTES + '_PENDING' });

		var unsubscribe = firestore.collection(handle + '/quotes/quotes').where('deleted', '==', false).where(field, '==', value).onSnapshot((querySnapshot) => {

			var quotes = [];
			querySnapshot.forEach((doc) => {
				quotes.push({ ...doc.data(), id: doc.id });
			});
			quotes = _.orderBy(quotes, ['created'], ['desc']);

			dispatch({ type: types.QUOTES + '_FULFILLED', data: quotes, unsubscribe });
		});
	};
}
export function createQuotePdf(options, callback) {
	return async dispatch => {
		firebase.functions().httpsCallable('dispatchCreateQuote')(options).then((result) => {
			if (typeof callback === 'function') callback(result);
		}).catch((error) => {
			window.toastr.error('There was an error generating this Quote.', 'Quote Copy could not be generated');
		});
	};
}
export function customerSignsQuote(handle, quoteId, validateSignature, action, signatureUrl, quickbooksSettings, company, callback) {

	return async dispatch => {

		const quoteDoc = await firestore.collection(handle + '/quotes/quotes').doc(quoteId).get();

		dispatch({ type: types.QUOTES + '_SAVE_PENDING' });

		if (quoteDoc.exists) {

			const quote = { ...quoteDoc.data(), id: quoteDoc.id };

			// If link is invalid (mismatching validateSignature), return unauthorized
			if (quote.validateSignature != validateSignature) {
				callback({ result: 'unauthorized' });
				dispatch({ type: types.QUOTES + '_SAVE_FULFILLED' });
				return;
			}

			const batch = firestore.batch();
			const quoteRef = firestore.collection(handle + '/quotes/quotes').doc(quoteId);
			const serviceRequestRef = firestore.collection(handle + '/service-requests/service-requests').doc(quote.serviceRequestId);
			var invoiceId;
			// Generate 20% deposit Invoice
			if (action == 'accept') {
				var newQuote = { statusId: QUOTES.ACCEPTED.id, signatureUrl, changeOrder: false, validateSignature: '' };
				if (quote.changeOrder) {
					newQuote.approvedOption = '1';
					newQuote.options = ['1'];
					newQuote.serviceItems = quote.serviceItems.map((item) => ({ ...item, option: '1' }));
				}
				batch.update(quoteRef, newQuote);
				batch.update(serviceRequestRef, { serviceRequestTermsId: SERVICE_REQUEST_TERMS.PROJECT_QUOTE.id });

				invoiceId = await nextInvoiceId(handle, batch);
				// Create Deposit Invoice
				const invoiceObject = {
					billTo: '...',
					serviceTo: '...',
					// addressId: serviceRequest.addressId,
					// customId: serviceRequest.customId,
					sendToContactId: null,
					payments: [],
					invoiceDate: new Date(),
					created: new Date(),
					modified: new Date(),
					deleted: false,
					memo: 'Deposit Payment',
					profileId: quote.profileId,
					requestId: quote.serviceRequestId,
					serviceItems: [{
						calculatedPrice: (quote.serviceItems.reduce((total, item) => total + item.calculatedPrice, 0) * .20).toFixed(2),
						count: 1,
						customPricePerUnit: (quote.serviceItems.reduce((total, item) => total + item.calculatedPrice, 0) * .20).toFixed(2),
						description: 'Deposit Payment',
						unitPrice: (quote.serviceItems.reduce((total, item) => total + item.calculatedPrice, 0) * .20).toFixed(2),
					}],
					isDeposit: true,
					statusId: INVOICES.PENDING.id,
				};
				batch.set(firestore.collection(handle + '/invoices/invoices').doc(invoiceId), invoiceObject);
			} else {
				batch.update(quoteRef, { statusId: QUOTES.REJECTED.id, signatureUrl, validateSignature: '' });
			}

			batch.commit().then(async () => {
				callback({ result: 'success', message: null });
				dispatch({ type: types.QUOTES + '_SAVE_FULFILLED' });
				const customerRef = await firestore.collection(handle + '/profiles/profiles').doc(quote.profileId).get();
				const customer = { ...customerRef.data(), id: customerRef.id };
				const ownerContact = Object.values(customer.contacts ?? {}).find((contact) => contact.contactTypeId == CONTACTS.OWNER.id) ?? customer.contacts?.[0];

				if (action == 'accept') {
					dispatch(actions_quickbooks.updateQuickbooks({
						handle: handle,
						type: 'invoice',
					}, quickbooksSettings, () => {
						dispatch(actions_invoices.createInvoicePdf({
							handle: handle,
							profileId: quote.profileId,
							invoiceId: invoiceId,
							email: (!ownerContact) ? null : [ownerContact.email],
							subject: (!ownerContact) ? null : `Quote #${quote.customId} - Deposit Payment`,
							body: (!ownerContact) ? null : (ownerContact.firstName + `,\r\n\r\nPlease see the attached PDF document to review your deposit charge.`).replace(/(?:\r\n|\r|\n)/g, '<br>'),
							from: (company.email) ? company.companyName + ' <' + company.email + '>' : 'Mobile Track Systems <no-reply@mobiletrack.systems>',
						}));
					}));
				}
				return;
			}).catch((error) => {
				callback({ result: 'error', message: error.message });
				dispatch({ type: types.QUOTES + '_SAVE_FULFILLED' });
				return;
			});

		} else {
			callback({ result: 'error', message: 'Quote does not exist' });
			dispatch({ type: types.QUOTES + '_SAVE_FULFILLED' });
			return;
		}

	};
}
async function nextInvoiceId(handle, batch) {
	const table = 'invoices';
	const field = 'nextInvoiceId';
	const startingId = 100000;

	return toolbox.nextId(handle, batch, table, field, startingId);
}


export function subAcceptedQuoteByServiceRequestId(handle, id) {

	return dispatch => {

		dispatch({ type: types.QUOTE_APPROVED + '_PENDING' });

		var unsubscribe = firestore.collection(handle + '/quotes/quotes').where('statusId', '==', QUOTES.ACCEPTED.id).where('deleted', '==', false).where('serviceRequestId', '==', id).onSnapshot((querySnapshot) => {

			var quotes = [];
			querySnapshot.forEach((doc) => {
				quotes.push({ ...doc.data(), id: doc.id });
			});
			quotes = _.orderBy(quotes, ['created'], ['desc']);

			dispatch({ type: types.QUOTE_APPROVED + '_FULFILLED', data: quotes[0], unsubscribe });
		});
	};
}

export function clearQuotes() {

	return dispatch => {

		dispatch({ type: types.QUOTES + '_CLEAR' });
	};
}

export function createQuoteFromWorkOrders(handle, serviceRequestId, callback) {

	return async dispatch => {

		dispatch({ type: types.QUOTE + '_SAVE_PENDING' });

		const user = firebase.auth().currentUser;
		var doc = await firestore.collection(handle + '/service-requests/service-requests').doc(serviceRequestId).get();
		var serviceRequest = { ...doc.data(), id: doc.id };

		const workOrderSnapshot = await firestore.collection(handle + '/work-orders/work-orders').where('serviceRequestId', '==', serviceRequestId).where('deleted', '==', false).get();

		var workOrders = [];
		var serviceItems = [];

		workOrderSnapshot.forEach((doc) => {
			workOrders.push({ ...doc.data(), id: doc.id });
		});

		var startDate;
		var endDate;
		var description = '';

		for (let workOrder of workOrders) {
			if (!startDate || workOrder.startDate < startDate) startDate = workOrder.startDate;
			if (!endDate || workOrder.endDate > endDate) endDate = workOrder.endDate;
			description += workOrder.description + "\n\n";

			let doc = await firestore.collection(handle + '/work-orders/work-orders/' + workOrder.id + '/serviceItems').doc(workOrder.id).get();
			if (doc.exists) {
				doc.data()['serviceItems']?.forEach((serviceItem) => {
					const existing = serviceItems.findIndex((item) => (item.id == serviceItem.id) && (item.customPricePerUnit == serviceItem.customPricePerUnit));
					if (existing >= 0) {
						serviceItems[existing].count = parseInt(serviceItems[existing].count) + parseInt(serviceItem.count);
					} else {
						serviceItems.push({ ...serviceItem, option: '1' });
					}
				});
			}
		}

		const batch = firestore.batch();

		// Search for existing quote - overwrite draft
		var quoteId = null;
		var querySnapshot = await firestore.collection(handle + '/quotes/quotes').where('serviceRequestId', '==', serviceRequest.id).get();
		querySnapshot.forEach((doc) => {
			if (doc.data()['statusId'] == QUOTES.DRAFT.id) quoteId = doc.id;
		});

		if (!quoteId) quoteId = await nextQuoteId(handle, batch);

		var quote = {
			_displayName: serviceRequest._displayName,
			customId: serviceRequest.customId,
			serviceRequestId: serviceRequest.id,
			profileId: serviceRequest.profileId,
			addressId: serviceRequest.addressId,
			summary: serviceRequest.summary,
			startDate: startDate,
			endDate: endDate,
			description: description,
			statusId: QUOTES.DRAFT.id,
			options: ['1'],
			approvedOption: '1',
			editedBy: user.email,
		};
		quote.deleted = false;
		quote.expiration = moment().add(30, 'days').toDate();
		quote.created = new Date();
		quote.effective = new Date();
		quote.modified = new Date();

		batch.set(firestore.collection(handle + '/quotes/quotes').doc(quoteId), { ...quote, serviceItems });

		batch.commit().then(() => {
			dispatch({ type: types.QUOTE + '_SAVE_FULFILLED' });
			window.toastr.success('The Quote has been successfully saved/updated', 'Quote Saved!');
			if (typeof callback === 'function') callback(quoteId);

		}).catch((error) => {
			toolbox.process_error(error, 'Record NOT Saved!');
		});
	};
}

export function createChangeOrder(handle, serviceRequestId, callback) {

	return async dispatch => {

		// Copy all line items from quote & Work Orders
		// Begin list of changed items
		// List any line items removed with negative and field `changeOrder: "removed"`
		// List any new line items with field `changeOrder: "added"`

		dispatch({ type: types.QUOTE + '_SAVE_PENDING' });

		var doc = await firestore.collection(handle + '/service-requests/service-requests').doc(serviceRequestId).get();
		var serviceRequest = { ...doc.data(), id: doc.id };

		// Search for existing quote - copy line items
		var querySnapshot = await firestore.collection(handle + '/quotes/quotes').where('serviceRequestId', '==', serviceRequest.id).get();
		var quoteRef;
		querySnapshot.forEach((doc) => {
			if (!doc.data()?.changeOrder && doc.data()?.statusId == QUOTES.ACCEPTED.id) quoteRef = doc;
		});
		querySnapshot.forEach((doc) => {
			if (doc.data()?.changeOrder && doc.data()?.statusId == QUOTES.CHANGES_REQUESTED.id) quoteRef = doc;
		});
		var quoteItems = (quoteRef) ? quoteRef.data()['serviceItems'].filter((item) => item.option == quoteRef.data().approvedOption).map((item) => {
			return { ...item, count: parseInt(item.count) };
		}) : [];

		// Add line items from any Work Orders & show changes on Change Order
		const workOrderSnapshot = await firestore.collection(handle + '/work-orders/work-orders').where('serviceRequestId', '==', serviceRequestId).where('deleted', '==', false).get();

		var workOrders = [];
		var serviceItems = [];

		workOrderSnapshot.forEach((doc) => {
			workOrders.push({ ...doc.data(), id: doc.id });
		});

		var startDate;
		var endDate;
		var description = '';

		if (quoteRef) {
			startDate = quoteRef.data().startDate;
			endDate = quoteRef.data().endDate;
			description = quoteRef.data().description;
			serviceItems = quoteRef.data()['serviceItems'];
			if (!quoteRef.data().changeOrder) serviceItems = serviceItems.filter((item) => item.option == quoteRef.data().approvedOption);
			serviceItems = serviceItems.map((item) => {
				return { ...item, count: parseInt(item.count) };
			});
		} else {
			for (let workOrder of workOrders) {
				if (!startDate || workOrder.startDate < startDate) startDate = workOrder.startDate;
				if (!endDate || workOrder.endDate > endDate) endDate = workOrder.endDate;
				description += workOrder.description;

				let doc = await firestore.collection(handle + '/work-orders/work-orders/' + workOrder.id + '/serviceItems').doc(workOrder.id).get();
				if (doc.exists) {
					doc.data()['serviceItems'].forEach((serviceItem) => {
						const existing = serviceItems.findIndex((item) => (item.id == serviceItem.id) && (item.customPricePerUnit == serviceItem.customPricePerUnit) && (item.description == serviceItem.description));
						if (existing >= 0) {
							serviceItems[existing].count = parseInt(serviceItems[existing].count) + parseInt(serviceItem.count);
						} else {
							serviceItems.push(serviceItem);
						}
					});
				}
			}
		}

		var changedItems = [];

		// If there are no Work Order Line Items, don't show any changes on Change Order
		if (serviceItems.length) {
			serviceItems.forEach((item) => {
				const existing = quoteItems.find((qi) => (qi.id == item.id) && (qi.customPricePerUnit == item.customPricePerUnit) && (qi.description == item.description));
				if (existing) {
					if (parseInt(item.count) > parseInt(existing.count)) {
						changedItems.push({ ...item, count: parseInt(item.count) - parseInt(existing.count), changeOrder: 'added' });
					} else if (parseInt(item.count) < parseInt(existing.count)) {
						changedItems.push({ ...item, count: parseInt(item.count) - parseInt(existing.count), changeOrder: 'removed' });
					}
				} else {
					changedItems.push({ ...item, changeOrder: 'added' });
				}
			});
			quoteItems.forEach((qi) => {
				const existing = changedItems.find((ci) => (ci.id == qi.id) && (ci.customPricePerUnit == qi.customPricePerUnit) && (ci.description == qi.description));
				if (!existing) {
					const itemIndex = serviceItems.findIndex((si) => (si.id == qi.id) && (si.customPricePerUnit == qi.customPricePerUnit) && (si.description == qi.description));
					if (itemIndex < 0) {
						changedItems.push({ ...qi, count: -qi.count, changeOrder: 'removed' });
					}
				}
			});
		}

		const batch = firestore.batch();

		var quoteId = null;
		var querySnapshot = await firestore.collection(handle + '/quotes/quotes').where('serviceRequestId', '==', serviceRequest.id).where('changeOrder', '==', true).get();
		querySnapshot.forEach((doc) => { if (doc.data()['statusId'] == QUOTES.DRAFT.id) quoteId = doc.id; });

		if (!quoteId) quoteId = await nextQuoteId(handle, batch);

		var quote = {
			_displayName: serviceRequest._displayName,
			customId: serviceRequest.customId,
			serviceRequestId: serviceRequest.id,
			profileId: serviceRequest.profileId,
			addressId: serviceRequest.addressId,
			summary: serviceRequest.summary,
			startDate: startDate,
			endDate: endDate,
			description: description,
			statusId: QUOTES.DRAFT.id,
			changeOrder: true
		};
		quote.deleted = false;
		quote.expiration = moment().add(30, 'days').toDate();
		quote.created = new Date();
		quote.effective = new Date();
		quote.modified = new Date();

		batch.set(firestore.collection(handle + '/quotes/quotes').doc(quoteId), { ...quote, serviceItems, changedItems, quoteItems });
		// batch.set(firestore.collection(handle + '/quotes/quotes/' + quoteId + '/serviceItems').doc(quoteId), { serviceItems, changedItems, quoteItems });

		batch.commit().then(() => {
			dispatch({ type: types.QUOTE + '_SAVE_FULFILLED' });
			window.toastr.success('The Quote has been successfully saved/updated', 'Quote Saved!');
			if (typeof callback === 'function') callback(quoteId, serviceRequest.customId);

		}).catch((error) => {
			toolbox.process_error(error, 'Record NOT Saved!');
		});
	};
}

// export function saveNewQuote(handle, quote, customer, callback) {

// 	return async dispatch => {

// 		dispatch({ type: types.QUOTE + '_SAVE_PENDING' });

// 		// var addressId = 

// 		const batch = firestore.batch();
// 		var quoteId = await nextQuoteId(handle, batch);
// 		var customId = await nextCustomId(handle, batch);

// 		quote._displayName = customer.displayName;
// 		quote.deleted = false;
// 		quote.customId = customId;
// 		quote.profileId = customer.id;
// 		quote.addressId = customer.addressId;
//         quote.expiration = moment().add(30, 'days').toDate();
// 		quote.created = new Date();
// 		quote.modified = new Date();
// 		quote.statusId = QUOTES.DRAFT;

// 		batch.set(firestore.collection(handle + '/quotes/quotes').doc(quoteId), { ...quote });

// 		batch.commit().then(() => {
// 			dispatch({ type: types.QUOTE + '_SAVE_FULFILLED' });
// 			window.toastr.success('The Quote has been successfully saved/updated', 'Quote Saved!');
//             if (typeof callback === 'function') callback();

// 		}).catch((error) => {
// 			toolbox.process_error(error, 'Record NOT Saved!');
// 		});
// 	}
// }
export function updateQuote(handle, quote, callback) {

	return async dispatch => {
		dispatch({ type: types.QUOTE + '_SAVE_PENDING' });

		const user = firebase.auth().currentUser;

		const batch = firestore.batch();
		var quoteId = quote.id;
		delete quote.id;
		quote.modified = new Date();
		quote.editedBy = user.email;

		batch.update(firestore.collection(handle + '/quotes/quotes').doc(quoteId), { ...quote });

		batch.commit().then(() => {
			dispatch({ type: types.QUOTE + '_SAVE_FULFILLED' });
			window.toastr.success('The Quote has been successfully saved/updated', 'Quote Saved!');
			if (typeof callback === 'function') callback();

		}).catch((error) => {
			toolbox.process_error(error, 'Record NOT Saved!');
		});
	};
}
export function deleteQuote(handle, quoteId, callback) {

	return async dispatch => {

		dispatch({ type: types.QUOTE + '_SAVE_PENDING' });
		const user = firebase.auth().currentUser;

		const batch = firestore.batch();
		batch.update(firestore.collection(handle + '/quotes/quotes').doc(quoteId), { deleted: true, modified: new Date(), editedBy: user.email });

		batch.commit().then(() => {
			dispatch({ type: types.QUOTE + '_SAVE_FULFILLED' });
			window.toastr.success('The Quote has been successfully deleted', 'Quote Deleted!');
			if (typeof callback === 'function') callback();

		}).catch((error) => {
			toolbox.process_error(error, 'Record NOT Saved!');
		});
	};
}

async function nextQuoteId(handle, batch) {
	const table = 'quotes';
	const field = 'nextQuoteId';
	const startingId = 1000;

	return toolbox.nextId(handle, batch, table, field, startingId);
}
